import { Spin, Button, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';

import { CdCopyIcon } from '../../components/Icons';
import { createCdModal, ModalType } from '../cd-modal-new/CdModal2';

import { gettextCatalog } from '@/react/services/I18nService';

const { Paragraph } = Typography;

export interface CopyModalProps {
  subTitle: string;
  copyValue: string;
  title: string;
}

export const showCopyModal = createCdModal<CopyModalProps>(
  ({ subTitle, copyValue, title, setModalProps }) => {
    const [buttonText, setButtonText] = useState(title);

    useEffect(
      () =>
        setModalProps({
          title,
          modalType: ModalType.POPUP,
          width: 800,
          hideOk: true,
          cancelText: gettextCatalog.getString('Close'),
        }),
      [setModalProps, title]
    );

    return (
      <>
        <Paragraph>{subTitle}</Paragraph>
        <Spin spinning={!copyValue}>
          <Paragraph code style={{ whiteSpace: 'pre-line' }}>
            {copyValue}
          </Paragraph>
        </Spin>
        <CopyToClipboard
          text={copyValue}
          onCopy={() => {
            setButtonText(gettextCatalog.getString('Copied'));
          }}
        >
          <Button style={{ margin: '0px 0px 10px 2px' }} icon={<CdCopyIcon />}>
            {buttonText}
          </Button>
        </CopyToClipboard>
      </>
    );
  }
);
