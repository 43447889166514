import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { mainApi } from '../../../api';
import { handleError } from '../../../services/ErrorHandlingService';
import gettextCatalog from '../../../services/I18nService';
import NotificationService from '../../../services/NotificationService';
import { showConfirmModal } from '../../../shared/components/cd-confirm-modal/CdConfirmModal';
import { showEnquiryDrawer } from '../components/EnquiryDrawer';

export const useEnquiriesPage = () => {
  const queryClient = useQueryClient();
  const refreshEnquiries = useCallback(
    () =>
      queryClient.invalidateQueries({
        queryKey: ['useEnquiries'],
      }),
    [queryClient]
  );

  const viewEnquiry = useCallback(
    (id: string) => showEnquiryDrawer({ id }),
    []
  );

  const approveEnquiry = useCallback(
    (id: string) =>
      showConfirmModal({
        title: gettextCatalog.getString('Approve enquiry'),
        message: gettextCatalog.getString(
          'Please confirm that the enquiry is possible and that you have accepted. The enquirer will be informed accordingly.'
        ),
        onOk: async () => {
          const response = await mainApi.put(
            `/calendar/booking/requests/${id}/respond`,
            {
              response: 'accepted',
            }
          );
          if (response.ok) {
            NotificationService.notifySuccess(
              gettextCatalog.getString('Enquiry approved')
            );
            refreshEnquiries();
          } else {
            handleError(response);
          }
        },
      }).then(({ resolved }) => {
        if (!resolved) return { preventClose: true };
      }),
    [refreshEnquiries]
  );

  const declineEnquiry = useCallback(
    (id: string) =>
      showConfirmModal({
        title: gettextCatalog.getString('Decline enquiry'),
        message: gettextCatalog.getString(
          'Please confirm that the enquiry is not possible. The enquirer will be informed accordingly.'
        ),
        onOk: async () => {
          const response = await mainApi.put(
            `/calendar/booking/requests/${id}/respond`,
            {
              response: 'rejected',
            }
          );
          if (response.ok) {
            NotificationService.notifySuccess(
              gettextCatalog.getString('Enquiry declined')
            );
            refreshEnquiries();
          } else {
            handleError(response);
          }
        },
      }).then(({ resolved }) => {
        if (!resolved) return { preventClose: true };
      }),
    [refreshEnquiries]
  );

  return {
    viewEnquiry,
    approveEnquiry,
    declineEnquiry,
  };
};
