import { Button, Space } from 'antd';
import React, { useMemo } from 'react';

import { useHasPrivilege } from '../../user/store/permissions';
import cdApp from '../../config';
import { openCopyShareableLinkModal } from '../../calendar/rota-scheme/CopyShareableLinkModal';

import gettextCatalog from '@/react/services/I18nService';
import { useBookingPagesActions } from '@/react/settings/booking-pages/hooks/UseBookingPagesActions';
import {
  CdDeleteIcon,
  CdEditIcon,
  CdLink,
  CdTableIcon,
} from '@/react/shared/components/Icons';
import CdTable, { ColumnType } from '@/react/shared/components/cd-table';
import { AvailabilityTaxonomy } from '@/react/taxonomies/services/Taxonomies';

export const BookingPagesTable = () => {
  const { taxonomies, editBookingPage, deleteBookingPage, isLoading } =
    useBookingPagesActions();
  const canEdit = useHasPrivilege('taxonomy.availability.edit');
  const canDelete = useHasPrivilege('taxonomy.availability.edit');

  const columns: ColumnType<AvailabilityTaxonomy>[] = useMemo(
    () => [
      {
        key: 'name',
        title: gettextCatalog.getString('Type'),
        dataIndex: 'name',
      },
      {
        key: 'config',
        title: gettextCatalog.getString('Public name'),
        dataIndex: 'config',
        render: (config) => config?.publicName,
      },
      {
        key: 'actions',
        title: '',
        width: 200,
        render: (_, bookingPage) => (
          <Space>
            <Button
              type="primary"
              icon={<CdLink />}
              onClick={() =>
                openCopyShareableLinkModal({
                  title: gettextCatalog.getString('Share your booking page'),
                  url: `${cdApp.references.bookingPage}${cdApp.organization.id}/${bookingPage.alias}`,
                })
              }
            >
              {gettextCatalog.getString('Copy shareable link')}
            </Button>
            {canEdit && (
              <Button
                icon={<CdEditIcon />}
                onClick={() => editBookingPage(bookingPage)}
              >
                {gettextCatalog.getString('Edit')}
              </Button>
            )}
            {canDelete && (
              <Button
                icon={<CdDeleteIcon />}
                danger
                onClick={() => deleteBookingPage(bookingPage)}
              >
                {gettextCatalog.getString('Delete')}
              </Button>
            )}
          </Space>
        ),
      },
    ],
    [canDelete, canEdit, deleteBookingPage, editBookingPage]
  );

  return (
    <CdTable<AvailabilityTaxonomy>
      columns={columns}
      isLoading={isLoading}
      data={{
        items: (taxonomies ?? []) as AvailabilityTaxonomy[],
        total: taxonomies?.length ?? 0,
      }}
      emptyStateProps={{
        title: gettextCatalog.getString('No booking pages'),
        EmptyStateIcon: <CdTableIcon />,
      }}
    />
  );
};
