import React from 'react';

import { CdPage } from '../../../shared/components/cd-page/CdPage';
import gettextCatalog from '../../../services/I18nService';
import { CdTabs } from '../../../shared/components/cd-tabs/CdTabs';
import { OpenEnquiriesTab } from '../components/OpenEnquiriesTab';
import { ClosedEnquiriesTab } from '../components/ClosedEnquiriesTab';

export const EnquiriesPage = () => (
  <CdPage
    pageHeaderProps={{
      title: gettextCatalog.getString('Enquiries'),
      subTitle: gettextCatalog.getString(
        'Manage incoming enquiries from booking pages here'
      ),
    }}
  >
    <CdTabs
      items={[
        {
          title: gettextCatalog.getString('Open enquiries'),
          content: <OpenEnquiriesTab />,
        },
        {
          title: gettextCatalog.getString('Closed enquiries'),
          content: <ClosedEnquiriesTab />,
        },
      ]}
    />
  </CdPage>
);
