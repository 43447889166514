import { Checkbox, Form, Input, Layout, Row, Table, Tabs } from 'antd';
import { AnyObject } from 'antd/es/_util/type';
import styled from 'styled-components';

export const CdAppContainer = styled(Layout)`
  &&&& {
    padding: 24px;
  }
`;

export const getStyledInlineForm = (minWidth, maxWidth) => styled(Form)`
  &&&& {
    .ant-row.ant-form-item {
      min-width: ${minWidth};
      max-width: ${maxWidth};
      margin-bottom: 10px;

      .ant-input-affix-wrapper {
        max-height: none;

        input.ant-input {
          padding-top: 0;
          padding-bottom: 0;
          height: auto;
        }
      }

      .ant-select-multiple .ant-select-selection-item {
        align-items: center;
        margin-bottom: 0;
        margin-right: 0px;
        padding-right: 0px;
        background: none;
        border-width: initial;
        border-style: none;
        border-color: initial;
        border-image: initial;
      }
    }
  }
`;
// Form
export const StyledInlineForm = styled(Form)`
  &&&& {
    .ant-row.ant-form-item {
      min-width: 150px;
      max-width: 220px;
      margin-bottom: 10px;

      .ant-input-affix-wrapper {
        max-height: none;

        input.ant-input {
          padding-top: 0;
          padding-bottom: 0;
          height: auto;
        }
      }

      .ant-select-multiple .ant-select-selection-item {
        align-items: center;
        margin-bottom: 0;
        margin-right: 0px;
        padding-right: 0px;
        background: none;
        border-width: initial;
        border-style: none;
        border-color: initial;
        border-image: initial;
      }
    }
  }
`;

// Tabs
export const StyledTabs = styled(Tabs)`
  &&&& {
    border: none;
    box-shadow: none;
    box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5;
    background-color: rgba(255, 255, 255);
    border-radius: 4px;

    .ant-tabs-nav {
      margin-bottom: 0;
      border-bottom: none;

      .ant-tabs-nav-wrap {
        height: 100% !important;

        .ant-tabs-nav-list {
          width: 100%;

          .ant-tabs-tab {
            flex-grow: 1;
            flex: 1 1 auto;
            flex-wrap: wrap;
            align-items: center;
            justify-content: start;
            position: relative;
            margin: 0;
            padding: 1.15em 2em;
            background: #eee;
            color: rgba(0, 0, 0, 0.87);
            border-radius: 0;
            border: none;
            border-right: 1px solid rgba(34, 36, 38, 0.15);
            transition: background-color 0.1s ease, opacity 0.1s ease,
              color 0.1s ease, box-shadow 0.1s ease;
            height: 100%;
            font-size: 14px;
            font-weight: 700;

            &:not(.ant-tabs-tab-active) {
              border-bottom: 1px solid rgba(34, 36, 38, 0.15);
            }

            &.ant-tabs-tab-active {
              background: #fff;
              border-bottom: 1px solid transparent;

              .ant-tabs-tab-btn {
                color: rgba(0, 0, 0, 0.87);
                font-weight: 700;
              }
            }
          }
        }
      }
    }
    .ant-tabs-content {
      padding: 20px;
    }
  }
`;

// Table styles
export const StyledTable: typeof Table<AnyObject> = styled(Table)`
  &&&& {
    .errorRow {
      background-color: #f2dede;
    }
    .warningRow {
      background-color: #fffbe6;
    }
  }
`;

export const TableToolbar = styled(Row)`
  &&&&.table-toolbar {
    background: #fff;
    padding: 10px;

    .table-toolbar__right-aligned-actions {
      text-align: left;

      @media (min-width: 768px) {
        text-align: right;
      }
    }
  }
`;

// Styled search input: fixing alignment when using allowClear
export const StyledSearch = styled(Input.Search)`
  &&&& {
    display: inline-flex !important;

    .ant-input-affix-wrapper {
      display: flex;
    }

    input {
      padding-top: 0px;
      padding-bottom: 0px;
      height: auto;
    }
  }
`;

export const CapitalizedDiv = styled.div`
  text-transform: capitalize;
`;

export const StyledCheckbox = styled(Checkbox)`
  &&&& {
    .ant-checkbox {
      align-self: start;
      margin-top: 0.3rem;
    }
  }
`;
