import React, { Suspense } from 'react';
import { Menu, Dropdown, Button, Space, Tag } from 'antd';
import NiceModal from '@ebay/nice-modal-react';
import { useRecoilValue } from 'recoil';

import { StatementType } from '../components/ConsentCreateStatement';
import useConsentStatement from '../hooks/useConsentStatement';
import { Consent } from '../types/consent.type';
import { ConsentTypeQuery } from '../store/consentState';

import { CdPage } from '@/react/shared/components/cd-page/CdPage';
import { gettextCatalog } from '@/react/services/I18nService';
import {
  CdDeleteIcon,
  CdEyeIcon,
  CdDownloadIcon,
  CdEditIcon,
  CdAngleDown,
  CdConsentType,
  CdUsers,
} from '@/react/shared/components/Icons';
import CdrPageLoader from '@/react/shared/components/CdrPageLoader';
import { getStateUrl } from '@/react/services/StateServiceFactory';
import CdSimpleTable from '@/react/shared/components/cd-simple-table/CdSimpleTable';
import { UserHasPrivilege } from '@/react/user/store/permissions';
import { showConfirmModalDeprecated } from '@/react/shared/components/cd-confirm-modal/CdConfirmModal';
import { CdTooltip } from '@/react/shared/components/cd-tooltip/CdTooltip';

const ConsentTypeList = () => {
  const showConsentCreateStatementModal = (
    statementType: StatementType,
    id?: string
  ) => {
    NiceModal.show('ConsentCreateStatement', { statementType, id });
  };

  const canCreateUpdateDeleteConsents = useRecoilValue(
    UserHasPrivilege('organization.actions.manageConsent')
  );

  const { deleteStatement } = useConsentStatement();
  const confirmDeleteModal = (id: string) =>
    showConfirmModalDeprecated({
      title: gettextCatalog.getString('Delete consent'),
      message: gettextCatalog.getString(
        `All registered consents on your contacts will be deleted.`
      ),
      icon: <CdDeleteIcon />,
      okType: 'danger',
      okText: gettextCatalog.getString('Delete'),
      onOk: async () => {
        await deleteStatement(id);
      },
    });

  const moreMenu = (id: string, link: string, defaultConsent: boolean) => (
    <Menu key={`moremenu-${id}`}>
      {canCreateUpdateDeleteConsents && (
        <Menu.Item
          key="edit"
          icon={<CdEditIcon fixedWidth />}
          onClick={() =>
            showConsentCreateStatementModal(StatementType.Edit, id)
          }
        >
          {gettextCatalog.getString('Edit')}
        </Menu.Item>
      )}

      <Menu.Item
        key="viewstatement"
        icon={<CdDownloadIcon fixedWidth />}
        onClick={() => false /** GO to item.link */}
      >
        <a href={link} target="_blank" rel="noreferrer">
          {gettextCatalog.getString('View statement')}
        </a>
      </Menu.Item>
      {canCreateUpdateDeleteConsents && (
        <CdTooltip
          title={
            defaultConsent
              ? gettextCatalog.getString(
                  'This is the default consent you are not able to delete it.'
                )
              : undefined
          }
          color="red"
        >
          <div>
            <Menu.Item
              key="delete"
              disabled={defaultConsent}
              icon={<CdDeleteIcon fixedWidth />}
              onClick={() => confirmDeleteModal(id)}
            >
              {gettextCatalog.getString('Delete')}
            </Menu.Item>
          </div>
        </CdTooltip>
      )}
    </Menu>
  );

  return (
    <CdPage
      pageHeaderProps={{
        extra: [
          (canCreateUpdateDeleteConsents && (
            <Button
              key="create-statement"
              onClick={() =>
                showConsentCreateStatementModal(StatementType.Create)
              }
              type="primary"
            >
              {gettextCatalog.getString('Create statement')}
            </Button>
          )) ||
            undefined,
        ].filter((x) => x !== undefined),
        title: gettextCatalog.getString('Consent statements'),
      }}
    >
      <CdSimpleTable<Consent>
        recoilSource={ConsentTypeQuery}
        emptyStateProps={{
          title: gettextCatalog.getString('No consents found'),
          EmptyStateIcon: <CdConsentType />,
        }}
        columns={getColumns(moreMenu, gettextCatalog)}
      />
    </CdPage>
  );
};

const getConsentTypeFilterLink = (id: string) => {
  const filters = [
    {
      type: 'date',
      property: `consentType-${id}`,
      operator: 'known',
      unit: null,
      value: null,
    },
  ];
  const json = JSON.stringify(filters);
  const encoded = window.btoa(unescape(encodeURIComponent(json)));

  return getStateUrl(`app.private.people.contacts.list`, {
    filter: encoded,
  });
};

export default function ConsentTypeListHOC() {
  return (
    <Suspense fallback={<CdrPageLoader />} key="consentTypeListHOC">
      <ConsentTypeList />
    </Suspense>
  );
}

const getColumns = (
  moreMenu: (id: string, link: string, defualtConsent: boolean) => any,
  gettextCatalog
) => [
  {
    active: true,
    dataIndex: 'label',
    key: 'label',
    title: gettextCatalog.getString('Name'),
    render: (label, record) => {
      const consentLabel = gettextCatalog.getString('I agree to {{consent}}', {
        consent: `<a href="${record.link}" target="_blank" rel="noopener">${label}</a>`,
      });
      return (
        <Space>
          <span dangerouslySetInnerHTML={{ __html: consentLabel }}></span>
          {record.defaultConsent && (
            <Tag color="processing">
              {gettextCatalog.getString(
                'Default consent for all newsletter sign-ups'
              )}
            </Tag>
          )}
        </Space>
      );
    },
  },
  {
    active: true,
    dataIndex: 'people',
    key: 'people',
    title: gettextCatalog.getString('Number of contacts'),
    render: (people, record) => (
      <>
        <Button
          type="link"
          icon={<CdUsers />}
          href={getConsentTypeFilterLink(record.id)}
          size="small"
        >
          {gettextCatalog.getPlural(
            people.length || 0,
            '1 contact',
            '{{ $count | number }} contacts',
            {
              $count: people.length || 0,
            }
          )}
        </Button>
      </>
    ),
  },
  {
    active: true,
    dataIndex: 'buttons',
    key: 'buttons',
    title: '',
    align: 'right' as any,
    render: (text, record) => (
      <>
        <Space key={`space-dropdown-${record.id}`}>
          <Button
            icon={<CdEyeIcon fixedWidth />}
            type="primary"
            href={getConsentTypeFilterLink(record.id)}
            size="small"
          >
            {gettextCatalog.getString('See contacts')}
          </Button>
          <Dropdown
            overlay={() =>
              moreMenu(record.id, record.link, record.defaultConsent)
            }
            key={`dropdown-${record.id}`}
          >
            <Button size="small">
              {gettextCatalog.getString('More')} <CdAngleDown />
            </Button>
          </Dropdown>
        </Space>
      </>
    ),
  },
];
