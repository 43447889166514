import { useQuery, useQueryClient } from '@tanstack/react-query';

import { mainApi } from '@/react/api';
import { User } from '@/react/user/types/User.types';

export const useOrganizationUsers = () => {
  const queryClient = useQueryClient();
  const query = useQuery({
    queryKey: ['useOrganizationUsers'],
    queryFn: async () => {
      const response = await mainApi.get<User[]>('users');
      if (response.ok) {
        return response.data;
      }
      throw response;
    },
  });

  const refreshUsers = () =>
    queryClient.invalidateQueries({
      queryKey: ['useOrganizationUsers'],
    });

  return { users: query.data || [], refreshUsers, isLoading: query.isLoading };
};
