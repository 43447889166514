import { $injector } from 'ngimport';
import { IRootScopeService } from 'angular';

import { DetailedEvent } from '../models/calendar';
import { fullCalendarActions } from '../store/events/fullCalendarEventsSlice';
import Store from '../../redux';

import CalendarViewService from './CalendarViewService';

interface CreateEventOptions {
  onHide: () => void;
  onCreate: (event: DetailedEvent) => void;
}

export interface RootScope extends IRootScopeService {
  openCreateContentWindow(
    eventType: string,
    operation: 'edit' | 'create' | 'copy',
    event?: any
  );
  previousStateParams: any;
  previousState: any;
}

export interface UibModal<T = void> {
  open(options: {
    windowClass?: string;
    backdrop?: string;
    keyboard?: boolean;
    size?: string;
    component: string;
    resolve: any;
  }): { result: Promise<T> };
}

export interface CreateEventData {
  type?: string;
  allDay: boolean;
  showAbsence?: boolean;
  startDate?: string;
  endDate?: string;
  resources?: (string | number)[];
  users?: number[];
}

interface ViewEventOptions {
  openOnClick: boolean;
  onDelete: () => void;
  onUpdateResponse: () => void;
}

interface AngularEventPopupService {
  createEvent: (
    eventData: CreateEventData,
    target: EventTarget,
    options: CreateEventOptions
  ) => void;
  viewEvent: (
    eventId: number,
    target: EventTarget,
    options: ViewEventOptions
  ) => void;
}

const defaultViewEventOptions: ViewEventOptions = {
  openOnClick: true,
  onDelete: (): void => {
    const store = Store.getStore();
    store.dispatch(fullCalendarActions.reloadCurrentView());
  },
  onUpdateResponse: (): void => {
    const store = Store.getStore();
    store.dispatch(fullCalendarActions.reloadCurrentView());
  },
};

class EventPopupService {
  public viewEvent(
    eventId: number,
    target: Element | Text,
    type: string,
    options?: ViewEventOptions
  ): void {
    const eventPopupService: EventPopupService =
      $injector.get('eventPopupService');
    eventPopupService.viewEvent(
      eventId,
      target,
      type,
      options || defaultViewEventOptions
    );
  }

  public createEvent(
    data: CreateEventData,
    target: EventTarget,
    onCreate: (event: DetailedEvent) => void
  ): void {
    const eventPopupService: AngularEventPopupService =
      $injector.get('eventPopupService');
    eventPopupService.createEvent(data, target, {
      onHide: () => {
        CalendarViewService.unselect();
      },
      onCreate: (result) => {
        onCreate(result as DetailedEvent);
      },
    });
  }

  public createEventDetails(eventType: 'event' | 'absence'): void {
    const rootScope = $injector.get('$rootScope') as RootScope;
    rootScope.openCreateContentWindow(eventType, 'create');
  }

  public editEventDetails(eventType: string, event: DetailedEvent): void {
    const rootScope = $injector.get('$rootScope') as RootScope;
    rootScope.openCreateContentWindow(eventType, 'edit', event);
  }

  public copyEvent(eventType: string, event: DetailedEvent): void {
    const rootScope = $injector.get('$rootScope') as RootScope;
    rootScope.openCreateContentWindow(eventType, 'copy', event);
  }

  public deleteEvent(event: any): { result: Promise<void> } {
    const $uibModal = $injector.get('$uibModal') as UibModal;
    return $uibModal.open({
      component: 'cdEventDeleteModal',
      resolve: {
        event: () => event,
      },
    });
  }
}

export default new EventPopupService();
