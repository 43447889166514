import { useQuery } from '@tanstack/react-query';

import { CdClientSideTableApiSearchParam } from '../../../shared/components/cd-client-side-table/types';
import { handleError } from '../../../services/ErrorHandlingService';
import { Enquiry, SingleEnquiry } from '../types/enquiry';
import { mainApi } from '../../../api';

export const useEnquiries = (params: CdClientSideTableApiSearchParam) =>
  useQuery({
    queryKey: ['useEnquiries', params],
    queryFn: async () => {
      const response = await mainApi.get<Enquiry[]>(
        '/calendar/booking/requests'
      );

      if (!response.ok) {
        handleError(response);
        return { items: [], total: 0 };
      }

      const filtered = (response?.data || []).filter(
        (enquiry) =>
          !params?.extraData?.status ||
          params?.extraData?.status?.includes(enquiry.status)
      );

      return {
        items: filtered,
        total: filtered.length,
      };
    },
  });

export const useSingleEnquiry = (id) =>
  useQuery({
    queryKey: ['useSingleEnquiry', id],
    queryFn: async () => {
      const response = await mainApi.get<SingleEnquiry>(
        `/calendar/booking/requests/${id}`
      );

      if (!response.ok) {
        handleError(response);
      } else {
        return response.data;
      }
    },
  });
