import { InputNumber, Row, Space } from 'antd';
import React from 'react';
import styled from 'styled-components';

import { FormDuration } from '../types/availability-taxonomy.types';

const StypedInputNumber = styled(InputNumber)`
    width: 100px;
`;

type DurationPickerProps = {
  value?: FormDuration;
  onChange?: (value: FormDuration) => void;
};

export const DurationPicker = ({ value, onChange }: DurationPickerProps) => (
  <Row>
    <Space>
      <StypedInputNumber
        addonAfter="h"
        precision={0}
        min={0}
        max={144}
        value={value?.hours}
        onChange={(hours: number) => onChange({ ...value, hours: hours || 0 })}
      />
      <StypedInputNumber
        addonAfter="min"
        precision={0}
        step={5}
        min={0}
        max={59}
        width={100}
        value={value?.minutes}
        onChange={(minutes: number) =>
          onChange({ ...value, minutes: minutes || 0 })
        }
      />
    </Space>
  </Row>
);
