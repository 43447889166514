import React from 'react';
import { Col, Radio, theme, Typography } from 'antd';

import { ContactDetails } from '@/react/people/duplicate/components/ContactDetails';
import { CdAnglesLeft, CdAnglesRight } from '@/react/shared/components/Icons';
import gettextCatalog from '@/react/services/I18nService';
import { DuplicateContact } from '@/react/people/duplicate/types/Types';
import { CdVerticalSpace } from '@/react/shared/components/cd-vertical-space/CdVerticalSpace';
const { useToken } = theme;

interface RadioGroupComponentProps {
  duplicateRecordData: DuplicateContact;
  value?: number;
  onChange?: (value: string) => void;
}
export const ContactGroupComponent = (props: RadioGroupComponentProps) => {
  const [primaryContactLocal, setPrimaryContactLocal] = React.useState(
    props?.duplicateRecordData?.contactOne?.churchDeskId === props?.value
      ? props?.duplicateRecordData?.contactOne
      : props?.duplicateRecordData?.contactTwo
  );

  const { token } = useToken();
  const getExtraMessage = (master: boolean) => {
    if (master) {
      return (
        <Typography.Text style={{ color: token.colorSuccess }}>
          {gettextCatalog.getString(
            'This is the primary contact.',
            null,
            'Merge Duplicate Contacts'
          )}
        </Typography.Text>
      );
    } else {
      return (
        <Typography.Text style={{ color: token.colorError }}>
          {gettextCatalog.getString(
            'This is the duplicate contact (will be deleted)',
            null,
            'Merge Duplicate Contacts'
          )}
        </Typography.Text>
      );
    }
  };

  return (
    <Radio.Group
      style={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        marginTop: '12px',
        marginRight: '0px',
      }}
      defaultValue={props?.value}
      onChange={(e) => {
        setPrimaryContactLocal(
          e.target.value ===
            props?.duplicateRecordData?.contactOne?.churchDeskId
            ? props?.duplicateRecordData?.contactOne
            : props?.duplicateRecordData?.contactTwo
        );
        props.onChange(e.target.value);
      }}
    >
      <Col span={11}>
        <CdVerticalSpace>
          <ContactDetails
            contact={props?.duplicateRecordData?.contactOne}
            primary={
              props?.duplicateRecordData?.contactOne?.churchDeskId ===
              primaryContactLocal?.churchDeskId
            }
          />
          {getExtraMessage(
            props?.duplicateRecordData?.contactOne?.churchDeskId ===
              primaryContactLocal?.churchDeskId
          )}
        </CdVerticalSpace>
      </Col>
      <Col span={2} style={{ display: 'flex', justifyContent: 'center' }}>
        {primaryContactLocal?.churchDeskId ===
        props?.duplicateRecordData?.contactOne?.churchDeskId ? (
          <CdAnglesLeft type="secondary" style={{ fontSize: '24px' }} />
        ) : (
          <CdAnglesRight type="secondary" style={{ fontSize: '24px' }} />
        )}
      </Col>
      <Col span={11}>
        <CdVerticalSpace>
          <ContactDetails
            contact={props?.duplicateRecordData?.contactTwo}
            primary={
              props?.duplicateRecordData?.contactTwo?.churchDeskId ===
              primaryContactLocal?.churchDeskId
            }
          />
          {getExtraMessage(
            props?.duplicateRecordData?.contactTwo?.churchDeskId ===
              primaryContactLocal?.churchDeskId
          )}
        </CdVerticalSpace>
      </Col>
    </Radio.Group>
  );
};
