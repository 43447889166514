import { each } from 'lodash';
import { createSelector } from 'reselect';

import { AppState } from '../../redux';

export const getUsers = (state: AppState) => state.user.users;

export const selectIsUsersLoading: (state: AppState) => boolean = (state) =>
  state.user.communication.isUsersLoading;

export const getUserChurchRolesByRoleId: (state: AppState) => {
  [roleId: string]: number[];
} = (state) => {
  const church = {};
  each(state.user.churchRoles, (churchMembership) => {
    each(churchMembership.roles, (churchMembershipRoles) => {
      if (!church[churchMembershipRoles.id]) {
        church[churchMembershipRoles.id] = [];
      }
      church[churchMembershipRoles.id].push(churchMembership.churchId);
    });
  });
  return church;
};

export const getUserPrivilegeSelector = (
  state: AppState,
  permission: string
): boolean =>
  // Returns null to show that the state does not have a value yet.
  state.user.permissions[permission] || null;

export const getUserOrganizationRoles = (state: AppState) =>
  state.user.organizationRoles;

export const getUserChurchRoles = (state: AppState) => state.user.churchRoles;

export const getUserOldRoles = (state: AppState) => state.user.oldRoles;

export const selectUserById = (userId: number) =>
  createSelector(getUsers, (users) => users.find((user) => user.id === userId));

export const getUserDisplayName = (userId: number) =>
  createSelector(selectUserById(userId), (user) =>
    !user
      ? ''
      : user.firstName && user.lastName
        ? `${user.firstName} ${user.lastName}`
        : user.email
  );
