import { react2angular } from 'react18-react2angular';

import sharedContext from '../../shared/components/shared-context-root/shared-context-root.component';

import { CdSalesButtonFromAngular } from '@/react/shared/components/cd-buttons/CdSalesButton';

angular
  .module('cdApp.shared')
  .component(
    'cdrSalesButton',
    react2angular(
      sharedContext.use(CdSalesButtonFromAngular),
      ['href', 'children'],
      []
    )
  );
