import angular, { ui } from 'angular';
import type { IStateService } from 'angular-ui-router';
import { $injector } from 'ngimport';

import { RootScope } from '@/react/calendar/services/EventPopupService';

const StateServiceFactory = (): IStateService => {
  if (!$injector) {
    return {
      go(): angular.IPromise<any> {
        return Promise.resolve();
      },
      href(): string {
        return '#';
      },
    } as any as IStateService;
  }
  return $injector.get('$state');
};

export const StateParamsServiceFactory = <T>(): T =>
  $injector.get('$stateParams') as T;

export const RootScopeServiceFactory = (): RootScope =>
  $injector.get('$rootScope') as RootScope;

export const navigate = (
  url: string,
  params?: any,
  options?: ui.IStateOptions
): void => {
  const state = StateServiceFactory();
  state.go(url, params, options);
};

export const getStateUrl = (
  url: string,
  params?: any,
  options?: any
): string => {
  const state = StateServiceFactory();
  return state.href(url, params, options);
};

export const goBack = (fallbackIfNoPrevious?: string): void => {
  const rootScope = RootScopeServiceFactory();
  const previousState = rootScope.previousState;

  if (previousState?.name?.length > 0) {
    // Angulars back function does not work. But the browser one does.
    history.back();
  } else {
    if (fallbackIfNoPrevious) {
      navigate(fallbackIfNoPrevious);
    }
  }
};

export default StateServiceFactory;
