import { get } from 'lodash';

import { mainApi } from '../../api';
import { buildErrorEntityByStatus } from '../../shared/errors';

export interface InterfaceGroups {
  id: number;
  name: string;
  picture: string;
  members: number[];
  absenceDisabled: boolean;
  tasks: {
    id: number;
    title: string;
  }[];
}

class GroupService {
  public getAll = async () => {
    const res = await mainApi.get(`/groups`);
    if (res.ok) return res.data as InterfaceGroups[];
    throw buildErrorEntityByStatus(
      get(res.data, 'status'),
      get(res.data, 'message')
    );
  };
}
export default new GroupService();
