import React from 'react';
import { useSelector } from 'react-redux';

import { gettextCatalog } from '../../../services/I18nService';
import { selectIsInResourceView } from '../../store/main-view/Selectors';
import { useTaxonomies } from '../../hooks/use-taxonomies';
import { CategoryType } from '../../../shared/models/category';
import { useAvailabilitiesFilter } from '../../store/availabilities/availabilitiesSlice';
import { selectIsUserFiltersLoaded } from '../../store/filters/Selectors';

import { FilterSection } from './FilterSection';

export const AvailabilityCalendarFilters = () => {
  const isInResourceView = useSelector(selectIsInResourceView);
  const { taxonomies, isLoading } = useTaxonomies(CategoryType.availability);
  const [value, setValue] = useAvailabilitiesFilter();
  const isUserFiltersLoaded = useSelector(selectIsUserFiltersLoaded);

  return (
    <FilterSection
      title={gettextCatalog.getString('All availabilities')}
      value={value}
      onChange={setValue}
      options={taxonomies}
      isLoading={isLoading || !isUserFiltersLoaded}
      isDisabled={isInResourceView}
    />
  );
};
