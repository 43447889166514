import { selector, selectorFamily } from 'recoil';
import { QueryClient, useQuery, useSuspenseQuery } from '@tanstack/react-query';

import { ConsentType, ContributionProject } from '../types/contribution';
import { PaymentProjectStats } from '../types/payment-project-stats';

import { registerRecoilRefresher } from '@/app/cdRecoilRefresher';
import { handleError } from '@/react/services/ErrorHandlingService';
import { ApiSearchParams, contributionsApi, mainApi } from '@/react/api';
import ResourceService from '@/react/shared/services/ResourceService';

export const QRCodeQuery = selector({
  key: 'QRCodeQuery',
  get: async () => {
    const res = await contributionsApi.get<string>(
      'contributions/qrcode?type=landingPage'
    );
    if (!res.ok) {
      handleError(res);
      throw res;
    }
    return res.data;
  },
});

export const QRCodeAliasQuery = selectorFamily<string, string>({
  key: 'QRCodeAliasQuery',
  get: (alias: string) => async () => {
    const response = await contributionsApi.get('/contributions/qrcode', {
      contributionAlias: alias,
    });
    if (response.ok) {
      return response.data as string;
    }
    throw response.data;
  },
});

export const PosterTokenQuery = selectorFamily<string, { contributionId }>({
  key: 'PosterTokenQuery',
  get:
    ({ contributionId }) =>
    async () => {
      const response = await mainApi.get<{ token: string }>(
        `/p/contributions/${contributionId}/poster/token`
      );
      if (response.ok) {
        return response.data.token;
      } else {
        throw response.data;
      }
    },
});

export const GetCurrentContributionProject = selectorFamily<
  ContributionProject,
  string
>({
  key: 'GetCurrentContributionProject',
  get:
    (contributionId: string) =>
    async ({ getCallback }) => {
      const response = await contributionsApi.get<ContributionProject>(
        `/contributions/${contributionId}`
      );

      registerRecoilRefresher(
        GetCurrentContributionProject(contributionId),
        getCallback(
          ({ refresh }) =>
            () =>
              refresh(GetCurrentContributionProject(contributionId))
        )
      );

      if (response.ok) {
        return response.data;
      } else {
        throw response.data;
      }
    },
});

export const GetCurrentContributionStats = selectorFamily<
  PaymentProjectStats,
  string
>({
  key: 'GetCurrentContributionStats',
  get: (contributionId: string) => async () => {
    const response = await contributionsApi.get<PaymentProjectStats>(
      `/contributions/${contributionId}/stats`
    );
    if (response.ok) {
      return response.data;
    } else {
      throw response.data;
    }
  },
});

export const getChurchesV3 = async (
  permissionContext: string,
  permissionType: string
) => {
  try {
    const res = await ResourceService.getChurchesV3(
      permissionContext,
      permissionType
    );
    return res.churches;
  } catch (err) {
    handleError(err);
  }
};

export const getConsentTypes = async () => {
  try {
    const response = await mainApi.get<ConsentType[]>(`/people/consent-types`);
    if (response.ok) {
      return response.data;
    } else {
      throw response.data;
    }
  } catch (err) {
    handleError(err);
  }
};

export const useConsentTypes = () =>
  useSuspenseQuery({
    queryKey: ['useConsentTypes'],
    queryFn: async () => {
      const response = await mainApi.get<ConsentType[]>(
        `/people/consent-types`
      );
      if (response.ok) {
        return response.data;
      } else {
        handleError(response.data as any);
        throw response.data;
      }
    },
  });

const getContributionProject = async () => {
  const response =
    await contributionsApi.get<ContributionProject[]>(`/contributions`);
  if (response.ok) {
    return response.data;
  } else {
    handleError(response.data as any);
    return null;
  }
};

export const useChurchesV3 = (
  permissionContext: string,
  permissionType: string
) =>
  useQuery({
    queryKey: ['useChurchesV3', permissionContext, permissionType],
    queryFn: () => getChurchesV3(permissionContext, permissionType),
  });

export const useGetAllContributionProjects = (params: ApiSearchParams) =>
  useQuery({
    queryKey: [
      'useGetAllContributionProjects',
      params?.extraData?.searchKeyWord,
      params?.extraData?.selectedParishes,
    ],
    queryFn: async () => {
      const response = await getContributionProject();
      const searchKeyWord = params?.extraData?.searchKeyWord || '';
      const selectedParishes = params?.extraData?.selectedParishes || [];

      if (response) {
        const data = response.filter((item) => {
          const matchesKeyword = item.title
            .toLowerCase()
            .includes(searchKeyWord.toLowerCase());

          const matchesParish =
            !selectedParishes ||
            selectedParishes.length === 0 ||
            item.churches.some((church) =>
              selectedParishes.includes(church.id)
            );

          return matchesKeyword && matchesParish;
        });

        return {
          items: data.sort((a, b) => a.title.localeCompare(b.title)),
          total: data.length,
        };
      } else {
        return { items: [], total: 0 };
      }
    },
  });

export const refreshContributionProjects = async (queryClient: QueryClient) =>
  queryClient.invalidateQueries({
    queryKey: ['useGetAllContributionProjects'],
  });
