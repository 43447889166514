const antdOverride = {
  '@layout-header-background': '#00b683',
  '@primary-color': '#008DB6',
  '@link-color': '#008DB6',
  '@success-color': '#7ab800',
  '@warning-color': '#faad14',
  '@error-color': '#f5222d',
  '@text-color': 'rgba(0, 0, 0, 0.85)',
  '@disabled-color': 'rgba(0, 0, 0, 0.25)',
  '@border-radius-base': '4px',
  '@border-color-base': '#d9d9d9',
  '@font-family':
    "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
  '@font-size-base': '14px',
};

export const customTheme = {
  // Find full custom theme sample here: https://github.com/UgnisSoftware/react-spreadsheet-import/blob/55f0df78513a9e66a27b597d9b698edc041e5cc3/src/theme.ts#L515
  fonts: {
    heading: antdOverride['@font-family'],
    body: antdOverride['@font-family'],
  },
  colors: {
    textColor: antdOverride['@text-color'],
    subtitleColor: antdOverride['@text-color'],
    inactiveColor: antdOverride['@disabled-color'],
    border: antdOverride['@border-color-base'],
    background: 'white',
    secondaryBackground: 'white',
    highlight: '#e5e5e5',
    rsi: {
      50: '#e5e5e5', // ROW SELECTED COLOR
      100: '#e5e5e5', // ROW HOVER COLOR
      200: '#A2A5FC', // UNKNOWN
      300: '#8888FC', // UNKNOWN
      400: '#00FF00',
      500: antdOverride['@primary-color'], // NEXT BUTTON COLOR
      600: antdOverride['@primary-color'], // SELECT FILE HOVER COLOR
      700: antdOverride['@primary-color'], // SELECT FILE CLICK COLOR
      800: '#1D0EBE', // UNKNOWN
      900: '#0C008C', // UNKNOWN
    },
  },
  components: {
    Steps: {
      parts: [
        'description',
        'icon',
        'iconLabel',
        'label',
        'labelContainer',
        'step',
        'stepContainer',
        'stepIconContainer',
        'root',
      ],
      sizes: {
        md: {
          stepIconContainer: {
            width: '40px',
            height: '40px',
            borderWidth: '4px',
            borderColor: antdOverride['@primary-color'],
            borderRadius: '100%',
            marginRight: '1rem',
            backgroundColor: antdOverride['@primary-color'],
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          },
          icon: { width: '18px', height: '18px' },
          label: {
            fontWeight: 'bold',
            textAlign: 'center',
            fontSize: '2xl',
          },
          iconLabel: {
            color: 'white',
          },
        },
      },
      variants: {},
      defaultProps: { size: 'md', variant: 'simple' },
    },
    MatchIcon: {
      baseStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
        borderWidth: '2px',
        bg: 'background',
        borderColor: antdOverride['@warning-color'],
        color: 'background',
        transitionDuration: 'ultra-fast',
        _highlighted: {
          bg: antdOverride['@success-color'],
          borderColor: antdOverride['@success-color'],
        },
      },
    },
    UploadStep: {
      baseStyle: {
        heading: {
          fontSize: '3xl',
          color: 'textColor',
          mb: '2rem',
        },
        title: {
          fontSize: '2xl',
          lineHeight: 8,
          fontWeight: 'semibold',
          color: 'textColor',
        },
        subtitle: {
          fontSize: 'md',
          lineHeight: 6,
          color: 'subtitleColor',
          mb: '1rem',
        },
        tableWrapper: {
          mb: '0.5rem',
          position: 'relative',
          h: '72px',
        },
        dropzoneText: {
          size: 'lg',
          lineHeight: 7,
          fontWeight: 'semibold',
          color: 'textColor',
        },
        dropZoneBorder: antdOverride['@primary-color'],
        // Select file button
        dropzoneButton: {
          fontSize: '2xl',
          bg: antdOverride['@primary-color'],
          p: '16px',
        },
      },
    },
    SelectSheetStep: {
      baseStyle: {
        heading: {
          color: 'textColor',
          mb: 8,
          fontSize: '3xl',
        },
        radio: {},
        radioLabel: {
          color: 'textColor',
        },
      },
    },
    SelectHeaderStep: {
      baseStyle: {
        heading: {
          color: 'textColor',
          mb: 8,
          fontSize: '3xl',
        },
      },
    },
    MatchColumnsStep: {
      baseStyle: {
        heading: {
          color: 'textColor',
          mb: 8,
          fontSize: '3xl',
        },
        title: {
          color: 'textColor',
          fontSize: '2xl',
          lineHeight: 8,
          fontWeight: 'semibold',
          mb: 4,
        },
        select: {
          fontSize: '2xl',
          // lineHeight: 8,
          // fontWeight: 'semibold',
          // color: 'textColor',
          control: (provided) => ({
            ...provided,
            fontSize: 'xl',
            borderColor: 'border',
            _hover: {
              borderColor: 'border',
            },
            ['&[data-focus-visible]']: {
              borderColor: 'border',
              boxShadow: 'none',
            },
          }),
          menu: (provided) => ({
            ...provided,
            fontSize: 'xl',
            p: 0,
            mt: 0,
          }),
          menuList: (provided) => ({
            ...provided,
            bg: 'background',
            fontSize: 'xl',
            borderColor: 'border',
          }),
          option: (provided, state) => ({
            ...provided,
            color: 'textColor',
            fontSize: 'xl',
            bg:
              state.isSelected || state.isFocused ? 'highlight' : 'background',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: 'block',
            whiteSpace: 'nowrap',
            _hover: {
              bg: 'highlight',
            },
          }),
          placeholder: (provided) => ({
            ...provided,
            fontSize: 'xl',
            color: 'inactiveColor',
          }),
          noOptionsMessage: (provided) => ({
            ...provided,
            fontSize: 'xl',
            color: 'inactiveColor',
          }),
        },
        userTable: {
          header: {
            fontSize: antdOverride['@font-size-base'],
            lineHeight: 8,
            fontWeight: 'bold',
            letterSpacing: 'wider',
            color: 'textColor',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          },
          cell: {
            fontSize: antdOverride['@font-size-base'],
            lineHeight: 5,
            fontWeight: 'medium',
            color: 'textColor',
            px: 6,
            py: 4,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          },
          ignoreButton: {
            size: 'lg',
            colorScheme: 'gray',
            color: 'textColor',
          },
        },
        selectColumn: {
          text: {
            fontSize: antdOverride['@font-size-base'],
            lineHeight: 5,
            fontWeight: 'normal',
            color: 'inactiveColor',
            px: 4,
          },
          accordionLabel: {
            color: antdOverride['@primary-color'],
            fontSize: 'xl',
            lineHeight: 5,
            pl: 1,
          },
          selectLabel: {
            pt: '0.375rem',
            pb: 2,
            fontSize: antdOverride['@font-size-base'],
            lineHeight: 6,
            fontWeight: 'medium',
            color: 'textColor',
          },
        },
      },
    },
    ValidationStep: {
      baseStyle: {
        heading: {
          color: 'textColor',
          fontSize: '3xl',
        },

        select: {
          valueContainer: (provided) => ({
            ...provided,
            py: 0,
            px: 1.5,
          }),
          inputContainer: (provided) => ({
            ...provided,
            py: 0,
            fontSize: 'xl',
          }),
          control: (provided) => ({
            ...provided,
            border: 'none',
            fontSize: 'xl',
          }),
          input: (provided) => ({
            ...provided,
            color: 'textColor',
            fontSize: 'xl',
          }),
          menu: (provided) => ({
            ...provided,
            p: 0,
            mt: 0,
            fontSize: 'xl',
          }),
          menuList: (provided) => ({
            ...provided,
            bg: 'background',
            fontSize: antdOverride['@font-size-base'],
          }),
          option: (provided, state) => {
            let bg = 'background';
            if (state.isFocused) bg = 'lightgray';
            if (state.isSelected) {
              bg = `${antdOverride['@primary-color']} !important`;
            }

            return {
              ...provided,
              color: 'textColor',
              bg,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: 'block',
              whiteSpace: 'nowrap',
              fontSize: antdOverride['@font-size-base'],
            };
          },
          noOptionsMessage: (provided) => ({
            ...provided,
            color: 'inactiveColor',
          }),
        },
        button: {
          size: 'lg',
          colorScheme: 'gray',
          color: 'textColor',
        },
      },
    },
    Modal: {
      baseStyle: {
        dialog: {
          borderRadius: antdOverride['@border-radius-base'],
          fontSize: antdOverride['@font-size-base'],
          color: 'textColor',
          width: '95%',
        },
        closeModalButton: {
          color: 'background',
          fontSize: antdOverride['@font-size-base'],
        },
        continueButton: {
          gridColumn: '3',
          gridRow: '1',
          color: 'background',
          fontSize: antdOverride['@font-size-base'],
          justifySelf: 'end',
          padding: '18px',
          width: 'unset !important',
        },
        backButton: {
          gridColumn: '2',
          gridRow: '1',
          color: antdOverride['@primary-color'],
          fontSize: antdOverride['@font-size-base'],
          justifySelf: 'end',
          border: '1px solid',
          padding: '8px 16px',
        },
      },
      variants: {
        rsi: {
          header: {
            bg: 'secondaryBackground',
            px: '2rem',
            py: '1.5rem',
            borderTopStyle: 'none',
            borderLeftStyle: 'none',
            borderRightStyle: 'none',
            borderBottomStyle: 'solid',
            borderColor: 'f0f0f0',
            borderWidth: '1px',
          },
          body: {
            bg: 'background',
          },
          footer: {
            bg: 'secondaryBackground',
          },
          dialog: {
            borderRadius: antdOverride['@border-radius-base'],
          },
        },
      },
    },
    Button: {
      baseStyle: {
        borderRadius: antdOverride['@border-radius-base'],
        fontSize: '2xl',
      },
      defaultProps: {
        colorScheme: 'rsi',
      },
    },
  },
  styles: {
    global: {
      '.chakra-modal__body > div > div > button.chakra-button': {
        fontSize: '14px',
        padding: '14px',
      },
      '.chakra-portal .chakra-portal .chakra-modal__content': {
        maxWidth: '35%',
      },
      '.chakra-portal .chakra-portal .chakra-modal__content .chakra-modal__footer button':
        {
          fontSize: antdOverride['@font-size-base'],
          fontWeight: 'normal',
          padding: '14px',
        },
      '.chakra-portal .chakra-portal .chakra-modal__content .chakra-modal__header':
        {
          fontSize: antdOverride['@font-size-base'],
        },
      '.chakra-portal .chakra-portal div[data-popper-placement="top"] > div[role="tooltip"]':
        {
          fontSize: antdOverride['@font-size-base'],
        },
      '.rdg': {
        // TABLE
        borderRadius: antdOverride['@border-radius-base'],
        border: 'none',
        borderTop: '1px solid var(--rdg-border-color)',
        blockSize: '100%',
      },
      '.rdg-header-row .rdg-cell': {
        color: 'textColor',
        fontSize: antdOverride['@font-size-base'],
        lineHeight: 10,
        fontWeight: 'bold',
        letterSpacing: 'wider',
        textTransform: 'camelCase',
        '&:first-of-type': {
          borderTopLeftRadius: antdOverride['@border-radius-base'],
        },
        '&:last-child': {
          borderTopRightRadius: antdOverride['@border-radius-base'],
        },
      },
      '.rdg-row:last-child .rdg-cell:first-of-type': {
        borderBottomLeftRadius: antdOverride['@border-radius-base'],
      },
      '.rdg-row:last-child .rdg-cell:last-child': {
        borderBottomRightRadius: antdOverride['@border-radius-base'],
      },
      ".rdg[dir='rtl']": {
        '.rdg-row:last-child .rdg-cell:first-of-type': {
          borderBottomRightRadius: antdOverride['@border-radius-base'],
          borderBottomLeftRadius: 'none',
        },
        '.rdg-row:last-child .rdg-cell:last-child': {
          borderBottomLeftRadius: antdOverride['@border-radius-base'],
          borderBottomRightRadius: 'none',
        },
      },
      '.rdg-cell': {
        contain: 'size layout style paint',
        borderRight: 'none',
        fontSize: antdOverride['@font-size-base'],
        borderInlineEnd: 'none',
        borderBottom: '1px solid var(--rdg-border-color)',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        "&[aria-selected='true']": {
          boxShadow: 'inset 0 0 0 1px var(--rdg-selection-color)',
        },
        '&:first-of-type': {
          boxShadow: 'none',
          borderInlineStart: '1px solid var(--rdg-border-color)',
        },
        '&:last-child': {
          borderInlineEnd: '1px solid var(--rdg-border-color)',
        },
      },
      '.rdg-cell-error': {
        backgroundColor: '#ffb8b8',
        fontSize: antdOverride['@font-size-base'],
      },
      '.rdg-cell-warning': {
        backgroundColor: '#ffd4b8',
        fontSize: antdOverride['@font-size-base'],
      },
      '.rdg-cell-info': {
        backgroundColor: '#b8eaff',
        fontSize: antdOverride['@font-size-base'],
      },
      '.rdg-static': {
        cursor: 'pointer',
      },
      '.rdg-static .rdg-header-row': {
        display: 'none',
      },
      '.rdg-static .rdg-cell': {
        '--rdg-selection-color': 'none',
      },
      '.rdg-example .rdg-cell': {
        '--rdg-selection-color': 'none',
        borderBottom: 'none',
      },

      '.rdg-radio': {
        display: 'flex',
        alignItems: 'center',
      },
      '.rdg-checkbox': {
        '--rdg-selection-color': 'none',
        display: 'flex',
        alignItems: 'center',
      },
    },
  },
};
