import React, { Suspense, useState, useCallback } from 'react';
import { Menu, Dropdown, Button, Input, Space, Tag } from 'antd';
import NiceModal from '@ebay/nice-modal-react';
import { useRecoilValue } from 'recoil';

import { showCreateNewsletterListModal } from './CreateNewsletterList';

import { CdPage } from '@/react/shared/components/cd-page/CdPage';
import { CdTabs } from '@/react/shared/components/cd-tabs/CdTabs';
import { gettextCatalog } from '@/react/services/I18nService';
import { ColumnType } from '@/react/shared/components/cd-table';
import {
  CdSearch,
  CdFilterIcon,
  CdNewsletterListIcon,
  CdDeleteIcon,
  CdEyeIcon,
  CdDownloadIcon,
  CdEditIcon,
  CdUserPlusIcon,
  CdAngleDown,
  CdRenameFilter,
  CdUsers,
  CdParticipantList,
} from '@/react/shared/components/Icons';
import {
  PeopleListByTypeAndSearchQuery,
  NewsletterandFilterDataInterface,
} from '@/react/people/store/newsletterListState';
import CdrPageLoader from '@/react/shared/components/CdrPageLoader';
import { getStateUrl, navigate } from '@/react/services/StateServiceFactory';
import MessageListCreateDropdown from '@/react/people/message-editor/shared/containers/MessageListCreateDropdown';
import useNewsletterList, {
  SetUpType,
} from '@/react/people/hooks/useNewsletterList';
import { HasMultipleChurches } from '@/react/user/store/user-session';
import { UserHasPrivilege } from '@/react/user/store/permissions';
import CdSimpleTable from '@/react/shared/components/cd-simple-table/CdSimpleTable';
import { showConfirmModalDeprecated } from '@/react/shared/components/cd-confirm-modal/CdConfirmModal';
interface NewsletterListProps {
  $stateParams;
}

export const NewsletterList = ({ $stateParams }: NewsletterListProps) => {
  const [current, setCurrent] = useState(
    $stateParams.$stateParams.activeTab
      ? $stateParams.$stateParams.activeTab
      : 'filters'
  );
  const [searchKeyWord, setSearchKeyWord] = useState('');
  const onChange = setCurrent;
  const { deleteList } = useNewsletterList();
  const hasMultipleChurches = useRecoilValue(HasMultipleChurches);
  const canCreateEditLists = useRecoilValue(
    UserHasPrivilege('organization.people_segment.edit')
  );
  const canDeleteLists = useRecoilValue(
    UserHasPrivilege('organization.people_segment.delete')
  );
  const canExport = useRecoilValue(UserHasPrivilege('person.actions.export'));

  const showRenameModal = (
    listId: number,
    listName: string,
    type: string,
    setup: object
  ) => {
    NiceModal.show('RenameNewsletterOrFilter', {
      listId,
      listName,
      type,
      setup,
    });
  };
  const showExportContactsFromNewsletterOrFilterModal = (setup: SetUpType) => {
    NiceModal.show('ExportContactsFromNewsletterOrFilter', {
      setup,
    });
  };
  const menu = () => (
    <Menu>
      <Menu.Item key="createfilter" icon={<CdFilterIcon fixedWidth />}>
        <a
          href={getStateUrl(`app.private.people.contacts.list`, {
            createList: true,
          })}
        >
          {gettextCatalog.getString('Create filter')}
        </a>
      </Menu.Item>
      <Menu.Item
        key="createList"
        onClick={() =>
          showCreateNewsletterListModal({
            refreshSegments: () => setCurrent('newsletterLists'),
          })
        }
        icon={<CdNewsletterListIcon fixedWidth />}
      >
        {gettextCatalog.getString('Create newsletter list')}
      </Menu.Item>
      <Menu.Item
        key="createParticipantList"
        onClick={showCreateParticipantListModal}
        icon={<CdParticipantList fixedWidth />}
      >
        {gettextCatalog.getString('Create participant list')}
      </Menu.Item>
    </Menu>
  );

  const moreMenu = (
    id: number,
    name: string,
    type: string,
    setup: SetUpType,
    canAddPeopleToList: boolean,
    filter: NewsletterandFilterDataInterface
  ) => (
    <Menu key="moremenu">
      <Menu.Item key="seecontact" icon={<CdEyeIcon fixedWidth />}>
        <a href={getStateUrl(`app.private.people.contacts.list`, { list: id })}>
          {gettextCatalog.getString('See contacts')}
        </a>
      </Menu.Item>
      {canCreateEditLists && (
        <Menu.Item
          key="rename"
          icon={<CdRenameFilter fixedWidth />}
          onClick={() => showRenameModal(id, name, type, setup)}
        >
          {gettextCatalog.getString('Rename')}
        </Menu.Item>
      )}
      {type === 'filter' && canCreateEditLists && (
        <Menu.Item
          key="editFilters"
          icon={<CdEditIcon fixedWidth />}
          onClick={() =>
            navigate('app.private.people.contacts.list', {
              list: id,
              editing: true,
            })
          }
        >
          {gettextCatalog.getString('Edit filter')}
        </Menu.Item>
      )}
      {type !== 'filter' && canAddPeopleToList && (
        <Menu.Item
          key="addcontacttolist"
          icon={<CdUserPlusIcon fixedWidth />}
          onClick={() => showAddContactsToListsModal(filter)}
        >
          {gettextCatalog.getString('Add contacts in bulk')}
        </Menu.Item>
      )}

      {canExport && (
        <Menu.Item
          key="exportcontact"
          icon={<CdDownloadIcon fixedWidth />}
          onClick={() => showExportContactsFromNewsletterOrFilterModal(setup)}
        >
          {gettextCatalog.getString('Export contacts')}
        </Menu.Item>
      )}
      {canDeleteLists && (
        <Menu.Item
          key="deletefilter"
          icon={<CdDeleteIcon fixedWidth />}
          onClick={() => confirmDeleteModal(id, name, type)}
        >
          {type === 'filter'
            ? gettextCatalog.getString('Delete filter')
            : type === 'newsletter'
              ? gettextCatalog.getString('Delete newsletter list')
              : gettextCatalog.getString('Delete participant list')}
        </Menu.Item>
      )}
    </Menu>
  );

  const confirmDeleteModal = (listId: number, listName: string, type: string) =>
    showConfirmModalDeprecated({
      title: gettextCatalog.getString(`Delete "{{listName}}"?`, {
        listName,
      }),
      message: gettextCatalog.getString(
        `Do you want to delete "{{listName}}"? Deleting a list will not delete the contacts in the list.`,
        {
          listName,
        }
      ),
      icon: <CdDeleteIcon />,
      okType: 'danger',
      okText: gettextCatalog.getString('Delete'),
      onOk: async () => {
        await deleteList(listId, type);
      },
    });

  const showCreateParticipantListModal = useCallback(() => {
    NiceModal.show('CreateParticipantList', {}).then(() => {
      setCurrent('participantLists');
    });
  }, []);

  const showAddContactsToListsModal = useCallback(
    (filter: NewsletterandFilterDataInterface) => {
      NiceModal.show('AddContactsToLists', { filter });
    },
    []
  );

  const tabItems = [
    {
      key: 'filters',
      icon: <CdFilterIcon />,
      title: gettextCatalog.getString(`Filters`),
      subtitle: gettextCatalog.getString(
        `Contacts that match a set of filter criteria. This is automatically updated`
      ),
      content: (
        <CdSimpleTable<NewsletterandFilterDataInterface>
          noStyle
          columns={getColumns(moreMenu, hasMultipleChurches, gettextCatalog)}
          recoilSource={PeopleListByTypeAndSearchQuery({
            type: 'filter',
            searchParameter: searchKeyWord,
          })}
          emptyStateProps={{
            title: gettextCatalog.getString('No lists found.'),
            EmptyStateIcon: <CdParticipantList />,
          }}
        />
      ),
    },
    {
      key: 'participantLists',
      icon: <CdParticipantList />,
      title: gettextCatalog.getString('Participant Lists'),
      subtitle: gettextCatalog.getString(
        'Easily manage lists of participants for events or classes'
      ),
      content: (
        <CdSimpleTable<NewsletterandFilterDataInterface>
          noStyle
          recoilSource={PeopleListByTypeAndSearchQuery({
            type: 'participant',
            searchParameter: searchKeyWord,
          })}
          columns={getColumns(
            moreMenu,
            hasMultipleChurches,
            gettextCatalog,
            true
          )}
          emptyStateProps={{
            title: gettextCatalog.getString('No lists found.'),
            EmptyStateIcon: <CdParticipantList />,
          }}
        />
      ),
    },
    {
      key: 'newsletterLists',
      icon: <CdNewsletterListIcon />,
      title: gettextCatalog.getString('Newsletter Lists'),
      subtitle: gettextCatalog.getString(
        'Let people easily sign-up to your newsletter lists from your website or forms.'
      ),
      content: (
        <CdSimpleTable<NewsletterandFilterDataInterface>
          noStyle
          columns={getColumns(
            moreMenu,
            hasMultipleChurches,
            gettextCatalog,
            true
          )}
          recoilSource={PeopleListByTypeAndSearchQuery({
            type: 'newsletter',
            searchParameter: searchKeyWord,
          })}
          emptyStateProps={{
            title: gettextCatalog.getString('No lists found.'),
            EmptyStateIcon: <CdParticipantList />,
          }}
        />
      ),
    },
  ];
  return (
    <CdPage
      pageHeaderProps={{
        extra: [
          <Space key="menu">
            <Input
              placeholder={gettextCatalog.getString('Search for list')}
              value={searchKeyWord}
              onChange={(e) => setSearchKeyWord(e.target.value)}
            />
            <Button icon={<CdSearch />}>
              {gettextCatalog.getString('Search')}
            </Button>

            {canCreateEditLists && (
              <Dropdown
                key="create"
                overlay={menu}
                placement="bottomLeft"
                arrow
              >
                <Button type="primary">
                  <Space>
                    {gettextCatalog.getString('Create')}
                    <CdAngleDown />
                  </Space>
                </Button>
              </Dropdown>
            )}
          </Space>,
        ],
        title: gettextCatalog.getString('All lists'),
      }}
    >
      <CdTabs
        activeKey={current}
        onChange={onChange}
        fluidHeight
        items={tabItems}
      />
    </CdPage>
  );
};

export default function NewsletterListHOC($stateParams) {
  return (
    <Suspense fallback={<CdrPageLoader />} key="newsletterlisthoc">
      <NewsletterList $stateParams={$stateParams} />
    </Suspense>
  );
}

const getColumns = (
  moreMenu,
  hasMultipleChurches,
  gettextCatalog,
  showContactCounter = false
) =>
  [
    {
      active: true,
      dataIndex: 'name',
      key: 'name',
      title: gettextCatalog.getString('Name'),
      width: 400,
      render: (text, record) => (
        <a
          href={getStateUrl(`app.private.people.contacts.list`, {
            list: record.id,
          })}
        >
          {record.name}
        </a>
      ),
    },
    (showContactCounter && {
      active: true,
      dataIndex: 'peopleCount',
      key: 'peopleCount',
      title: gettextCatalog.getString('Number of contacts'),
      width: 200,
      render: (peopleCount) => (
        <Space align="center">
          <CdUsers />
          {gettextCatalog.getPlural(
            peopleCount || 0,
            '1 contact',
            '{{ $count | number }} contacts',
            {
              $count: peopleCount || 0,
            }
          )}
        </Space>
      ),
    }) ||
      undefined,
    (hasMultipleChurches && {
      active: true,
      dataIndex: 'churches',
      key: 'churches',
      title: gettextCatalog.getString('Parishes'),
      render: (churches) => (
        <>
          {churches.map((church, i) => (
            <Tag key={i}>{church}</Tag>
          ))}
        </>
      ),
    }) ||
      undefined,
    {
      active: true,
      dataIndex: 'buttons',
      key: 'buttons',
      title: '',
      align: 'right',
      render: (text, item: NewsletterandFilterDataInterface) => (
        <>
          <Space key="dropdown">
            <MessageListCreateDropdown
              buttonLabel={gettextCatalog.getString('Send Message')}
              buttonSize="small"
              recipients={[item]}
            />
            <Dropdown
              overlay={() =>
                moreMenu(
                  item.id,
                  item.name,
                  item.type,
                  item.setup,
                  item.canAddPeopleToList,
                  item
                )
              }
              placement="bottomLeft"
              key="dropdownsendmessage"
            >
              <Button size="small">
                {gettextCatalog.getString('More')} <CdAngleDown />
              </Button>
            </Dropdown>
          </Space>
        </>
      ),
    },
  ].filter(
    (x) => x !== undefined
  ) as ColumnType<NewsletterandFilterDataInterface>[];
