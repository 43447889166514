import { react2angular } from 'react18-react2angular';

import sharedContext from '@/app/shared/components/shared-context-root/shared-context-root.component';
import { EnquiriesPage } from '@/react/calendar/availabilities/pages/EnquiriesPage';

angular
  .module('cdApp.calendar')
  .component(
    'cdrEnquiriesPage',
    react2angular(sharedContext.use(EnquiriesPage), [], [])
  );
