import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Button, Tabs, Row, Col, Card } from 'antd';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useRecoilValue } from 'recoil';

import { gettextCatalog } from '../../../services/I18nService';
import { navigate } from '../../../services/StateServiceFactory';
import { IntentionFee } from '../../models/fee';
import { getFilteredFees } from '../../redux/intention-fees/data.converter';
import { StyledTable } from '../../../shared/antd/StyledAntdComponents';
import {
  getIntentionFees,
  getIntentionFeeRecipients,
  getStoleFees,
} from '../../redux/intention-fees/Selectors';
import {
  fetchIntentionFees,
  fetchIntentionFeeRecipients,
} from '../../redux/intention-fees/Actions';

import { IntentionSettings } from './IntentionSettings';
import { GetIntentionFeeColumns } from './IntentionFee.columns';
import { IntentionRecipientColumns } from './IntentionRecipient.columns';
import { openIntentionRecipientModal } from './IntentionRecipient.modal';

import { CdExclamationTriangleIcon } from '@/react/shared/components/Icons';
import CdrEmptyState from '@/react/shared/components/CdrEmptyState';
import { UserHasPrivilege } from '@/react/user/store/permissions';
import AuthorizationService from '@/react/services/AuthorizationService';
import { CdPage } from '@/react/shared/components/cd-page/CdPage';

// Tab constants
enum IntentionFeeTabs {
  INTENTIONEN = 'intentionen',
  STOLARIEN = 'stolarien',
  RECIPIENTS = 'recipients',
  ADVANCED = 'advanced',
}

// Props
interface IntentionFeesProps {
  tab?: string;
}

const IntentionFeesInner: FunctionComponent<IntentionFeesProps> = ({ tab }) => {
  // Redux
  const dispatch = useDispatch();

  // Retrieve required entities from Redux store
  const allIntentionFees = useSelector(getIntentionFees);
  const allStoleFees = useSelector(getStoleFees);
  const intentionFees: IntentionFee[] = getFilteredFees(allIntentionFees);
  const stolesFees: IntentionFee[] = getFilteredFees(allStoleFees);
  const intentionFeeRecipients = useSelector(getIntentionFeeRecipients);

  const canAccessIntentionFee = useRecoilValue(
    UserHasPrivilege('intentionFee.intentionen.edit')
  );

  // Define initialization hook and entity reload hooks
  const [currentTab, setCurrentTab] = useState<IntentionFeeTabs>();

  const refreshOverviewTable = useCallback(
    (tab: IntentionFeeTabs) => {
      // Fetch recipients
      if (tab === IntentionFeeTabs.RECIPIENTS) {
        dispatch(fetchIntentionFeeRecipients());
      } else if (
        tab === IntentionFeeTabs.INTENTIONEN ||
        tab === IntentionFeeTabs.STOLARIEN
      ) {
        dispatch(fetchIntentionFees({ type: tab as any }));
      }
    },
    [dispatch]
  );

  // Retrieve required entities
  useEffect(() => {
    const defaultTab = tab || IntentionFeeTabs.INTENTIONEN;
    setCurrentTab(defaultTab as IntentionFeeTabs);
    refreshOverviewTable(defaultTab as IntentionFeeTabs);
  }, [refreshOverviewTable, tab]);

  const onTabChange = (tab: IntentionFeeTabs) => {
    setCurrentTab(tab);
    refreshOverviewTable(tab);
  };

  // Get table config based on state
  let dataSource: any;
  switch (currentTab) {
    case IntentionFeeTabs.INTENTIONEN:
      dataSource = intentionFees || [];
      break;
    case IntentionFeeTabs.STOLARIEN:
      dataSource = stolesFees || [];
      break;
    case IntentionFeeTabs.RECIPIENTS:
      dataSource = intentionFeeRecipients || [];
      break;
  }

  const intentionFeeTabs = [
    {
      label: gettextCatalog.getString('Intentionen'),
      key: IntentionFeeTabs.INTENTIONEN,
      children: (
        <InnerTab
          type={IntentionFeeTabs.INTENTIONEN}
          dataSource={dataSource}
          currentTab={currentTab}
        />
      ),
    },
    AuthorizationService.hasPackage('intentionExtended') && {
      label: gettextCatalog.getString('Stoles'),
      key: IntentionFeeTabs.STOLARIEN,
      children: (
        <InnerTab
          type={IntentionFeeTabs.STOLARIEN}
          dataSource={dataSource}
          currentTab={currentTab}
        />
      ),
    },
    AuthorizationService.hasPackage('intentionExtended') && {
      label: gettextCatalog.getString('Recipients'),
      key: IntentionFeeTabs.RECIPIENTS,
      children: (
        <InnerTab
          type={IntentionFeeTabs.RECIPIENTS}
          dataSource={dataSource}
          currentTab={currentTab}
        />
      ),
    },
    {
      label: gettextCatalog.getString(
        'Advanced',
        undefined,
        'Intentions settings'
      ),
      key: IntentionFeeTabs.ADVANCED,
      children: <IntentionSettings />,
    },
  ];

  return canAccessIntentionFee ? (
    <Card>
      <Tabs
        activeKey={currentTab}
        defaultActiveKey={IntentionFeeTabs.INTENTIONEN}
        onChange={onTabChange}
        type="card"
        items={intentionFeeTabs}
      />
    </Card>
  ) : (
    <CdrEmptyState
      title={gettextCatalog.getString(
        'You do not have access to Intention Fee settings.'
      )}
      subtitle={gettextCatalog.getString(
        'Ask your organisation administrator to grant access.'
      )}
      EmptyStateIcon={<CdExclamationTriangleIcon />}
    />
  );
};

const InnerTab = (props: {
  type: IntentionFeeTabs;
  dataSource;
  currentTab;
}) => {
  const createIntentionFee = () => {
    navigate('app.private.settings.fees.create', {
      currentState: 'create',
      type: props.currentTab,
      data: null,
    });
  };

  const columns =
    IntentionFeeTabs.RECIPIENTS === props.currentTab
      ? IntentionRecipientColumns()
      : GetIntentionFeeColumns(props.currentTab);

  return (
    <Row>
      <Col span={24}>
        <div
          style={{
            float: 'right',
            marginRight: '8px',
            marginBottom: '8px',
          }}
        >
          {props.type === IntentionFeeTabs.RECIPIENTS ? (
            <Button
              onClick={() => openIntentionRecipientModal({})}
              type="primary"
            >
              <FontAwesomeIcon icon={faPlus} style={{ marginRight: 8 }} />
              {gettextCatalog.getString('Create Recipient')}
            </Button>
          ) : null}
          {[IntentionFeeTabs.INTENTIONEN, IntentionFeeTabs.STOLARIEN].includes(
            props.type
          ) && (
            <Button type="primary" onClick={() => createIntentionFee()}>
              <FontAwesomeIcon icon={faPlus} style={{ marginRight: 8 }} />
              {gettextCatalog.getString('Create Fee')}
            </Button>
          )}
        </div>
        <StyledTable
          id="feesTable"
          style={{ padding: 8 }}
          dataSource={props.dataSource}
          columns={columns as any}
          scroll={{ x: true }}
          bordered={true}
          rowKey="id"
        />
      </Col>
    </Row>
  );
};

export const IntentionFees = ({ tab }: IntentionFeesProps) => (
  <CdPage
    pageHeaderProps={{
      title: gettextCatalog.getString('Intention Fees'),
    }}
  >
    <IntentionFeesInner tab={tab} />
  </CdPage>
);
