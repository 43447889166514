import { Form, FormInstance } from 'antd';
import { get } from 'lodash';

interface FormWatchedTextProps {
  name: string | number | (string | number)[];
  defaultValue?: string;
  form: FormInstance;
}

export const FormWatchedText = (props: FormWatchedTextProps) => {
  const values = Form.useWatch([], props.form);
  return get(values, props.name) || props.defaultValue;
};
