import React from 'react';

import cdApp from '../../../../config';
import gettextCatalog from '../../../../services/I18nService';
import { CdSelectProps, CdSelect } from '../../cd-select/CdSelect';

export const CdChurchParishSelect = (
  props: Pick<CdSelectProps, 'value' | 'onChange'>
) => (
  <CdSelect
    placeholder={gettextCatalog.getString('Choose a parish')}
    mode="multiple"
    maxTagCount="responsive"
    options={cdApp.organization.churches.map((church) => ({
      value: church.id,
      label: church.name,
    }))}
    {...props}
  />
);
