import { Form } from 'antd';
import moment from 'moment';
import { useEffect } from 'react';

import { SetDrawerPropsType } from '../../../shared/components/cd-drawer/CdDrawer';
import { ModalType } from '../../../shared/components/cd-modal-new/CdModal2';
import { useTaxonomies } from '../../hooks/use-taxonomies';
import { AvailabilityTaxonomy } from '../../../taxonomies/services/Taxonomies';
import { CategoryType } from '../../../shared/models/category';
import { useGroups } from '../../../organization/store/organization';
import cdApp from '../../../config';
import { postEventAPI } from '../../store/events/event';
import { EventType } from '../../models/calendar';
import NotificationService from '../../../services/NotificationService';
import { DateInputValue } from '../../event-details/components/date-input/DateInput';
import { getUsersInAvailabilityGroup } from '../helpers/availability.helpers';

export const useAvailabilityDrawer = (
  setDrawerProps: SetDrawerPropsType,
  closeDrawer: () => void
) => {
  const [form] = Form.useForm();
  const mainCategoryId = Form.useWatch('mainCategory', form);
  const bookingOptionIds = Form.useWatch('bookingOptionIds', form);
  const date: DateInputValue = Form.useWatch('date', form);
  const { taxonomies } = useTaxonomies<AvailabilityTaxonomy>(
    CategoryType.availability
  );
  const { groups } = useGroups();

  const selectedTaxonomy = taxonomies?.find(
    (taxonomy) => taxonomy.id === mainCategoryId
  );
  const userIdsInGroups = getUsersInAvailabilityGroup(selectedTaxonomy, groups);

  const selectedBookingOptions =
    selectedTaxonomy?.bookingOptions.filter((bookingOption) =>
      bookingOptionIds.includes(bookingOption.id)
    ) || [];

  const bookingLink = selectedTaxonomy
    ? new URL(
        `/${cdApp.organization.id}/${selectedTaxonomy.alias}`,
        cdApp.references.bookingPage
      ).href
    : null;

  const initialValues = {
    user: cdApp.me.id,
    date: {
      startDate: moment().startOf('hour').add(1, 'hour'),
      endDate: moment().startOf('hour').add(2, 'hour'),
      allDay: false,
    },
  };

  const onSave = async () => {
    const values = await form.validateFields();
    try {
      await postEventAPI({
        startDate: moment(values.date.startDate).toISOString(),
        endDate: moment(values.date.endDate).toISOString(),
        mainCategory: values.mainCategory,
        rrule: values.date.rrule,
        users: [values.user],
        bookingOptionIds: values.bookingOptionIds,
        type: EventType.Availability,
      });
      closeDrawer();
    } catch (error) {
      NotificationService.notifyError(error);
    }
  };
  const onCancel = () => {
    closeDrawer();
  };

  useEffect(() => {
    setDrawerProps({
      width: 800,
      modalType: ModalType.POPUP,
      hideOk: true,
      hideCancel: true,
      hideTopbar: true,
    });
  }, [setDrawerProps]);

  useEffect(() => {
    form.setFieldValue('bookingOptionIds', []);
  }, [form, mainCategoryId]);

  return {
    date,
    form,
    initialValues,
    userIdsInGroups,
    bookingLink,
    selectedTaxonomy,
    selectedBookingOptions,
    onSave,
    onCancel,
  };
};
