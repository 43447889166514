import { get } from 'lodash';
import { $injector } from 'ngimport';

import { mainApi } from '../api';

import { Packages } from '@/react/shared/models/authentication';

export interface PrivilegeInput {
  entityId: number | string;
  entityType: string;
  privileges: string | string[];
}

class AuthorizationService {
  public hasPermission: (permission: string) => boolean = (permission) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const Authorization: any = $injector.get('Authorization');
    return Authorization.hasPermission(permission);
  };

  public hasAnyPermission: (permissions: string[]) => boolean = (
    permissions
  ) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const Authorization: any = $injector.get('Authorization');
    return Authorization.hasAnyPermission(permissions);
  };

  public hasPackage: (_package: keyof Packages) => boolean = (_package) => {
    if (!$injector) {
      return true;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const Authorization: any = $injector.get('Authorization');
    return Authorization.hasPackage(_package);
  };

  /**
   * Check if the user has a privilege in any church or in the organization
   * @see {@link https://app.shortcut.com/churchdesk/write/IkRvYyI6I3V1aWQgIjY3NTk4M2VkLTNlYWEtNGY2Yy1iNzFkLWRmZjM5MWI0YmQ0NCI= | Docs }
   * @param {String} privilege Example: 'contribution.create'
   * @returns {Promise<Boolean>} whether the user has the privilege
   */
  public hasPrivilege: (privilege: string) => Promise<boolean> = async (
    privilege
  ) => {
    const res = await mainApi.get('/acl/has-privilege', {
      privilege,
    });
    const data = res.data as { hasPrivilege: boolean };
    if (res.ok) {
      return Promise.resolve(!!data.hasPrivilege);
    }
    throw get(res.data, 'message');
  };
}

export default new AuthorizationService();
