import { mainApi } from '@/react/api';
import { Taxonomy } from '@/react/shared/models/taxonomy';

export const createNewTaxonomy = async (taxonomy: Partial<Taxonomy>) => {
  const response = await mainApi.post('/taxonomies', taxonomy);
  if (response.ok) {
    return response.data as Taxonomy;
  }
  throw response.data;
};

export const updateTaxonomy = async (taxonomy: Partial<Taxonomy>) => {
  const response = await mainApi.put(`/taxonomies/${taxonomy.id}`, taxonomy);
  if (response.ok) {
    return response.data as Taxonomy;
  }
  throw response.data;
};

export const getTaxonomy = async (id: number) => {
  const response = await mainApi.get(`/taxonomies/${id}`);
  if (response.ok) {
    return response.data as Taxonomy;
  }
  throw response.data;
};
