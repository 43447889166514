import React from 'react';
import { Button, Col, Form, Row, Space, Typography } from 'antd';

import { EventType } from '../../models/calendar';
import { CdVerticalSpace } from '../../../shared/components/cd-vertical-space/CdVerticalSpace';
import { isInArray, required } from '../../../shared/antd/validations/common';
import { gettextCatalog } from '../../../services/I18nService';
import { CdUserSelect } from '../../../groups/components/CdUserSelect';
import { CalendarEventTabs } from '../../types/event';
import { createCdDrawer } from '../../../shared/components/cd-drawer/CdDrawer';
import { FieldSection } from '../../event-details/components/FieldSection';
import { MINIMUM_COLUMN_WIDTH } from '../../event-details/constants';
import { DateInput } from '../../event-details/components/date-input/DateInput';
import { FormItemNoErrorMessage } from '../../event-details/components/form-items/FormItemNoErrorMessage';
import { CustomTabs } from '../../event-details/EventDetailsPage';
import { CdEventCategorySelect } from '../../event-details/components/CdEventCategorySelect';
import { useAvailabilityDrawer } from '../hooks/use-availability-drawer';
import { CategoryType } from '../../../shared/models/category';
import { CdFormSubmitButton } from '../../../shared/components/cd-form-submit-button/CdFormSubmitButton';

import { BookingOptionsSelect } from './BookingOptionsSelect';
import { BookingOptionsSummary } from './BookingOptionsSummary';

// TODO: Allow for passing initialValues from outside
export const showAvailabilityDrawer = createCdDrawer(
  ({ setDrawerProps, closeDrawer }) => {
    const {
      form,
      date,
      initialValues,
      userIdsInGroups,
      bookingLink,
      selectedTaxonomy,
      selectedBookingOptions,
      onSave,
      onCancel,
    } = useAvailabilityDrawer(setDrawerProps, closeDrawer);

    return (
      <Form form={form} initialValues={initialValues} layout="vertical">
        <CdVerticalSpace>
          <Row gutter={16} wrap={false}>
            <Col flex={`${MINIMUM_COLUMN_WIDTH + 16}px`}>
              <CdVerticalSpace size={24}>
                <FieldSection>
                  <FormItemNoErrorMessage
                    name="user"
                    dependencies={['mainCategory']}
                    rules={[
                      required(gettextCatalog.getString('A user is required.')),
                      isInArray(
                        userIdsInGroups.length === 0 ? null : userIdsInGroups,
                        gettextCatalog.getString(
                          'This user is not part of any groups using this availability category'
                        )
                      ),
                    ]}
                  >
                    <CdUserSelect size="large" includeIds={userIdsInGroups} />
                  </FormItemNoErrorMessage>
                </FieldSection>

                <FieldSection>
                  <Form.Item name="date">
                    <DateInput
                      eventId={null}
                      previousRRule={null}
                      eventType={EventType.Availability}
                    />
                  </Form.Item>
                </FieldSection>
              </CdVerticalSpace>
            </Col>
            <Col flex="auto">
              <Space>
                <CdFormSubmitButton form={form} size="large" onClick={onSave}>
                  {gettextCatalog.getString('Save')}
                </CdFormSubmitButton>
                <Button size="large" onClick={onCancel}>
                  {gettextCatalog.getString('Cancel')}
                </Button>
              </Space>
            </Col>
          </Row>
          <CustomTabs
            type="card"
            items={[
              {
                key: CalendarEventTabs.DETAILS_PAGE,
                label: gettextCatalog.getString('Availability details'),
                forceRender: true,
                children: (
                  <Row gutter={16}>
                    <Col flex={`${MINIMUM_COLUMN_WIDTH + 16}px`}>
                      <FieldSection>
                        <Form.Item
                          name="mainCategory"
                          label={gettextCatalog.getString('Category')}
                          rules={[
                            required(
                              gettextCatalog.getString(
                                'Please choose a category'
                              )
                            ),
                          ]}
                          extra={
                            selectedTaxonomy && (
                              <CdVerticalSpace style={{ paddingTop: 8 }}>
                                {selectedTaxonomy.description}
                                {bookingLink && (
                                  <Typography.Link
                                    href={bookingLink}
                                    target="_blank"
                                    copyable
                                  >
                                    {bookingLink}
                                  </Typography.Link>
                                )}
                              </CdVerticalSpace>
                            )
                          }
                        >
                          <CdEventCategorySelect
                            placeholder={gettextCatalog.getString(
                              'Add category'
                            )}
                            showSecondaryText={false}
                            type={CategoryType.availability}
                          />
                        </Form.Item>
                      </FieldSection>
                      <FieldSection>
                        <Form.Item
                          name="bookingOptionIds"
                          label={gettextCatalog.getString('Booking options')}
                          rules={[
                            required(
                              gettextCatalog.getString(
                                'Please select minimum one booking option'
                              )
                            ),
                          ]}
                        >
                          <BookingOptionsSelect
                            taxonomyId={selectedTaxonomy?.id}
                          />
                        </Form.Item>
                      </FieldSection>
                      <BookingOptionsSummary
                        bookingOptions={selectedBookingOptions}
                        date={date}
                      />
                    </Col>
                  </Row>
                ),
              },
            ]}
          />
        </CdVerticalSpace>
      </Form>
    );
  }
);
