import cdApp from '../../config';

export const sortString = (
  a: string | undefined,
  b: string | undefined,
  locale?: string
) =>
  (a ?? '')
    .toLowerCase()
    .localeCompare(
      (b ?? '').toLowerCase(),
      locale || cdApp?.organization?.locale?.language || 'da'
    );
