import { Form } from 'antd';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { FetchUsers } from '../redux/actions';

import NotificationService from '@/react/services/NotificationService';
import { handleAntFormValidationErrors } from '@/react/services/ErrorHandlingService';
import { mainApi } from '@/react/api';
import { useOrganization } from '@/react/organization/hooks/useOrganization';

export const useTimeBalance = ({
  currentUserId,
  timeRegistrationEnabled,
  initialTimeBalance,
}: {
  currentUserId: number;
  timeRegistrationEnabled: boolean;
  initialTimeBalance: number;
}) => {
  const [isValueChanged, setIsValueChanged] = useState<boolean>(false);
  const { organizationSettings, refreshOrganizationSettings } =
    useOrganization();
  const [timeBalanceForm] = Form.useForm();
  const timeBalanceFormInitialValue = {
    timeRegistrationEnabled,
    initialTimeBalance,
  };

  const dispatch = useDispatch();

  const onOk = useCallback(async () => {
    const values = await timeBalanceForm.validateFields().catch((err) => {
      handleAntFormValidationErrors(err);
    });
    if (!values) {
      return;
    } else {
      const response = await mainApi.put(
        `/users/${currentUserId}/time-registrations/settings`,
        {
          timeRegistrationEnabled: values.timeRegistrationEnabled,
          initialTimeBalance: values.initialTimeBalance,
        }
      );
      if (!response.ok) {
        NotificationService.notifyError((response as any)?.data?.message);
        throw response;
      }
      refreshOrganizationSettings();
      dispatch(FetchUsers());
      return response.data;
    }
  }, [timeBalanceForm, currentUserId, dispatch, refreshOrganizationSettings]);

  const formValueChanged = (_, allValues) => {
    if (
      allValues.timeRegistrationEnabled &&
      allValues.initialTimeBalance === undefined
    ) {
      setIsValueChanged(false);
    } else {
      setIsValueChanged(true);
    }
  };
  return {
    onOk,
    formValueChanged,
    organizationSettings,
    isValueChanged,
    timeBalanceForm,
    timeBalanceFormInitialValue,
  };
};
