import _ from 'lodash';
import { createSelector } from 'reselect';

import { AppState } from '../../redux';
import { Me } from '../../shared/models/authentication';
import { Organization } from '../../shared/models/organization';

export const getChurchId: (state: AppState) => number = (state) =>
  _.get(state, 'config.organizationData.churchId');

export const getCurrentOrganization = (state: AppState): Organization =>
  _.get(state, 'config.organization') as unknown as Organization;

export const getOrganizationId: (state: AppState) => number = createSelector(
  getCurrentOrganization,
  (organization) => organization?.id
);

export const getLocale = (state: AppState): string => {
  const currentOrganization = getCurrentOrganization(state);
  return _.get(currentOrganization, 'locale.language', 'gb');
};

export const getCurrentCountryCode = (state: AppState): string => {
  const currentOrganization = getCurrentOrganization(state);
  return currentOrganization ? currentOrganization.countryIso2 : 'gb';
};

export const getCurrentLanguage = (state: AppState): string => {
  const currentOrganization = getCurrentOrganization(state);
  return currentOrganization
    ? _.get(currentOrganization, 'locale.language')
    : 'en-gb';
};

export const getCurrentUser = (state: AppState): Me =>
  _.get(state, 'config.me', {}) as unknown as Me;

export const getCurrentUserId = (state: AppState): number =>
  _.get(state, 'config.me.id');

export const getNationalHolidays = (state: AppState) =>
  _.get(state, 'calendar.view.nationalHolidays', []);

export const getLiturgicalHolidays = (state: AppState) =>
  _.get(state, 'calendar.view.liturgicalHolidays', []);

export const getOrganizations = (state: AppState) => {
  const organizations = _.get(state, 'config.me.organizations', null);
  return organizations ? organizations.asMutable({ deep: true }) : [];
};
