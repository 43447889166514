import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppState } from '../../../redux';
import { createActionValueHook } from '../../../redux/utils';

export interface AvailabilitiesState {
  selectedAvailabilities: Record<number, boolean>;
}

// Initial State
const initialState: AvailabilitiesState = { selectedAvailabilities: {} };

// Slice
export const availabilitiesSlice = createSlice({
  name: 'availabilities',
  initialState,
  reducers: {
    initialize(state, action: PayloadAction<AvailabilitiesState>) {
      state.selectedAvailabilities =
        action.payload.selectedAvailabilities ||
        initialState.selectedAvailabilities;
    },
    setState(state, action: PayloadAction<Record<number, boolean>>) {
      state.selectedAvailabilities = action.payload || {};
    },
  },
});

export const availabilitiesRootSelector = (state: AppState) =>
  state.calendar.availabilities;

export const availabilityFilters = createSelector(
  availabilitiesRootSelector,
  (availabilities) => availabilities.selectedAvailabilities
);

export const availabilityIdsSelector = (state: AppState) =>
  Object.keys(state.calendar.availabilities?.selectedAvailabilities)
    .filter(
      (availabilityId) =>
        state.calendar.availabilities?.selectedAvailabilities[availabilityId]
    )
    .map(Number);

export const useAvailabilitiesFilter = createActionValueHook<
  unknown,
  Record<number, boolean>,
  Record<number, boolean>
>(availabilitiesSlice.actions.setState, availabilityFilters);

export const availabilitiesReducer = availabilitiesSlice.reducer;
