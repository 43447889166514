import { mainApi } from '@/react/api';
import cdApp from '@/react/config';
import { handleError } from '@/react/services/ErrorHandlingService';

export const checkIfAliasExist = async ({
  alias,
  entityId,
  entityType,
}: { alias: string; entityId?: number; entityType?: string }) => {
  const response = await mainApi.get<any>(`/v2/organizations/alias-check`, {
    alias,
    organizationId: cdApp.organization.id,
    entityId,
    entityType,
  });

  if (!response.ok) {
    handleError(response);
    return null;
  }
  return response.data;
};

export function angularDebounceValidator(func, wait) {
  let timeout;
  return function (...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
}
