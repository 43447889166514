import { Form } from 'antd';
import React, { useEffect, useMemo } from 'react';

import CdrPageLoader from '../../shared/components/CdrPageLoader';

import { DetailsForm } from './DetailsForm';
import { OptionForm } from './OptionForm';
import {
  MenuItem,
  VerticalTabMenu,
} from './components/vertical-tab-menu/VerticalTabMenu';
import { useAvailabilityFormData } from './hooks/useAvailabilityFormData';
import { FormWatchedText } from './components/vertical-tab-menu/FormWatchedText';

import gettextCatalog from '@/react/services/I18nService';
import {
  ModalType,
  createCdModal,
} from '@/react/shared/components/cd-modal-new/CdModal2';

type BookingPageSettingsModalProps = {
  taxonomyId?: number;
};

export const showBookingPageSettingsModal =
  createCdModal<BookingPageSettingsModalProps>(
    ({ setModalProps, taxonomyId }) => {
      const {
        initialValues,
        form,
        onSave,
        onSaveAndContinue,
        isLoadingExistingTaxonomy,
        title,
        activeItem,
        setActiveItem,
        deleteBookingOption,
        currentBookingOptions,
        formData,
      } = useAvailabilityFormData(taxonomyId);

      useEffect(() => {
        setModalProps({
          title,
          modalType: ModalType.MODAL,
          alternativeButtonText: gettextCatalog.getString(
            'Save and continue editing'
          ),
          okText: gettextCatalog.getString('Save'),
          onOk: onSave,
          width: 850,
          handleAlternativeButtonClick: onSaveAndContinue,
        });
      }, [onSave, onSaveAndContinue, setModalProps, title]);

      const menuItems: MenuItem[] = useMemo(
        () => [
          {
            label: gettextCatalog.getString('Booking page details'),
            key: 'details',
          },
          {
            type: 'group',
            label: gettextCatalog.getString('Options'),
            children: [
              ...(currentBookingOptions ?? []).map((option, index) => ({
                key: 'option-' + index,
                label: (
                  <FormWatchedText
                    name={['bookingOptions', index, 'name']}
                    form={form}
                    defaultValue={gettextCatalog.getString('Unnamed option')}
                  />
                ),
              })),
              {
                key: 'add-option',
                label: '+ ' + gettextCatalog.getString('Add option'),
              },
            ],
          },
        ],
        [currentBookingOptions, form]
      );

      return (
        <VerticalTabMenu
          menuItems={menuItems}
          activeItem={activeItem}
          setActiveItem={setActiveItem}
        >
          {isLoadingExistingTaxonomy ? (
            <CdrPageLoader />
          ) : (
            <Form form={form} layout="vertical" initialValues={initialValues}>
              <div
                // Making sure that all form fields are always mounted
                // because AntD validation works only on mounted fields
                style={{ display: activeItem === 'details' ? 'block' : 'none' }}
              >
                <DetailsForm
                  formData={formData}
                  initialValues={initialValues}
                />
              </div>
              {currentBookingOptions?.map((option, index) => (
                <div
                  style={{
                    display:
                      activeItem === 'option-' + index ? 'block' : 'none',
                  }}
                >
                  <OptionForm
                    key={index}
                    index={index}
                    onDeleteBookingOption={deleteBookingOption}
                  />
                </div>
              ))}
            </Form>
          )}
        </VerticalTabMenu>
      );
    }
  );
