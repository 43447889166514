import { selectorFamily } from 'recoil';
import { useSuspenseQuery } from '@tanstack/react-query';

import { ChurchesWithPermissionContext } from '../../shared/models/resource';
import ResourceService from '../../shared/services/ResourceService';

import AuthorizationService from '@/react/services/AuthorizationService';

type UserChurchesByPermissionInput = {
  permissionContext: 'form' | 'contribution' | 'people' | 'calendar';
  permissionType: string;
};

/**
 * Get the churches where the user has the specified permission
 * @param permissionContext Examples: 'form', 'contribution', 'people', 'calendar'
 * @param permissionType Examples: 'create'
 */
export const UserChurchesWithPermissionQuery = selectorFamily<
  ChurchesWithPermissionContext['churches'],
  UserChurchesByPermissionInput
>({
  key: 'UserChurchesWithPermissionQuery',
  get:
    ({ permissionContext, permissionType }) =>
    async () => {
      const res = await ResourceService.getChurchesV3(
        permissionContext,
        permissionType
      );
      return res?.churches?.filter((item) => item.access);
    },
});

/**
 * Check if the user has a privilege in any church or in the organization
 * Example: UserHasPrivilege('contribution.create')
 * Further documentation: https://app.shortcut.com/churchdesk/write/IkRvYyI6I3V1aWQgIjY3NTk4M2VkLTNlYWEtNGY2Yy1iNzFkLWRmZjM5MWI0YmQ0NCI=
 */
export const UserHasPrivilege = selectorFamily<boolean, string>({
  key: 'UserHasPrivilege',
  get: (privilege: string) => async () => {
    const access = await AuthorizationService.hasPrivilege(privilege);
    return access;
  },
});

export const useHasPrivilege = (privilege: string) => {
  const query = useSuspenseQuery({
    queryKey: ['useHasPrivilege', privilege],
    queryFn: () => AuthorizationService.hasPrivilege(privilege),
  });
  return query.data ?? false;
};
