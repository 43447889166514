import React from 'react';
import { Suspense } from 'react';
import { Badge, Button, Dropdown, Space } from 'antd';
import NiceModal from '@ebay/nice-modal-react';
import { useRecoilValue } from 'recoil';

import EventPopupService from '../services/EventPopupService';
import { Church } from '../models/calendar';
import { getEventVisibility } from '../event-details/services/getEventVisibility';
import { showAvailabilityDrawer } from '../availabilities/components/AvailabilityDrawer';

import cdApp from '@/react/config';
import { FeatureFlagQuery } from '@/react/services/FeatureToggleService';
import {
  CdCalendarPlusIcon,
  CdEventWithSignup,
  CdAbsence,
  CdAngleDown,
  // CdAvailbility,
} from '@/react/shared/components/Icons';
import { gettextCatalog } from '@/react/services/I18nService';

export const CalendarCreateDropdown = () => (
  <Suspense fallback={<CreateButton loading />}>
    <CalendarCreateDropdownInner />
  </Suspense>
);

const CalendarCreateDropdownInner = () => {
  const churches: Church[] = cdApp.organization.churches;
  const flagNewEventFormAsDefault = useRecoilValue(
    FeatureFlagQuery('new_event_form_as_default')
  );
  const hideOldEventForm = useRecoilValue(
    FeatureFlagQuery('disable_old_event_form')
  );
  /* const funeralCalendarEnabled = useRecoilValue(
    FeatureFlagQuery('funeral_calendar')
  );*/

  const newEventFormAsDefault = flagNewEventFormAsDefault || hideOldEventForm;

  const handleMenuClick = ({ key }) => {
    switch (key) {
      case 'legacyEvent':
        EventPopupService.createEventDetails('event');
        break;
      case 'legacyAbsence':
        EventPopupService.createEventDetails('absence');
        break;
      case 'newEvent':
        NiceModal.show('EventDrawer', {
          newEvent: {
            visibility: getEventVisibility(),
            churches:
              churches.length > 1 ? [] : churches.map((c) => ({ id: c.id })),
          },
        });
        break;
      case 'availability':
        showAvailabilityDrawer();
        break;
      default:
        break;
    }
  };
  const calendarCreateActions = [
    !hideOldEventForm && {
      label: newEventFormAsDefault
        ? gettextCatalog.getString('Old event form')
        : gettextCatalog.getString('Event'),
      icon: <CdCalendarPlusIcon />,
      key: 'legacyEvent',
    },
    {
      label: newEventFormAsDefault ? (
        gettextCatalog.getString('Event')
      ) : (
        <Space>
          {gettextCatalog.getString('Event')}
          {!hideOldEventForm && (
            <Badge count={gettextCatalog.getString('New design')} />
          )}
        </Space>
      ),
      icon: <CdEventWithSignup />,
      key: 'newEvent',
    },
  ];
  if (newEventFormAsDefault) {
    calendarCreateActions.reverse();
  }
  calendarCreateActions.push({
    label: gettextCatalog.getString('Absence'),
    icon: <CdAbsence />,
    key: 'legacyAbsence',
  });
  /* Availability drawer omitted in V1 if (funeralCalendarEnabled) {
    calendarCreateActions.push({
      label: gettextCatalog.getString('Availability'),
      icon: <CdAvailbility />,
      key: 'availability',
    });
  }*/

  return (
    <Dropdown
      className="dropdown-custom-2"
      overlayStyle={{ minWidth: 150 }}
      menu={{
        items: calendarCreateActions,
        onClick: handleMenuClick,
      }}
    >
      <CreateButton />
    </Dropdown>
  );
};

const CreateButton = (props) => (
  <Button {...props} size="middle" type="primary">
    <Space>
      {gettextCatalog.getString('Create', null, 'Create button in calendar')}
      <CdAngleDown />
    </Space>
  </Button>
);
