import { selectorFamily } from 'recoil';

import { ApiSearchResult, mainApi } from '../../api';
import { CategoryType, Category } from '../models/category';
import { Taxonomy } from '../../calendar/models/calendar';

import { registerRecoilRefresher } from '@/app/cdRecoilRefresher';
import { PortalCategoryType } from '@/react/portal/state/portal';

// Main class

class CategoryService {
  public async getCategories(): Promise<Category[]> {
    const response = await mainApi.get<Category[]>('/taxonomies');
    if (response.ok) {
      // We sort using localeCompare with locale set to 'EN' because customers prefer having categories such as AAAA at the top.
      return (response.data as Category[]).sort((a, b) =>
        a.name.localeCompare(b.name, 'en')
      );
    }
    throw response.data;
  }

  public async getCategoriesByType(
    type: CategoryType | PortalCategoryType
  ): Promise<Category[]> {
    const response = await mainApi.get('/taxonomies', { type });
    if (response.ok) {
      // We sort using localeCompare with locale set to 'EN' because customers prefer having categories such as AAAA at the top.
      return (response.data as Category[]).sort((a, b) =>
        a.name.localeCompare(b.name, 'en')
      );
    }
    throw response.data;
  }
}

const categoryService = new CategoryService();

export const CategoryByTypeQuery = selectorFamily<
  Category[],
  CategoryType | PortalCategoryType
>({
  key: 'CategoryByTypeQuery',
  get:
    (type) =>
    async ({ getCallback }) => {
      const categories = categoryService.getCategoriesByType(type);
      registerRecoilRefresher(
        CategoryByTypeQuery(type),
        getCallback(
          ({ refresh }) =>
            () =>
              refresh(CategoryByTypeQuery(type))
        )
      );
      return categories;
    },
});

export const PortalOrganizationEventCategories = selectorFamily<
  ApiSearchResult<Taxonomy>,
  number
>({
  key: 'PortalOrganizationEventCategories',
  get:
    (organizationId: number) =>
    async ({ getCallback }) => {
      const res = await mainApi.get<Taxonomy[]>(
        `/taxonomies?organizationId=${organizationId}`,
        { type: 'event' }
      );
      registerRecoilRefresher(
        PortalOrganizationEventCategories(organizationId),
        getCallback(
          ({ refresh }) =>
            () =>
              refresh(PortalOrganizationEventCategories(organizationId))
        )
      );
      // We sort using localeCompare with locale set to 'EN' because customers prefer having categories such as AAAA at the top.
      const data = res.data.sort((a, b) =>
        a.name.toLowerCase().localeCompare(b.name.toLowerCase(), 'en')
      );
      return { items: data, total: data.length };
    },
});

export const PortalOrganizationCategories = selectorFamily<
  ApiSearchResult<Taxonomy>,
  { organizationId: number; portalCategoryType: PortalCategoryType }
>({
  key: 'PortalOrganizationCategories',
  get:
    ({
      organizationId,
      portalCategoryType,
    }: {
      organizationId: number;
      portalCategoryType: PortalCategoryType;
    }) =>
    async () => {
      const res = await mainApi.get<Taxonomy[]>(
        `/taxonomies?organizationId=${organizationId}`,
        { type: portalCategoryType }
      );
      // We sort using localeCompare with locale set to 'EN' because customers prefer having categories such as AAAA at the top.
      const data = res.data.sort((a, b) =>
        a.name.toLowerCase().localeCompare(b.name.toLowerCase(), 'en')
      );
      return { items: data, total: data.length };
    },
});

export default categoryService;
