import moment from 'moment';
import { CalendarApi } from '@fullcalendar/react';
import _ from 'lodash';

class CalendarViewService {
  private _api: CalendarApi;
  private _promisesToBeResolved: (() => void)[] = [];

  public set Api(api: CalendarApi) {
    this._api = api;
    if (this._promisesToBeResolved.length > 0 && api) {
      _.forEach(this._promisesToBeResolved, (resolve) => resolve());
      this._promisesToBeResolved = [];
    }
  }

  public getStartDate = async (): Promise<Date> => {
    try {
      if (this._api) {
        return Promise.resolve(this._api.view.activeStart);
      } else {
        return new Promise((resolve) => {
          this._promisesToBeResolved.push(() => {
            resolve(this._api.view.activeStart);
          });
        });
      }
    } catch (error) {
      console.warn(
        'Calendar api is not initialized, make sure that the calendar component is mounted'
      );
    }
  };

  public getEndDate = async (): Promise<Date> => {
    try {
      if (this._api) {
        return Promise.resolve(this._api.view.activeEnd);
      } else {
        return new Promise((resolve) => {
          this._promisesToBeResolved.push(() => {
            resolve(this._api.view.activeEnd);
          });
        });
      }
    } catch (error) {
      console.warn(
        'Calendar api is not initialized, make sure that the calendar component is mounted'
      );
    }
  };

  public get viewType(): string {
    try {
      return this._api.view.type;
    } catch (error) {
      console.warn(
        'Calendar api is not initialized, make sure that the calendar component is mounted'
      );
    }
  }

  public getViewType = async (): Promise<string> => {
    if (this._api) {
      return Promise.resolve(this._api.view.type);
    } else {
      return new Promise((resolve) => {
        this._promisesToBeResolved.push(() => {
          resolve(this._api.view.type);
        });
      });
    }
  };
  public get title(): string {
    try {
      return this._api ? this._api.view.title : '';
    } catch (error) {
      console.warn(
        'Calendar api is not initialized, make sure that the calendar component is mounted'
      );
    }
  }

  public next = (): Promise<void> => {
    try {
      if (this._api) {
        this._api.next();
        return Promise.resolve();
      } else {
        return new Promise((resolve) => {
          this._promisesToBeResolved.push(() => {
            this._api.next();
            resolve();
          });
        });
      }
    } catch (error) {
      console.warn(
        'Calendar api is not initialized, make sure that the calendar component is mounted'
      );
    }
  };

  public prev = (): Promise<void> => {
    try {
      if (this._api) {
        this._api.prev();
        return Promise.resolve();
      } else {
        return new Promise((resolve) => {
          this._promisesToBeResolved.push(() => {
            this._api.prev();
            resolve();
          });
        });
      }
    } catch (error) {
      console.warn(
        'Calendar api is not initialized, make sure that the calendar component is mounted'
      );
    }
  };

  public changeView = (viewType: string): Promise<void> => {
    try {
      if (this._api) {
        this._api.changeView(viewType);
        return Promise.resolve();
      } else {
        return new Promise((resolve) => {
          this._promisesToBeResolved.push(() => {
            this._api.changeView(viewType);
            resolve();
          });
        });
      }
    } catch (error) {
      console.warn(
        'Calendar api is not initialized, make sure that the calendar component is mounted'
      );
    }
  };

  public toDay = (): Promise<void> => {
    try {
      const today = moment().toDate();
      return this.gotoDate(today);
    } catch (error) {
      console.warn(
        'Calendar api is not initialized, make sure that the calendar component is mounted'
      );
    }
  };

  public gotoDate = (day: Date): Promise<void> => {
    try {
      if (day) {
        if (this._api) {
          this._api.gotoDate(day);
          return Promise.resolve();
        } else {
          return new Promise((resolve) => {
            this._promisesToBeResolved.push(() => {
              this._api.gotoDate(day);
              resolve();
            });
          });
        }
      }
    } catch (error) {
      console.warn(
        'Calendar api is not initialized, make sure that the calendar component is mounted'
      );
    }
  };

  public unselect = (): Promise<void> => {
    if (this._api) {
      window.postMessage({ action: 'cd-close-create-event-popover' });
      this._api.unselect();
      return Promise.resolve();
    } else {
      return new Promise((resolve) => {
        this._promisesToBeResolved.push(() => {
          this._api.unselect();
          resolve();
        });
      });
    }
  };

  public updateSize = (): Promise<void> => {
    try {
      if (this._api) {
        this._api.updateSize();
        return Promise.resolve();
      } else {
        return new Promise((resolve) => {
          this._promisesToBeResolved.push(() => {
            this._api.updateSize();
            resolve();
          });
        });
      }
    } catch (error) {
      console.warn(
        'Calendar api is not initialized, make sure that the calendar component is mounted'
      );
    }
  };
}

export default new CalendarViewService();
