import { useQueryClient, useSuspenseQuery } from '@tanstack/react-query';
import { every, extend, isNull, omitBy, union } from 'lodash';
import moment from 'moment';
import { useEffect } from 'react';

import { AccountLink } from '../types/settings.types';
import { StripeAccount } from '../types/payments-settings.types';
import { showAcceptLegalTermsModal } from '../../contributions/components/shared/AcceptLegalTermsModal';
import StateServiceFactory from '../../services/StateServiceFactory';

import cdApp from '@/react/config';
import { mainApi } from '@/react/api';

export const usePaymentsSettingsPage = () => {
  const stateService = StateServiceFactory();
  const queryClient = useQueryClient();
  const { data } = useSuspenseQuery({
    queryKey: ['accountDetailsQuery'],
    queryFn: async () =>
      await mainApi.get<StripeAccount>(
        `/organizations/${cdApp.organization.id}/stripe/account`
      ),
  });

  useEffect(() => {
    const accept = async () => {
      const { resolved } = await showAcceptLegalTermsModal();
      if (!resolved) stateService.go('app.private.settings.other');
      else {
        queryClient.invalidateQueries({
          queryKey: ['accountDetailsQuery'],
        });
      }
    };

    if (!data.data.tos_acceptance) {
      accept();
    }
  }, [data.data.tos_acceptance, queryClient, stateService]);

  const getSupportArticle = () => {
    const organizationLanguage = cdApp.organization.locale.language;
    let helpLink;
    switch (organizationLanguage) {
      case 'de':
        helpLink =
          'https://support.churchdesk.com/de/article/richten-sie-churchdesk-spenden-ein-5o29rz/';
        break;
      case 'da':
        helpLink =
          'https://support.churchdesk.com/da/article/opsaetning-af-churchdesk-betalinger-1rc3jeo/';
        break;
      default:
        helpLink =
          'https://support.churchdesk.com/en/article/set-up-churchdesk-payments-b4czox/';
        break;
    }

    // Redirect to connect onboarding form.
    if (helpLink) {
      window.open(helpLink, '_blank');
    }
  };

  const getAccountLink = async () => {
    const response = await mainApi.get('/p/organizations/verification/links');
    const data = response.data as AccountLink;
    window.open(data.url, '_blank');
  };

  const paymentsData = data?.data;
  const dataRequirements = data?.data?.requirements;
  const requirements = union(
    dataRequirements?.currently_due,
    dataRequirements?.eventually_due,
    dataRequirements?.past_due
  );
  const usesAccountNumber = ['us', 'ca', 'au', 'jp', 'sg'].includes(
    cdApp.organization.countryIso2.toLowerCase()
  );
  const bankAccountIsRequired = requirements.includes('external_account');
  const birthDateNotEditable = !every(paymentsData.person?.dob, isNull);
  const notStripeVerified =
    !paymentsData.isVerified &&
    extend(
      {},
      omitBy(
        {
          city: paymentsData.company?.address?.city,
          line1: paymentsData.company?.address?.line1,
          line2: paymentsData.company?.address?.line2,
          postCode: paymentsData.company?.address?.postal_code,
          state: paymentsData.company?.address?.state,
          firstName: paymentsData.person?.first_name,
          lastName: paymentsData.person?.last_name,
          personAddressCity: paymentsData.person?.address?.city,
          personAddressLine1: paymentsData.person?.address?.line1,
          personAddressLine2: paymentsData.person?.address?.line2,
          personAddressPostCode: paymentsData.person?.address?.postal_code,
          personAddressState: paymentsData.person?.address?.state,
          birthDate: birthDateNotEditable
            ? moment([
                paymentsData.person.dob.year,
                paymentsData.person.dob.month - 1,
                paymentsData.person.dob.day,
              ]).format('L')
            : null,
        },
        isNull
      )
    );

  return {
    getAccountLink,
    paymentsData,
    notStripeVerified,
    getSupportArticle,
    bankAccountIsRequired,
    usesAccountNumber,
  };
};
