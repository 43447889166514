import { Dropdown, Button, Space } from 'antd';
import React from 'react';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import { SizeType } from 'antd/es/config-provider/SizeContext';

import { CdAngleDown } from '../Icons';

export interface CdDropdownProps {
  items: ItemType[];
  size?: SizeType;
  children?: React.ReactNode;
}

export const CdDropdown = (props: CdDropdownProps) => (
  <Dropdown menu={{ items: props.items }}>
    <Button size={props.size}>
      <Space>
        {props.children}
        <CdAngleDown />
      </Space>
    </Button>
  </Dropdown>
);
