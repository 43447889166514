import React, { CSSProperties } from 'react';
import { Space, Typography } from 'antd';

import { AvailabilityBookingOption } from '../../../taxonomies/services/Taxonomies';
import { CdVerticalSpace } from '../../../shared/components/cd-vertical-space/CdVerticalSpace';
import { sortString } from '../../../shared/libs/sorter';
import gettextCatalog from '../../../services/I18nService';
import { DateInputValue } from '../../event-details/components/date-input/DateInput';
import { useBookingOptions } from '../hooks/use-booking-options';

export interface BookingOptionsSummaryProps {
  bookingOptions: AvailabilityBookingOption[];
  date?: DateInputValue;
  style?: CSSProperties;
}

export const BookingOptionsSummary = (props: BookingOptionsSummaryProps) => {
  const { affectedWeekdays, weekdayMap } = useBookingOptions(props.date);

  return (
    props.bookingOptions?.length > 0 && (
      <CdVerticalSpace style={props.style}>
        <Typography.Text strong>
          {gettextCatalog.getString('Open hours for selected options')}
        </Typography.Text>
        {props.bookingOptions
          .sort((a, b) => sortString(a.name, b.name))
          .map((option) => (
            <CdVerticalSpace size={0}>
              <Typography.Text>{option.name}</Typography.Text>
              {affectedWeekdays.map((day) => (
                <Typography.Text type="secondary">
                  <Space>
                    {weekdayMap[day].name}-
                    {option.config.bookingHours[day]
                      ? option.config.bookingHours[day]
                          .map((hours) => `${hours.start} - ${hours.end}`)
                          .join(', ')
                      : gettextCatalog.getString('Not open')}
                  </Space>
                </Typography.Text>
              ))}
            </CdVerticalSpace>
          ))}
      </CdVerticalSpace>
    )
  );
};
