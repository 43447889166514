import { Resource } from '../../shared/models/resource';

export const findResource = (resources: Resource[], resourceId: number) => {
  // Find in first level
  let resource = resources?.find((resource) => resource.id === resourceId);

  // If not found, look in children
  if (!resource) {
    resources?.some((item) => {
      const resourceFound = item.resources?.find(
        (resource) => resource.id === resourceId
      );
      if (resourceFound) {
        resource = resourceFound;
      }
      return false;
    });
  }

  return resource;
};

// Useful if ID's come as "resource-12353"
export const extractResourceIds = (ids: string[]) =>
  ids?.map((id) => Number(id.replace(/\D/g, ''))) || [];
