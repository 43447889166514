import {
  useRecoilCallback,
  useRecoilRefresher_UNSTABLE as useRecoilRefresher,
  useRecoilValue,
} from 'recoil';
import { isEqual } from 'lodash';
import { Form } from 'antd';
import { useCallback } from 'react';

import { SafeGuardingDetailsQuery } from '../store/Safeguarding';
import { mainApi } from '../../api';
import NotificationService from '../../services/NotificationService';
import { gettextCatalog } from '../../services/I18nService';
import {
  handleAntFormValidationErrors,
  handleError,
} from '../../services/ErrorHandlingService';
import { HasMultipleChurches } from '../../user/store/user-session';
import { SafeGuardingInfoPayload } from '../types/SafeguardingTypes';
import cdApp from '../../config';

import { useSafeguarding } from './useSafeguarding';

export const useSafeguardingForm = (currentUserId: number) => {
  const safeguardingData = useRecoilValue(
    SafeGuardingDetailsQuery(currentUserId)
  );
  const refreshSafeguarding = useRecoilRefresher(
    SafeGuardingDetailsQuery(currentUserId)
  );

  const isMultiChurch = useRecoilValue(HasMultipleChurches);

  const { refreshSafeguardingList } = useSafeguarding();
  const [safeguardingForm] = Form.useForm();

  const onSafeguardingInfoSave = useRecoilCallback(
    () => async (safeguardingInfoFormData: SafeGuardingInfoPayload) => {
      const response = await mainApi.put(
        `/safeguard/users/${currentUserId}/info`,
        {
          ...safeguardingInfoFormData,
          parishIds: isMultiChurch
            ? safeguardingData.safeguardInfo.parishIds
            : [cdApp.organization.churches[0].id],
        }
      );
      if (!response.ok) {
        handleError(response);
        throw response.data;
      }
      refreshSafeguarding();
      refreshSafeguardingList();
      NotificationService.notifySuccess(
        gettextCatalog.getString(
          'Safeguarding information has been saved successfully'
        )
      );
    },
    [
      currentUserId,
      isMultiChurch,
      refreshSafeguarding,
      refreshSafeguardingList,
      safeguardingData.safeguardInfo.parishIds,
    ]
  );
  const onSafeguardingInfoSaveHandler = useCallback(async () => {
    await safeguardingForm
      .validateFields()
      .then((value) => {
        const address = isEqual(value?.address, safeguardingData?.address)
          ? {}
          : value?.address;
        onSafeguardingInfoSave({ ...value?.safeguardInfo, ...address });
      })
      .catch(handleAntFormValidationErrors);
  }, [onSafeguardingInfoSave, safeguardingForm, safeguardingData.address]);

  const deleteFile = useRecoilCallback(
    () => async (id: number) => {
      const response = await mainApi.delete(`/safeguard/${id}`);
      if (response.ok) {
        refreshSafeguarding();
        refreshSafeguardingList();
        NotificationService.notifySuccess(
          gettextCatalog.getString('The record has been deleted')
        );
        return;
      }
    },
    [refreshSafeguarding, refreshSafeguardingList]
  );

  return {
    safeguardingData,
    safeguardingForm,
    onSafeguardingInfoSaveHandler,
    deleteFile,
    refreshSafeguarding,
    isMultiChurch,
  };
};
