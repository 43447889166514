import { useQuery } from '@tanstack/react-query';

import { rruleApi } from '../../../api';
import cdApp from '../../../config';

interface RRuleGenerateProps {
  startDate: string;
  endDate?: string;
  rrule: string;
}

interface RRuleGenerateResult {
  startDate: string;
  endDate: string;
}

export const useRRule = (props?: RRuleGenerateProps) => {
  const query = useQuery({
    queryKey: ['useRRule', props],
    queryFn: async () => {
      if (props && props.rrule !== 'custom') {
        const response = await rruleApi.post<RRuleGenerateResult[]>(
          '/rrule/generate',
          {
            ...props,
            timezone: cdApp.organization.locale.timezone,
            language: 'en',
          }
        );
        if (response.ok) {
          return response.data;
        }
        throw response;
      }
      return [];
    },
  });

  return {
    dates: query.data,
    isLoading: query.isLoading,
  };
};
