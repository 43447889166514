import { Reducer } from 'redux';

import { CdAction } from '../../redux/utils';

import {
  RESET_USER_ENTITY,
  FETCH_USERS,
  FETCH_USERS_SUCCESS,
  FETCH_USER_PRIVILEGE_SUCCESS,
  RESET_USER_PRIVILEGES,
  FETCH_USER_ROLES,
  FETCH_USER_ROLES_SUCCESS,
} from './actions';

import { ImmutableUserState, initialState } from './index';

const fetchUsers: Reducer<ImmutableUserState> = (state = initialState) =>
  state.merge({
    communication: { ...state.communication, isUsersLoading: true },
  });
const fetchUsersSuccess: Reducer<ImmutableUserState, CdAction<any>> = (
  state = initialState,
  { payload }
) =>
  state.merge({
    users: payload,
    communication: { ...state.communication, isUsersLoading: false },
  });

const fetchUserRoles: Reducer<ImmutableUserState> = (state = initialState) =>
  state.merge({
    communication: {
      ...state.communication,
      userOldRolesLoading: true,
      userChurchRolesLoading: true,
      userOrganizationRolesLoading: true,
    },
  });
const fetchUserRolesSuccess: Reducer<ImmutableUserState, CdAction<any>> = (
  state = initialState,
  { payload }
) =>
  state.merge({
    churchRoles: payload.churchRoles,
    oldRoles: payload.oldRoles,
    organizationRoles: payload.organizationRoles,
    communication: {
      ...state.communication,
      userOldRolesLoading: false,
      userChurchRolesLoading: false,
      userOrganizationRolesLoading: false,
    },
  });

const FetchUserPrivilegeSuccess: Reducer<
  ImmutableUserState,
  CdAction<{ permission: string; access: boolean }>
> = (state = initialState, { payload }) =>
  state.merge({
    permissions: state.permissions.merge({
      [payload.permission]: payload.access,
    }),
  });

const resetUserPrivileges: Reducer<ImmutableUserState, CdAction<undefined>> = (
  state = initialState
) =>
  state.merge({
    permissions: {},
    churches: {},
  });

const resetUserEntity: Reducer<ImmutableUserState, CdAction<any>> = (
  state = initialState
) =>
  state.merge({
    oldRoles: {},
    churchRoles: [],
    organizationRoles: [],
    communication: { ...state.communication },
  });

// Handlers
export const handlers = {
  [FETCH_USER_ROLES]: fetchUserRoles,
  [FETCH_USER_ROLES_SUCCESS]: fetchUserRolesSuccess,
  [RESET_USER_ENTITY]: resetUserEntity,
  [FETCH_USERS]: fetchUsers,
  [FETCH_USERS_SUCCESS]: fetchUsersSuccess,
  [FETCH_USER_PRIVILEGE_SUCCESS]: FetchUserPrivilegeSuccess,
  [RESET_USER_PRIVILEGES]: resetUserPrivileges,
};
