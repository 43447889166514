const calendarModule = angular.module('cdApp.calendar', []);

require('./absence-detail/absence-detail.component');
require('./attendance');
require('./calendar-print/calendar-print.component');
require('./calendar-print/cdr-order-of-service-button.component');
require('./calendar-print/order-of-service-template-modal/order-of-service-template.modal');
require('./event-detail');
require('./event-popover/popover.component');
require('./feed-detail');
require('./shared/components/cdr-create-stole');
require('./shared/components/cdr-event-stole');
require('./shared/components/cdr-collection');
require('./shared/components/cdr-posters');
require('./external-event-detail');
require('./full-calendar');
require('./personal-ical-feed/personal-ical-feed.component');
require('./resource-location-modal/resource-location-modal.component');
require('./rotas-scheme/rotas-scheme.component');
require('./shared');
require('./workplan-detail/workplan-detail.component');
require('./enquiries/enquiries.component');
require('./workplan-overview/workplan-overview.component');
require('./rotas-scheme/rotas-scheme.component');
require('./calendar/cdr-calendar');
require('./calendar/CalendarSwitchScreen');
require('./calendar/calendarReports');
require('./templates');
require('@/app/shared/components/repeat/repeat-rule-modal.component');

module.exports = calendarModule.name;
