import React, { ReactNode } from 'react';
import { Space, Typography } from 'antd';
import { BaseType } from 'antd/es/typography/Base';

import { EnquiryStatus } from '../types/enquiry';
import {
  CdBan,
  CdCheckIcon,
  CdExclamationTriangleIcon,
} from '../../../shared/components/Icons';
import gettextCatalog from '../../../services/I18nService';

export const EnquiryStatusIcon = (props: { status: EnquiryStatus }) => {
  const values = getFields(props.status);

  return (
    <Typography.Text type={values.type}>
      <Space>
        {values.icon}
        {values.text}
      </Space>
    </Typography.Text>
  );
};

const getFields = (
  status: EnquiryStatus
): { type: BaseType; icon: ReactNode; text: string } => {
  switch (status) {
    case 'accepted':
      return {
        type: 'success',
        icon: <CdCheckIcon />,
        text: gettextCatalog.getString('Accepted'),
      };
    case 'rejected':
      return {
        type: 'danger',
        icon: <CdBan />,
        text: gettextCatalog.getString('Rejected'),
      };
    case 'requested':
    default:
      return {
        type: 'warning',
        icon: <CdExclamationTriangleIcon />,
        text: gettextCatalog.getString('Requested'),
      };
  }
};
