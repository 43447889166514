import { Col, Select, Space, Typography } from 'antd';
import React, { Suspense } from 'react';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import { gettextCatalog } from '../../../services/I18nService';
import {
  CdSelect,
  CdSelectProps,
} from '../../../shared/components/cd-select/CdSelect';
import { PortalCategoryType } from '../../../portal/state/portal';
import { sortString } from '../../../shared/libs/sorter';

import { COLOR_INDEX } from '@/app/ResourceColors';
import { CdCircleFilled } from '@/react/shared/components/Icons';
import { CategoryType } from '@/react/shared/models/category';
import { CategoryByTypeQuery } from '@/react/shared/services/CategoryService';

const { Option } = Select;

interface CdEventCategorySelectProps
  extends Pick<
    CdSelectProps,
    | 'value'
    | 'onChange'
    | 'mode'
    | 'disabled'
    | 'placeholder'
    | 'style'
    | 'maxTagCount'
    | 'autoFocus'
  > {
  type?: CategoryType | PortalCategoryType;
  showSecondaryText?: boolean;
}

export const CdEventCategorySelect = (props: CdEventCategorySelectProps) => (
  <Suspense fallback={<CdSelect {...props} disabled={true} />}>
    <CdEventCategorySelectInner {...props} />
  </Suspense>
);

export const CdEventCategorySelectInner = ({
  type,
  showSecondaryText = true,
  ...rest
}: CdEventCategorySelectProps) => {
  const categories = useRecoilValue(
    CategoryByTypeQuery(type || CategoryType.event)
  );
  const selectCategoriesData = useMemo(
    () =>
      (categories || [])
        .slice()
        .sort((a, b) => sortString(a.name, b.name))
        .map(({ id, name, color, registerAttendance }) => ({
          value: id,
          label: name,
          color,
          registerAttendance,
        }))
        .map((item) => (
          <Option value={item.value} label={item.label} key={item.value}>
            <Space>
              {type !== CategoryType.availability && (
                <CdCircleFilled
                  style={{
                    color: COLOR_INDEX[item.color],
                  }}
                />
              )}
              <Typography.Text>{item.label}</Typography.Text>
              {showSecondaryText && (
                <Col flex="auto" style={{ textAlign: 'right' }}>
                  <Typography.Text italic type="secondary">
                    {item.registerAttendance
                      ? gettextCatalog.getString('Tracking attendance')
                      : undefined}
                  </Typography.Text>
                </Col>
              )}
            </Space>
          </Option>
        )),

    [categories, showSecondaryText, type]
  );

  return (
    <CdSelect {...rest} allowClear={rest.mode === 'multiple'}>
      {selectCategoriesData}
    </CdSelect>
  );
};
