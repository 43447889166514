import { react2angular } from 'react18-react2angular';

import sharedContext from '@/app/shared/components/shared-context-root/shared-context-root.component';
import { BookingPagesPage } from '@/react/settings/booking-pages/BookingPages';

angular
  .module('cdApp.calendar')
  .component(
    'cdrBookingPagesComponent',
    react2angular(sharedContext.use(BookingPagesPage), [], [])
  );
