import React from 'react';
import { Button, Form } from 'antd';

import { gettextCatalog } from '../../services/I18nService';
import { useAddMemberModal } from '../hooks/useAddMemberModal';

import { CdUserSelect } from './CdUserSelect';

import { createCdModal } from '@/react/shared/components/cd-modal-new/CdModal2';
import { CdUserPlusIcon } from '@/react/shared/components/Icons';
import { required } from '@/react/shared/antd/validations/common';

export interface ModalGroup {
  name: string;
  id: string;
  members: number[];
}

interface AddMemberModalProps {
  group: ModalGroup;
}

export const showAddMemberModal = createCdModal<AddMemberModalProps>(
  ({ group, setModalProps }) => {
    const { form, createNewUser, hasUserAdminRole } = useAddMemberModal(
      setModalProps,
      group
    );

    return (
      <Form form={form} layout="vertical">
        <Form.Item
          name="userId"
          label={gettextCatalog.getString('Select a user')}
          rules={[required()]}
        >
          <CdUserSelect
            omitIds={group.members}
            size="large"
            addonAfter={
              hasUserAdminRole && (
                <Button
                  icon={<CdUserPlusIcon />}
                  size="large"
                  onClick={createNewUser}
                  style={{ fontSize: 14 }}
                >
                  {gettextCatalog.getString('Create new')}
                </Button>
              )
            }
          />
        </Form.Item>
      </Form>
    );
  }
);
