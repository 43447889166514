import {
  clientToBackendVisibility,
  getInitialEventVisibility,
} from '@/react/calendar/helpers/visibility';
import {
  BackendEventVisibility,
  ClientEventVisibility,
} from '@/react/calendar/types/event';
import cdApp from '@/react/config';
import {
  AvailabilityTaxonomyFormData,
  FormBookingHours,
  FormDuration,
  TaxonomyBookingOption,
} from '@/react/settings/booking-pages/types/availability-taxonomy.types';
import {
  BookingHours,
  BookingOption,
  ITaxonomyTypes,
  Taxonomy,
  Weekdays,
} from '@/react/shared/models/taxonomy';

export const EMPTY_FORM_DATA = (): AvailabilityTaxonomyFormData => ({
  name: '',
  alias: '',
  availabilityType: 'specific-availability',
  groups: [],
  churchIds: [],
  isBookable: false,
  bookingName: '',
  bookingDescription: '',
  bookingOptions: [],
  visibilityGroups: [],
  visibility: getInitialEventVisibility(),
});
export const createNewBookingOption = (): TaxonomyBookingOption => ({
  name: '',
  description: '',
  additionalResourceIds: [],
  allowWeeklySchedule: false,
  bookingHours: Object.fromEntries(
    Object.values(Weekdays).map((day) => [
      day,
      {
        enabled: false,
        data: [{ start: '08:00', end: '16:00' }],
      },
    ])
  ) as FormBookingHours,
  duration: {
    hours: 2,
    minutes: 30,
  },
  minimumNoticePeriod: {
    hours: 72,
    minutes: 0,
  },
  bufferAfterEvent: {
    hours: 0,
    minutes: 30,
  },
  bufferBeforeEvent: {
    hours: 1,
    minutes: 30,
  },
  startTimeIncrement: 30,
});
const trimResourcePrefix = (resource?: string): number => {
  if (!resource) {
    return undefined;
  }
  return Number(resource.replace('resource-', ''));
};
const addResourcePrefix = (resource?: number) => {
  if (resource === undefined || resource === null) {
    return undefined;
  }
  return `resource-${resource}`;
};

const isWeeklyScheduleDefined = (bookingHours?: BookingHours): boolean => {
  if (!bookingHours) {
    return false;
  }
  return (
    Object.values(bookingHours).length !== 7 ||
    Object.values(bookingHours).some((item) => item.length !== 0)
  );
};

const minutesToDuration = (minutes: number): FormDuration => ({
  hours: Math.floor(minutes / 60),
  minutes: minutes % 60,
});

const backendVisibilityToClient = (
  visibility: BackendEventVisibility,
  groups?: number[]
): ClientEventVisibility => {
  if (visibility === BackendEventVisibility.PUBLIC) {
    return ClientEventVisibility.PUBLIC;
  } else if (visibility === BackendEventVisibility.PRIVATE) {
    return ClientEventVisibility.PRIVATE;
  } else {
    // backend visibility is 'internal'
    if ((groups?.length ?? 0) > 0) {
      return ClientEventVisibility.INTERNAL_GROUPS;
    }
    return ClientEventVisibility.INTERNAL_ALL;
  }
};

export const existingTaxonomyToFormData = (
  taxonomy: Taxonomy
): AvailabilityTaxonomyFormData => ({
  name: taxonomy.name,
  alias: taxonomy.alias ?? '',
  availabilityType: taxonomy.config?.type,
  groups: taxonomy.groups?.map((group) => Number(group.id)) ?? [],
  isBookable: true,
  bookingName: taxonomy.config?.publicName,
  bookingDescription: taxonomy.description ?? '',
  categoryId: (taxonomy.config?.event?.categoryIds ?? [])?.[0],
  churchIds: taxonomy.config?.event?.churchIds ?? [],
  visibility: backendVisibilityToClient(
    taxonomy.config?.event?.visibility,
    taxonomy.config?.event?.groupIds
  ),
  visibilityGroups: taxonomy.config?.event?.groupIds ?? [],
  bookingOptions:
    taxonomy.bookingOptions?.map((option) => ({
      id: option?.id,
      name: option.name ?? '',
      description: option.description ?? '',
      allowWeeklySchedule: isWeeklyScheduleDefined(option.config?.bookingHours),
      additionalResourceIds:
        option.additionalResourceIds?.map(addResourcePrefix) ?? [],
      resourceId: addResourcePrefix(option.mainResourceId),
      bookingHours: Object.fromEntries(
        Object.values(Weekdays).map((day) => {
          const existing = option.config?.bookingHours[day];
          if (existing && existing.length > 0) {
            return [
              day,
              {
                enabled: true,
                data: existing.map((e) => ({
                  start: e.start,
                  end: e.end,
                })),
              },
            ];
          } else {
            return [
              day,
              { enabled: false, data: [{ start: '08:00', end: '16:00' }] },
            ];
          }
        })
      ) as FormBookingHours,
      duration: minutesToDuration(option.config?.duration),
      minimumNoticePeriod: minutesToDuration(
        option.config?.minimumNoticePeriod
      ),
      startTimeIncrement: option.config?.startTimeIncrement,
      bufferBeforeEvent: minutesToDuration(option.config?.bufferBeforeEvent),
      bufferAfterEvent: minutesToDuration(option.config?.bufferAfterEvent),
    })) ?? [],
});
const convertDurationToMinutes = (
  duration?: FormDuration
): number | undefined => {
  if (!duration) {
    return undefined;
  }
  return duration.hours * 60 + duration.minutes;
};
const convertFormDataBookingOptionToApi = (
  formData: TaxonomyBookingOption,
  existing?: BookingOption
): BookingOption => {
  const bookingHours = Object.fromEntries(
    Object.entries(formData.bookingHours).map(([day, data]) => [
      day,
      data.enabled
        ? data.data.map((item) => ({
            start: item.start,
            end: item.end,
          }))
        : [],
    ])
  );
  return {
    ...(existing ?? {}),
    name: formData.name,
    description: formData.description,
    mainResourceId: trimResourcePrefix(formData.resourceId),
    additionalResourceIds:
      formData.additionalResourceIds.map(trimResourcePrefix),
    config: {
      bookingHours,
      duration: convertDurationToMinutes(formData.duration),
      minimumNoticePeriod: convertDurationToMinutes(
        formData.minimumNoticePeriod
      ),
      startTimeIncrement: formData.startTimeIncrement,
      bufferBeforeEvent: convertDurationToMinutes(formData.bufferBeforeEvent),
      bufferAfterEvent: convertDurationToMinutes(formData.bufferAfterEvent),
    },
  };
};
export const convertFormDataToTaxonomy = (
  tax: Taxonomy | undefined,
  formData: AvailabilityTaxonomyFormData
): Partial<Taxonomy> => ({
  ...(tax ?? {}),
  type: ITaxonomyTypes.availability,
  name: formData.name,
  alias: formData.alias,
  groups: formData.groups.map((id) => ({ id: Number(id) })),
  description: formData.bookingDescription,
  config: {
    ...(tax?.config ?? {}),
    type: formData.availabilityType,
    isBookable: true,
    publicName: formData.bookingName ?? formData.name,
    event: {
      churchIds:
        formData.churchIds.length === 0
          ? [cdApp.organization.churches[0].id]
          : formData.churchIds,
      title: formData.bookingName,
      categoryIds: formData.categoryId ? [formData.categoryId] : [],
      groupIds:
        formData.visibility === 'internal-group'
          ? formData.visibilityGroups
          : [],
      visibility: clientToBackendVisibility(formData.visibility),
    },
  },
  bookingOptions: formData.bookingOptions.map((option) =>
    convertFormDataBookingOptionToApi(
      option,
      tax?.bookingOptions?.find((o) => o.id === option.id)
    )
  ),
});
