import { RuleObject } from 'antd/es/form';

export const validationRule = <T>(
  validationFunction: (value: T) => void | Promise<void>
): RuleObject => ({
  validator: async (_, value: T) => {
    try {
      if (value) {
        await validationFunction(value);
      }
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  },
});
