import React from 'react';
import { Button, Space, Typography } from 'antd';
import moment from 'moment';

import { OrderType } from '../../shared/models/table';
import { gettextCatalog } from '../../services/I18nService';
import {
  CdCircleCheck,
  CdExclamationTriangleIcon,
} from '../../shared/components/Icons';
import { ColumnType } from '../../shared/components/cd-table';
import { CdVerticalSpace } from '../../shared/components/cd-vertical-space/CdVerticalSpace';
import { coursesInterface } from '../types/SafeguardingTypes';

import { showSafeguardingDrawer } from './SafeguardingDrawer';

import { CdTooltip } from '@/react/shared/components/cd-tooltip/CdTooltip';
import { getSafeguardingLevelText } from '@/react/safeguarding/common/Helpers';

export const safeguardingTableColumns = ({ canEdit }: { canEdit: boolean }) => {
  const safeguardingTableColumn: ColumnType<any>[] = [
    {
      key: 'fullName',
      title: gettextCatalog.getString('Name'),
      defaultSortOrder: 'ascend',
      orderType: OrderType.STRING,
      render: (data) => (
        <Space direction="vertical" size={1}>
          {data.fullName}
          {data.email}
        </Space>
      ),
    },
    {
      key: 'externalVerification',
      title: gettextCatalog.getString('External verification'),
      defaultSortOrder: 'ascend',
      orderType: OrderType.STRING,
      render: (data) =>
        data.validation?.highestValidatedLevelInChurchDesk && (
          <Space direction="horizontal">
            {getSafeguardingLevelText(
              data.validation?.highestValidatedLevelInChurchDesk
            )}
            <CdCircleCheck type="success" />
          </Space>
        ),
    },
    {
      key: 'safeguardRequirement',
      title: gettextCatalog.getString('Requirement'),
      defaultSortOrder: 'ascend',
      orderType: OrderType.STRING,
      dataIndex: 'safeguardRequirement',
      render: (data) => getSafeguardingLevelText(data),
    },
    {
      key: 'course',
      title: gettextCatalog.getString('Course'),
      defaultSortOrder: 'ascend',
      orderType: OrderType.STRING,
      render: (data) => (
        <CdVerticalSpace size={2}>
          {data?.courses.length > 0 &&
            data.courses.map((course: coursesInterface) => (
              <CdVerticalSpace size={0}>
                {course.refreshRequired ? (
                  moment().isBefore(course.refreshDate) ? (
                    <CdVerticalSpace size={0}>
                      {course.courseTitle}
                      <Space
                        size={2}
                        style={{ fontSize: '12px' }}
                        direction="horizontal"
                      >
                        {gettextCatalog.getString('Expires on')}
                        {moment(course?.refreshDate).format('L')}
                      </Space>
                    </CdVerticalSpace>
                  ) : undefined
                ) : (
                  <CdVerticalSpace size={0}>
                    {course.courseTitle}
                    <Typography.Text style={{ fontSize: '12px' }}>
                      {gettextCatalog.getString(
                        'No expiration date',
                        null,
                        'Safeguarding table view'
                      )}
                    </Typography.Text>
                  </CdVerticalSpace>
                )}
              </CdVerticalSpace>
            ))}
          {data.validation?.courses?.basic?.missing && (
            <Space
              style={{
                color: 'red',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              {gettextCatalog.getString('Basic course missing')}
              <CdExclamationTriangleIcon />
            </Space>
          )}
          {data.validation?.courses?.extended?.missing && (
            <Space
              style={{
                color: 'red',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              {gettextCatalog.getString('Extended course missing')}
              <CdExclamationTriangleIcon />
            </Space>
          )}
        </CdVerticalSpace>
      ),
    },
    {
      key: 'documents',
      title: gettextCatalog.getString('Documents'),
      active: true,
      render: (data) => (
        <CdVerticalSpace size={1}>
          {data.validation?.documents?.codeOfConduct?.missing && (
            <Space
              style={{
                color: 'red',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              {gettextCatalog.getString(
                'Code of conduct',
                null,
                'Short text for safeguarding table'
              )}
              <CdExclamationTriangleIcon />
            </Space>
          )}
          {data.validation?.documents?.selfDisclosure?.missing && (
            <Space
              style={{
                color: 'red',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              {gettextCatalog.getString(
                'Self-disclosure statement',
                null,
                'Short text for safeguarding table'
              )}
              <CdExclamationTriangleIcon />
            </Space>
          )}
        </CdVerticalSpace>
      ),
    },
    {
      key: 'certificateOfConduct',
      title: gettextCatalog.getString('Certificate of conduct'),
      active: true,
      render: (data) => (
        <Space direction="vertical" size={1} style={{ width: '100%' }}>
          {data?.validation?.certificateOfConduct?.missing && (
            <Space
              size={1}
              direction="horizontal"
              style={{
                color: 'red',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              {gettextCatalog.getString('Missing!')}
              <CdExclamationTriangleIcon />
            </Space>
          )}
          {data?.certificateOfConduct && (
            <Space direction="vertical" size={1}>
              {gettextCatalog.getString('Reviewed')}
              {data?.certificateOfConduct?.refreshRequired ? (
                <Space
                  size={2}
                  style={{ fontSize: '12px' }}
                  direction="horizontal"
                >
                  {gettextCatalog.getString('Expires on')}
                  {moment(data?.certificateOfConduct?.refreshDate).format('L')}
                </Space>
              ) : null}
            </Space>
          )}
        </Space>
      ),
    },
    {
      key: 'safeguardingEdit',
      title: '',
      active: true,
      align: 'center',
      render: (data) => (
        <Space direction="horizontal">
          <CdTooltip
            title={
              canEdit
                ? undefined
                : gettextCatalog.getString('You do not have edit permission.')
            }
          >
            <Button
              type="primary"
              disabled={!canEdit}
              onClick={() =>
                showSafeguardingDrawer({
                  isEdit: canEdit,
                  currentUserId: data.userId,
                  currentUserOrganizationStatus: data.organizationStatus,
                })
              }
            >
              {gettextCatalog.getString('Edit')}
            </Button>
          </CdTooltip>
          {!canEdit && (
            <Button
              type="primary"
              onClick={() =>
                showSafeguardingDrawer({
                  isEdit: canEdit,
                  currentUserId: data.userId,
                  currentUserOrganizationStatus: data.organizationStatus,
                })
              }
            >
              {gettextCatalog.getString('View')}
            </Button>
          )}
        </Space>
      ),
    },
  ];
  return safeguardingTableColumn;
};
