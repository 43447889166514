import React from 'react';
import { Input, InputProps } from 'antd';

export const NationalIdentificationNumberInput = ({
  value,
  onChange,
  ...props
}: InputProps) => {
  const handleChange = (e) => {
    const newValue = e.target.value.replace(/[^0-9-]/g, '');
    if (onChange) {
      onChange({
        ...e,
        target: {
          ...e.target,
          value: newValue,
        },
      });
    }
  };

  return <Input {...props} value={value} onChange={handleChange} />;
};
