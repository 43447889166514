import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppState } from '../../../redux';
import { createActionDispatcherHook } from '../../../redux/utils';

export interface SettingsState {
  newCalendarView: boolean;
  calendarSwitchAllowed: boolean;
  calendarChanged: boolean;
  showSwitcher: boolean;
  bookingSystemEnabled: boolean;
}

const initialState: SettingsState = {
  newCalendarView: true,
  calendarSwitchAllowed: true,
  calendarChanged: false,
  showSwitcher: true,
  bookingSystemEnabled: false,
};
export interface CalendarSettingPayload {
  defaultCalendar: boolean;
  calendarSwitchAllowed: boolean;
  bookingSystemEnabled: boolean;
}
const calendarSettingsSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    toggleCalendarView(state: SettingsState) {
      state.calendarChanged = true;
      state.newCalendarView = !state.newCalendarView;
    },
    setCalendarSettings(
      state: SettingsState,
      { payload }: PayloadAction<CalendarSettingPayload>
    ) {
      state.newCalendarView = payload.defaultCalendar;
      state.calendarSwitchAllowed = payload.calendarSwitchAllowed;
      state.bookingSystemEnabled = payload.bookingSystemEnabled;
    },
    hideCalendarSwitch(state: SettingsState) {
      state.showSwitcher = false;
    },
    setCalendarChanged(state: SettingsState) {
      state.calendarChanged = true;
    },
  },
});

export const calendarSettingsReducer = calendarSettingsSlice.reducer;

export const calendarSettingsActions = calendarSettingsSlice.actions;

const rootCalendarSettingsSelector = (state: AppState) =>
  state.calendarSettings;

export const calendarSettingsSelectors = {
  isNewCalendar: createSelector(
    rootCalendarSettingsSelector,
    (settings) => settings.newCalendarView
  ),
  calendarViewChanged: createSelector(
    rootCalendarSettingsSelector,
    (settings) => settings.calendarChanged
  ),
  getShowCalendarSwitch: createSelector(
    rootCalendarSettingsSelector,
    (settings) => settings.showSwitcher
  ),
  getIsCalendarSwitchAllowed: createSelector(
    rootCalendarSettingsSelector,
    (settings) => settings.calendarSwitchAllowed
  ),
  getIsBookingSystemEnabled: createSelector(
    rootCalendarSettingsSelector,
    (settings) => settings.bookingSystemEnabled
  ),
};

export const calendarSettingsHooks = {
  useToggleCalendarView: createActionDispatcherHook(
    calendarSettingsActions.toggleCalendarView
  ),
  useHideCalendarSwitch: createActionDispatcherHook(
    calendarSettingsActions.hideCalendarSwitch
  ),
};
