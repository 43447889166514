import { Button, Dropdown, MenuProps, Space, Spin, Typography } from 'antd';
import React, { Suspense } from 'react';
import { useRecoilValue } from 'recoil';

import { useEventPosterAndInvitations } from '../../hooks/use-event-invitation-and-poster';

import {
  CdAngleDown,
  CdDeleteIcon,
  CdEditIcon,
  CdPoster,
  CdTime,
} from '@/react/shared/components/Icons';
import { gettextCatalog } from '@/react/services/I18nService';
import { showConfirmModal } from '@/react/shared/components/cd-confirm-modal/CdConfirmModal';
import AuthorizationService from '@/react/services/AuthorizationService';
import { ColumnType } from '@/react/shared/components/cd-table/types';
import CdSimpleTable from '@/react/shared/components/cd-simple-table/CdSimpleTable';
import { CalendarInvitation } from '@/react/calendar/types/event';
import { CdUserAvatar } from '@/react/user/components/cd-user-avatar/CdUserAvatar';
import { CalendarPosterLandingPage } from '@/react/calendar/containers/event/calendar-posters/CalendarPosterLandingPage';
import { UserHasPrivilege } from '@/react/user/store/permissions';

const { Link } = Typography;
export const EventInvitationList = ({
  eventId,
  handleCreateInvitation,
  handleEditInvitation,
}: {
  eventId: number;
  handleCreateInvitation?: any;
  handleEditInvitation?: any;
}) => {
  const { deleteInvitation, EventInvitationsSource } =
    useEventPosterAndInvitations(eventId);
  const canCommunicate = useRecoilValue(
    UserHasPrivilege('people_message.actions.send')
  );
  const hasStudio = AuthorizationService.hasPackage('studio');

  const confirmDeleteModal = (InvitationId: number) =>
    showConfirmModal({
      title: gettextCatalog.getString('Delete invite'),
      message: gettextCatalog.getString('Do you want to delete the invite?'),
      icon: <CdDeleteIcon />,
      okButtonProps: { danger: true },
      okText: gettextCatalog.getString('Delete'),
      onOk: () => {
        deleteInvitation(InvitationId);
      },
    });

  const items: MenuProps['items'] = [
    {
      key: 'edit',
      label: gettextCatalog.getString('Edit'),
      icon: <CdEditIcon />,
    },
    {
      key: 'delete',
      label: gettextCatalog.getString('Delete'),
      icon: <CdDeleteIcon />,
    },
  ];
  const handleDropDownClick = (option, id: number) => {
    switch (option.key) {
      case 'edit':
        handleEditInvitation(id);
        break;
      case 'delete':
        confirmDeleteModal(id);
        break;
      default:
        break;
    }
  };

  const invitationsTableColumns: ColumnType<CalendarInvitation>[] = [
    {
      title: gettextCatalog.getString('Created Date'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 200,
      render: (createdAt, record: CalendarInvitation) => (
        <Link onClick={() => handleEditInvitation(record.id)}>
          <Space>
            <CdTime />
            {gettextCatalog.formatDate(createdAt, 'lll')}
          </Space>
        </Link>
      ),
    },
    {
      title: gettextCatalog.getString('Author'),
      dataIndex: 'author',
      key: 'author',
      width: 200,
      render: (author) => (
        <CdUserAvatar picture={author.picture} name={author.name} />
      ),
    },
    {
      title: null,
      dataIndex: null,
      key: 'actionsMenu',
      fixed: 'right',
      width: 200,
      align: 'center',
      render: (invitation) => (
        <Dropdown
          menu={{
            items,
            onClick: (option) => handleDropDownClick(option, invitation.id),
          }}
        >
          <Button size="small">
            {gettextCatalog.getString('More')} <CdAngleDown />
          </Button>
        </Dropdown>
      ),
    },
  ];

  return (
    (hasStudio && (
      <CdSimpleTable<CalendarInvitation>
        noStyle
        recoilSource={EventInvitationsSource}
        emptyStateProps={{
          title: gettextCatalog.getString('No invites found'),
          buttonText: gettextCatalog.getString('Create invite'),
          buttonType: 'default',
          EmptyStateIcon: <CdPoster />,
          onButtonClick: () => handleCreateInvitation(),
          buttonDisabled: !canCommunicate,
        }}
        columns={invitationsTableColumns}
      />
    )) || (
      <Suspense fallback={<Spin />}>
        <CalendarPosterLandingPage />
      </Suspense>
    )
  );
};
