import { Button, Col, Image, Row, Typography } from 'antd';
import React from 'react';
import { useRecoilValue } from 'recoil';

import postLandingPage from './poster-landing-page.png';

import { ChurchSettingsState } from '@/react/organization/store/church';
import { getCRMContactDetails } from '@/react/shared/services/CRMContactDetailsService';
import { LanguageState } from '@/react/user/store/user-session';
import { gettextCatalog } from '@/react/services/I18nService';
import { CdSalesButton } from '@/react/shared/components/cd-buttons/CdSalesButton';

const { Title, Paragraph } = Typography;

export const CalendarPosterLandingPage = () => {
  const language = useRecoilValue(LanguageState);
  const churchSettings = useRecoilValue(ChurchSettingsState);
  const accountManager = churchSettings.accountManager;
  const { bookingPhone } = getCRMContactDetails(language);
  let bookingImage;
  switch (language) {
    case 'da':
      bookingImage = postLandingPage;
      break;
    case 'de':
      bookingImage = postLandingPage;
      break;

    default:
      bookingImage = postLandingPage;
      break;
  }
  return (
    <Row style={{ padding: '16px' }}>
      <Col span={12} style={{ textAlign: 'center' }}>
        <Title level={3}>
          {gettextCatalog.getString('Make a poster or flyer for your event')}
        </Title>
        <Paragraph>
          {gettextCatalog.getString(
            'With Studio you can create beautiful posters and flyers directly from the events in your calendar. It will never be easier to make flyers or posters. Click, edit and PDF it for easy printing.'
          )}
        </Paragraph>
        <CdSalesButton
          href={accountManager?.bookingLink}
          target="_blank"
          rel="noreferrer"
        >
          {gettextCatalog.getString('Book a demonstration')}
        </CdSalesButton>
        <Button type="link" onClick={() => window.open(`tel:${bookingPhone}`)}>
          {gettextCatalog.getString('or call us at {{phone}}', {
            phone: bookingPhone,
          })}
        </Button>
      </Col>
      <Col span={12} style={{ textAlign: 'center' }}>
        <Image width={200} src={bookingImage} />
      </Col>
    </Row>
  );
};
