import React from 'react';
import { Button } from 'antd';

import gettextCatalog from '../../../services/I18nService';
import CdClientSideTable from '../../../shared/components/cd-client-side-table/CdClientSideTable';
import { CdEyeIcon } from '../../../shared/components/Icons';
import { useEnquiries } from '../hooks/use-enquiries';
import { useEnquiriesPage } from '../hooks/use-enquiries-page';

import { commonColumns } from './OpenEnquiriesTab';

export const ClosedEnquiriesTab = () => {
  const { viewEnquiry } = useEnquiriesPage();

  return (
    <CdClientSideTable
      querySource={useEnquiries}
      searchParams={{ status: ['accepted', 'rejected'] }}
      noStyle
      columns={[
        ...commonColumns,
        {
          title: gettextCatalog.getString(
            'Responded by',
            null,
            'Name of the user who responded to the enquiry'
          ),
          dataIndex: 'respondedBy',
          render: (respondedBy) => respondedBy?.contact?.fullName,
        },
        {
          key: 'actions',
          width: 1,
          render: (_, record) => (
            <Button
              icon={<CdEyeIcon />}
              onClick={() => viewEnquiry(record.id)}
              size="small"
            >
              {gettextCatalog.getString('View')}
            </Button>
          ),
        },
      ]}
      emptyStateProps={{
        title: gettextCatalog.getString('No closed enquiries'),
      }}
    />
  );
};
