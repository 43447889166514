import { useGroups } from '../../../organization/store/organization';
import { CategoryType } from '../../../shared/models/category';
import { AvailabilityTaxonomy } from '../../../taxonomies/services/Taxonomies';
import { useTaxonomies } from '../../hooks/use-taxonomies';

export const useAvailabilities = (id: number) => {
  const { taxonomies } = useTaxonomies<AvailabilityTaxonomy>(
    CategoryType.availability
  );
  const { groups } = useGroups();

  const selectedTaxonomy = taxonomies?.find((taxonomy) => taxonomy.id === id);
  const groupIdsInTaxonomy = selectedTaxonomy?.groups.map((group) => group.id);
  const groupsInTaxonomy = groups?.filter((group) =>
    groupIdsInTaxonomy?.includes(group.id)
  );

  const groupMembers =
    groupsInTaxonomy?.map((group) => group.members).flat() || [];

  return { selectedTaxonomy, groupMembers };
};
