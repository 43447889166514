import { capitalize } from 'lodash';

import gettextCatalog from '@/react/services/I18nService';
import {
  FormBookingHours,
  ScheduleItem,
} from '@/react/settings/booking-pages/types/availability-taxonomy.types';
import { validationRule } from '@/react/shared/antd/validations/validation-rule';

export const aliasValidator = validationRule<string>((value) => {
  if (!value.match(/^[a-z0-9-]+$/)) {
    throw new Error(
      gettextCatalog.getString(
        'Alias can only contain lowercase letters, numbers and hyphens'
      )
    );
  }
});

export const hasOverlappingTime = validationRule<FormBookingHours>((value) => {
  const validationErrors = Object.entries(value)
    .map(([k, v]) => {
      if (v.enabled) {
        return doesIntervalsOverlap(v.data, k);
      }
    })
    .filter(Boolean);
  const weekdaysWithOverlappingTime = validationErrors.filter(
    (e) => e.type === 'overlap'
  );
  const weekdaysWithSameTime = validationErrors.filter(
    (e) => e.type === 'same'
  );
  const weekdaysWithStartHigher = validationErrors.filter(
    (e) => e.type === 'start-higher'
  );

  const getWeekdays = (weekdays: { weekday: string }[]) =>
    weekdays
      // it's ok to have a variable inside gettextCatalog here
      // because we are sure that weekdays are translated
      .map((weekdayError) => gettextCatalog.getString(weekdayError.weekday))
      .join(', ');

  if (weekdaysWithOverlappingTime.length > 0) {
    throw new Error(
      gettextCatalog.getString(
        '{{ weekdays }} share overlapping time',
        { weekdays: capitalize(getWeekdays(weekdaysWithOverlappingTime)) },
        'Validation error indicating that specific weekday(s) have overlapping day e.g: Monday, Tuesday has overlapping time'
      )
    );
  }
  if (weekdaysWithSameTime.length > 0) {
    throw new Error(
      gettextCatalog.getString(
        '{{ weekdays }} have the same start and end time',
        { weekdays: capitalize(getWeekdays(weekdaysWithSameTime)) },
        'Validation error indicating that specific weekday(s) have the same start and end time e.g: Monday, Tuesday have the same start and end time'
      )
    );
  }
  if (weekdaysWithStartHigher.length > 0) {
    throw new Error(
      gettextCatalog.getString(
        '{{ weekdays }} have a start time that is later than the end time',
        { weekdays: capitalize(getWeekdays(weekdaysWithStartHigher)) },
        'Validation error indicating that specific weekday(s) have a start time that is later than the end time e.g: Monday, Tuesday have a start time that is later than the end time'
      )
    );
  }
});

export const doesIntervalsOverlap = (
  intervals: ScheduleItem[],
  weekday: string
) => {
  for (let i = 0; i < intervals.length; i++) {
    for (let j = i + 1; j < intervals.length; j++) {
      if (
        intervals[i].start < intervals[j].end &&
        intervals[i].end > intervals[j].start
      ) {
        return {
          weekday,
          type: 'overlap',
          intervals: [intervals[i], intervals[j]],
        };
      }
    }
    if (intervals[i].start === intervals[i].end) {
      return { weekday, type: 'same', intervals: [intervals[i]] };
    }
    if (intervals[i].start > intervals[i].end) {
      return { weekday, type: 'start-higher', intervals: [intervals[i]] };
    }
  }
};
