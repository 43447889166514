import {
  clone,
  cloneDeep,
  compact,
  isArray,
  isEmpty,
  isUndefined,
  map,
  orderBy,
  pick,
} from 'lodash';
import moment from 'moment';
import { createContext, useEffect, useState } from 'react';
import {
  useRecoilCallback,
  useRecoilValue,
  waitForAll,
  useRecoilRefresher_UNSTABLE as useRecoilRefresher,
  useSetRecoilState,
  useRecoilState,
  useRecoilValueLoadable,
} from 'recoil';
import { $injector } from 'ngimport';
import { useDispatch } from 'react-redux';
import NiceModal from '@ebay/nice-modal-react';
import { Form, FormInstance } from 'antd';

import { hasRepeatSettingsChanged } from '../services/repeat-series.service';
import { getTempCropKey } from '../components/CdImagePicker';
import { hasEventFormChanged } from '../services/checkIfEventFormHasChange';
import {
  eventInfoForSpecialRevision,
  getCalendar,
} from '../services/event.service';

import { UponCloseInterface } from './use-close-modal';
import { useRotaAndIntentionState } from './use-rota-and-intention-state';

import { mainApi } from '@/react/api';
import {
  EventInitializationBaseOnFormFields,
  EventQuery,
  UpdatedPermissionQuery,
} from '@/react/calendar/store/events/event';
import { ChurchSettingsState } from '@/react/organization/store/church';
import {
  handleAntFormValidationErrors,
  handleError,
} from '@/react/services/ErrorHandlingService';
import NotificationService from '@/react/services/NotificationService';
import { gettextCatalog } from '@/react/services/I18nService';
import { UibModal } from '@/react/calendar/services/EventPopupService';
import {
  Attendance,
  DetailedEvent,
  Visibility,
} from '@/react/calendar/models/calendar';
import { AddEvent } from '@/react/calendar/store/main-view/Actions';
import { fullCalendarActions } from '@/react/calendar/store/events/fullCalendarEventsSlice';
import { GetAttendanceFields } from '@/react/calendar/store/events/eventAttendanceFields';
import { GetNotifications } from '@/react/calendar/store/events/eventBookedusersNotifications';
import { getRotaAndIntentionsChanges } from '@/react/calendar/event-details/components/rotas/RotaAndIntentions.service';
import {
  PropsEditRepeatedEventModal,
  ResultEditRepeatedEventModal,
} from '@/react/calendar/event-details/components/modals/EditRepeatedEventModal';
import {
  RotaTableInitialRRuleValue,
  RotaTableState,
} from '@/react/calendar/store/events/eventRotaDuties';
import { showConfirmModal } from '@/react/shared/components/cd-confirm-modal/CdConfirmModal';
import { CalendarEventTabs } from '@/react/calendar/types/event';
import cdApp from '@/react/config';
import { OrganizationUsers } from '@/react/organization/store/organization';

export enum EventLoadingState {
  NOT_LOADING = '0',
  SAVING = '1',
  SAVING_AND_NOTIFYING = '2',
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface NewEventProps
  extends Required<
    Pick<
      DetailedEvent,
      | 'title'
      | 'startDate'
      | 'endDate'
      | 'allDay'
      | 'type'
      | 'hideEndTime'
      | 'resources'
      | 'preparationStartDate'
      | 'cleanupEndDate'
      | 'churches'
      | 'locationName'
      | 'locationObj'
      | 'users'
      | 'mainCategory'
      | 'taxonomies'
      | 'internalNote'
      // Attached files
      | 'contributor'
      | 'imageId'
      | 'summary'
      | 'description'
      | 'visibility'
      | 'groups'
      | 'showInSlideshow'
      | 'price'
      | 'sogndk'
      | 'rotas'
      | 'alias'
      | 'shiftsAndIntentions'
      | 'files'
      // added form?
    >
  > {
  cropKey: string;
  fromPopup: boolean;
  isCopiedEvent: boolean;
}

export const EventAccessContext = createContext<{
  eventAccess: Partial<
    Pick<
      DetailedEvent,
      'canCreate' | 'canEdit' | 'canNotify' | 'canDelete' | 'fields'
    >
  >;
  disabledByVersionHistory: boolean;
}>(null);

export const useEvent = ({
  eventId,
  newEvent,
  form,
  close,
  setCurrentTab,
  setRevisionVersion,
}: {
  eventId: number;
  newEvent: NewEventProps;
  form: FormInstance;
  close: (input?: UponCloseInterface) => Promise<void>;
  setCurrentTab: (currentTab: CalendarEventTabs) => void;
  setRevisionVersion: (revisionVersion: {
    version: string;
    authorId?: number;
  }) => void;
}) => {
  const dispatch = useDispatch();
  const [eventIdState, setEventIdState] = useState(eventId);
  const { resetRotaTableStateToNull, setInitialRotaTableState } =
    useRotaAndIntentionState();
  const visibilitySetting = Form.useWatch('visibilitySetting', form);
  const setRotaTableInitialRRuleValue = useSetRecoilState(
    RotaTableInitialRRuleValue
  );
  const [event, eventAccess, churchSettings, attendanceFields, users] =
    useRecoilValue(
      waitForAll([
        EventQuery({ calendarId: eventId, clientVersion: 3 }),
        UpdatedPermissionQuery({
          eventId,
          visibility: !eventId && newEvent?.visibility,
          churches: !eventId && newEvent?.churches.map((c) => ({ id: c.id })),
        }),
        ChurchSettingsState,
        GetAttendanceFields,
        OrganizationUsers,
      ])
    );
  const [initialFormValues, setInitialFormValues] = useRecoilState(
    EventInitializationBaseOnFormFields
  );
  const notificationRefresher = useRecoilRefresher(
    GetNotifications({ entityId: eventId, entityType: 'calendar' })
  );
  const attendanceFieldsRefresher = useRecoilRefresher(GetAttendanceFields);
  const refreshEvent = useRecoilRefresher(
    EventQuery({ calendarId: eventId, clientVersion: 3 })
  );

  const eventAccessContextLoadable = useRecoilValueLoadable(
    UpdatedPermissionQuery({
      eventId: eventId,
      visibility: visibilitySetting?.visibility || newEvent?.visibility,
      groups: visibilitySetting?.groupIds,
      churches: !eventId && newEvent.churches.map((c) => ({ id: c.id })),
    })
  );
  const eventAccessContext =
    eventAccessContextLoadable.state === 'hasValue'
      ? eventAccessContextLoadable.contents
      : eventAccess;

  const [saveProcessing, setSaveProcessing] = useState<EventLoadingState>(
    EventLoadingState.NOT_LOADING
  );
  const existingEventAttendanceFields = attendanceFields.map((field) => {
    const result = event?.attendance?.attendanceFields.find(
      (attfield) => attfield.id === field.id
    );
    return result || field;
  });
  let visibilityValue: Visibility = null;
  if (newEvent?.fromPopup || newEvent?.isCopiedEvent) {
    visibilityValue = newEvent?.visibility;
  } else if (eventAccess?.fields?.visibility.allowedValues.includes('public')) {
    // default setting back to "All users" - most events created are internal or at least not ready for public just when created
    // @ts-ignore: internal-all is allowed in the form state
    visibilityValue = 'internal-all';
  } else if (
    eventAccess?.fields?.visibility.allowedValues.includes('internal-all')
  ) {
    // @ts-ignore: internal-all is allowed in the form state
    visibilityValue = 'internal-all';
  } else if (
    eventAccess?.fields?.visibility.allowedValues.includes('internal-group')
  ) {
    // @ts-ignore: internal-group is allowed in the form state
    visibilityValue = 'internal-group';
  } else {
    visibilityValue = Visibility.Private;
  }
  const eventInitialFormData = eventToFormData({
    event: !eventId
      ? initializeNewEvent(
          {
            ...newEvent,
            users: pick(
              newEvent?.users,
              users.map((user) => user.id.toString())
            ),
            churches: newEvent?.churches,
            visibility: visibilityValue,
            cropKey:
              newEvent?.isCopiedEvent && newEvent?.cropKey
                ? newEvent.cropKey
                : getTempCropKey(),
          },
          {
            attendanceCategory: null,
            attendanceFields: existingEventAttendanceFields,
            vicar: null,
          }
        )
      : {
          ...event,
          attendance: {
            ...event.attendance,
            attendanceFields: existingEventAttendanceFields,
          },
        },
    eventAccess,
  });

  // reload attendance fields after define them for the first time in an installation
  useEffect(() => {
    // Init attendance fields
    const values = clone(form.getFieldsValue(true));
    if (values.attendance?.attendanceFields) return;
    values.attendance.attendanceFields = existingEventAttendanceFields;
    form.setFieldsValue(values);
    setInitialFormValues(values);
  }, [existingEventAttendanceFields, form, setInitialFormValues]);

  useEffect(() => {
    // This monitors the visibility setting and sets the churchIds if the
    // visibility is not private and there is only one church.
    // This is especially usefull when the event was created as private
    // and then changed to internal or public.
    if (
      visibilitySetting?.visibility &&
      visibilitySetting?.visibility !== 'private' &&
      cdApp.organization.churches.length === 1
    ) {
      const currentChurchIds = form.getFieldValue([
        'resourcesLocation',
        'churchIds',
      ]);
      if ((currentChurchIds || []).length === 0) {
        form.setFieldValue(
          ['resourcesLocation', 'churchIds'],
          [cdApp.organization.churches[0].id]
        );
      }
    }
  }, [form, visibilitySetting]);

  useEffect(
    () => () => {
      // When the component unmounts, this cleanup function is called.
      // It sets a timeout to refresh various parts of the component state.
      setTimeout(() => {
        refreshEvent(); // Refreshes the event data.
        notificationRefresher(); // Refreshes the notifications.
        attendanceFieldsRefresher(); // Refreshes the attendance fields.
        resetRotaTableStateToNull(); // Resets the rota table state.
        setRotaTableInitialRRuleValue(null); // Resets the rota table rrule.
        setInitialFormValues(null); // Resets the initial form values.
      }, 0);
    },
    [
      attendanceFieldsRefresher,
      notificationRefresher,
      refreshEvent,
      resetRotaTableStateToNull,
      setInitialFormValues,
      setRotaTableInitialRRuleValue,
    ]
  );
  useEffect(() => {
    if (!eventId) return;
    if (!initialFormValues) {
      setInitialFormValues(eventInitialFormData);
    }
  }, [eventId, eventInitialFormData, initialFormValues, setInitialFormValues]);
  const saveAndClose = (props: SaveAndCloseInterface) =>
    new Promise<void>((resolve) => {
      const { title, okText, cancelText, message, skipRedirecting } = props;
      const currentFormValues = form.getFieldsValue();
      if (
        hasEventFormChanged({
          currentFormValues,
          initialFormValues,
        })
      ) {
        showConfirmModal({
          title,
          okText,
          cancelText,
          message,
          onOk: async () => {
            const res = await onSave({ notify: false, skipRedirecting });
            return resolve(res.data.id);
          },
        });
      } else {
        close({ skipRedirecting });
        return resolve();
      }
    });

  const onSave = useRecoilCallback(
    ({ set }) =>
      async ({
        notify,
        saveAndContinue,
        skipRedirecting,
      }: {
        notify: boolean;
        saveAndContinue?: boolean;
        skipRedirecting: boolean;
      }) => {
        notify
          ? setSaveProcessing(EventLoadingState.SAVING_AND_NOTIFYING)
          : setSaveProcessing(EventLoadingState.SAVING);
        // Validate
        const values = await form.validateFields().catch((err) => {
          setSaveProcessing(EventLoadingState.NOT_LOADING);
          handleAntFormValidationErrors(err);
        });
        if (!values) return;

        const { rotas, shiftsAndIntentions } = getRotaAndIntentionsChanges(
          {
            rotas: event?.rotas,
            shiftsAndIntentions: event?.shiftsAndIntentions,
          },
          values.rotasAndIntentions
        );

        values.rotas = rotas;
        values.shiftsAndIntentions = shiftsAndIntentions;
        // Remove internal form state for rotas and intentions.
        values.rotasAndIntentions = undefined;

        // Copy the attendance values for later use.
        const attendanceValues = values.attendance;
        values.attendance = undefined;

        // Remove internal form state for prepAndCleanUp.
        values.resourcesLocation = {
          ...values.resourcesLocation,
          prepAndCleanUp: undefined,
        };

        // for user who doesn't have access to sensitive informatin , remove the sensitive information
        if (values.secureInformation && values.secureInformation === '***') {
          values.secureInformation = undefined;
        }

        // for user who doesn't have access to internal note , it should be removed from payload
        if (values.internalNote && values.internalNote === '***') {
          values.internalNote = undefined;
        }

        // Submit
        const valuesDTO = translateFormFieldsToDTO(values, !!eventId);
        valuesDTO.sendNotifications = notify;

        // If part of event series ask user whether to update following or current event.
        const timeChanged = hasRepeatSettingsChanged(event, values.date);
        if (event?.rrule) {
          const { abort, updateAll } = await NiceModal.show<
            ResultEditRepeatedEventModal,
            PropsEditRepeatedEventModal
          >('EditRepeatedEventModal', {
            type: valuesDTO.type,
            timeChanged,
          });
          if (abort) {
            // Cancel save process.
            setSaveProcessing(EventLoadingState.NOT_LOADING);
            return;
          }
          valuesDTO.updateAll = updateAll;
        }

        let response = await apiSave(valuesDTO, eventId || eventIdState);

        if (response.status === 409) {
          // Double booking was detected. Ask user to allow it.
          NotificationService.notifyWarning(
            gettextCatalog.getString(
              'One or more conflicts prevented this from being saved.'
            )
          );
          const allowDoubleBooking = await showConflicts(response.data);
          response = await apiSave(
            { ...valuesDTO, allowDoubleBooking },
            eventId
          );
        }
        // attendance
        if (response.ok) {
          await registerAttendance(attendanceValues, response.data.id);
        }

        // Refresh local data.
        if (!eventId) {
          dispatch(AddEvent(response.data));
          NotificationService.notifySuccess(
            gettextCatalog.getString('The event has been created')
          );
        } else {
          dispatch(fullCalendarActions.reloadCurrentView());
          // This check is added not to refresh the event if there is a date/time/rrule
          // changed since the old event will be deleted to create a new event.
          if (!timeChanged) {
            refreshEvent();
          }
          NotificationService.notifySuccess(
            gettextCatalog.getString('The event has been updated')
          );
        }

        // Close modal
        if (!saveAndContinue) {
          close({ skipUnSavedCheck: true, skipRedirecting });
        } else {
          // Take the response data and update the form with it.
          const newEventId = response.data.id;
          const freshEvent = await getCalendar(newEventId);
          if (freshEvent) {
            const newFormData = eventToFormData({
              event: freshEvent,
              eventAccess: { fields: freshEvent.fields },
            });
            // This should set up to date information on rotas and repeated events.
            set(RotaTableState, newFormData.rotasAndIntentions); // Resets the rota table state.
            set(RotaTableInitialRRuleValue, newFormData.date.rrule); // Resets the rota table rrule.
            setEventIdState(newEventId);
            form.setFieldValue('cropKey', newEventId);
          }
        }
        return response;
      },
    [form, event, eventId, eventIdState, dispatch, refreshEvent, close]
  );

  const apiSave = async (valuesDTO: any, eventId?: number) => {
    let response;
    if (
      valuesDTO.rrule?.length > 0 &&
      !(valuesDTO.rrule as string).startsWith('RRULE')
    ) {
      throw new Error('Invalid RRULE');
    }
    if (!eventId) {
      response = await mainApi.post<any>(`/calendar`, valuesDTO);
    } else {
      response = await mainApi.put<any>(`/calendar/${eventId}`, valuesDTO);
    }
    setSaveProcessing(EventLoadingState.NOT_LOADING);
    if (response.status !== 409 && !response.ok) {
      handleError(response);
      throw response;
    }
    return response;
  };

  const onDelete = async () => {
    const calendarResource: any = $injector.get('Calendar');
    const $uibModal = $injector.get('$uibModal') as UibModal;
    const eventFromAngular = await calendarResource.get({ id: eventId })
      .$promise;
    return $uibModal
      .open({
        component: 'cdEventDeleteModal',
        resolve: {
          event: () => eventFromAngular,
        },
      })
      .result.then(() => {
        close({ skipUnSavedCheck: true });
      });
  };
  const setEventFormWithDataBasedOnVersionHistory = async ({
    revisionId,
    isFromBackToCurrentVesionButton,
  }: {
    revisionId?: number;
    isFromBackToCurrentVesionButton?: boolean;
  }) => {
    if (isFromBackToCurrentVesionButton) {
      const currentEvent = eventToFormData({ event: event, eventAccess });
      setRevisionVersion({ version: 'current', authorId: null });
      setInitialRotaTableState({
        rotas: currentEvent.rotasAndIntentions.rotas,
        shiftsAndIntentions:
          currentEvent.rotasAndIntentions.shiftsAndIntentions,
      });

      form.setFieldsValue({
        ...currentEvent,
      });
      setCurrentTab(CalendarEventTabs.DETAILS_PAGE);
    } else {
      const result = await eventInfoForSpecialRevision({
        revisionId,
        eventId,
      });
      if (result) {
        const pastEvent = eventToFormData({ event: result.data, eventAccess });
        const versionDate = moment(result.data.updatedAt).format(
          'DD MMM YYYY HH:mm'
        );
        const authorId = result.data.author.id;
        setRevisionVersion({ version: versionDate, authorId });
        setInitialRotaTableState({
          rotas: pastEvent.rotasAndIntentions.rotas,
          shiftsAndIntentions: pastEvent.rotasAndIntentions.shiftsAndIntentions,
        });

        form.setFieldsValue({
          ...pastEvent,
          sogndk: {
            categoryId: pastEvent.sogndk?.categoryId,
            churchId: pastEvent.sogndk?.churchId,
            export: pastEvent.sogndk?.export,
            id: pastEvent.sogndk?.id,
            pastorId: pastEvent.sogndk?.pastorId,
            sognId: pastEvent.sogndk?.sognId,
            status: pastEvent.sogndk?.status,
          },
          facebook: {
            exportStatus: pastEvent.facebook?.exportStatus,
            isScheduled: pastEvent.facebook?.isScheduled,
            message: pastEvent.facebook?.message,
            publish: pastEvent.facebook?.publish,
            publishOn: pastEvent.facebook?.publishOn,
            publishedOn: pastEvent.facebook?.publishedOn,
            schedulingOptions: pastEvent.facebook?.schedulingOptions,
          },
        });

        setCurrentTab(CalendarEventTabs.DETAILS_PAGE);
      } else {
        NotificationService.notifyError(
          gettextCatalog.getString('Something went wrong.')
        );
      }
    }
  };

  return {
    event,
    eventAccessContext,
    eventInitialFormData,
    churchSettings,
    onSave,
    onDelete,
    saveProcessing,
    saveAndClose,
    showConflicts,
    setEventFormWithDataBasedOnVersionHistory,
  };
};

export type UponCloseFunc = ({
  skipUnSavedCheck,
}?: UponCloseInterface) => Promise<void>;

export interface SaveAndCloseInterface {
  title: string;
  okText: string;
  cancelText: string;
  message: string;
  skipRedirecting?: boolean;
}

/**
 * Function to convert the preparation and clean up time in minutes to date format.
 * @param object
 * date : startDate | endDate
 * operation: add | substract
 * value: prep time and cleanUp time in minutes.
 * @returns  object
 */
const convertTime = ({ date, operation, value }) =>
  operation === 'add'
    ? moment(date).add(value, 'minutes').toDate()
    : moment(date).subtract(value, 'minutes').toDate();

const translateFormFieldsToDTO = (values, isExistingEvent: boolean) => {
  let saneForm;

  // Do not send an empty form object. Allow sending a form.id=null to tell the backend to remove the form.
  if (values.form === null || typeof values.form === 'undefined') {
    saneForm = values.form;
  } else if (
    typeof values.form.id === 'undefined' &&
    !values.form.configuration
  ) {
    saneForm = undefined;
  } else {
    saneForm = { id: values.form.id, configuration: values.form.configuration };
  }

  // Convert time to full day events before sending it to the backend.
  // Currently the backend is not capable of doing this.
  if (values.date.allDay) {
    values.date.startDate = values.date.startDate.startOf('day');
    values.date.endDate = values.date.endDate.endOf('day');
  }

  const preparationStartDate =
    values.resourcesLocation?.prepTimeMinutes > 0
      ? convertTime({
          date: values.date?.startDate,
          operation: 'subtract',
          value: values.resourcesLocation?.prepTimeMinutes,
        })
      : values.date?.startDate;

  const cleanupEndDate =
    values.resourcesLocation?.cleanupTimeMinutes > 0
      ? convertTime({
          date: values.date?.endDate,
          operation: 'add',
          value: values.resourcesLocation?.cleanupTimeMinutes,
        })
      : values.date?.endDate;

  return {
    ...values,
    ...values.resourcesLocation,
    churchIds:
      values.visibilitySetting?.visibility === 'private'
        ? undefined
        : values.resourcesLocation?.churchIds,
    groupIds:
      values.visibilitySetting?.visibility === 'internal-group' ||
      values.visibilitySetting?.visibility === 'public'
        ? isArray(values?.visibilitySetting?.groupIds)
          ? values?.visibilitySetting?.groupIds
          : compact([values?.visibilitySetting?.groupIds])
        : [],
    visibility: Object.values(Visibility).includes(
      values.visibilitySetting?.visibility
    )
      ? values.visibilitySetting?.visibility
      : isUndefined(values.visibilitySetting.visibility)
        ? undefined
        : 'internal',
    resourcesLocation: undefined,
    resources: values.resourcesLocation.resourceIds,
    ...values.date,
    date: undefined,
    startDate: values.date.startDate.toISOString(),
    endDate: values.date.endDate?.toISOString(),
    preparationStartDate,
    cleanupEndDate,
    clientVersion: 3,
    form: saneForm,
    // Cropkey is only included if the event is new since the image style has no entity id to attach to.
    cropKey: isExistingEvent ? undefined : `event-${values.cropKey}`,
  };
};
/**
 * Function to calculate the time difference in minutes
 */
const timeDifferenceInMinutes = ({ extraTime, date }) =>
  Math.abs(moment(date).diff(extraTime, 'minutes'));

const registerAttendance = async (attendance: Attendance, eventId: number) => {
  if (!attendance) return;
  const requests = [];
  attendance.attendanceFields.forEach((field) => {
    requests.push(
      mainApi.put(`attendance/events/${eventId}/amount/${field.id}`, {
        amount: attendance[field.name],
      })
    );
  });
  const { vicar } = attendance;
  if (vicar) {
    requests.push(
      mainApi.put(`attendance/events/${eventId}`, {
        vicarId: vicar.id,
      })
    );
  }
  const responses = await Promise.all(requests);

  responses.forEach((res) => {
    if (!res.ok) {
      handleError(res);
      throw res;
    }
  });
};

const initializeNewEvent = (
  {
    title,
    startDate,
    endDate,
    allDay,
    hideEndTime = true,
    resources,
    preparationStartDate,
    cleanupEndDate,
    churches,
    users,
    mainCategory,
    visibility,
    locationName,
    locationObj,
    taxonomies,
    internalNote,
    // Attached files
    contributor,
    imageId,
    summary,
    description,
    groups,
    showInSlideshow,
    price,
    sogndk,
    rotas,
    shiftsAndIntentions,
    // added form?
    cropKey,
    fromPopup,
    files,
    type,
  }: NewEventProps,
  attendance: Attendance
) =>
  ({
    title,
    startDate,
    endDate,
    allDay,
    hideEndTime,
    resources,
    preparationStartDate,
    cleanupEndDate,
    churches,
    users,
    mainCategory,
    visibility,
    locationName,
    locationObj,
    taxonomies,
    internalNote,
    summary,
    contributor,
    imageId,
    description,
    form: {},
    type: type || 'event',
    groups,
    showInSlideshow,
    price,
    sogndk,
    rotas,
    shiftsAndIntentions: shiftsAndIntentions || [
      { calendarId: null, shifts: [], intentions: [] },
    ],
    attendance,
    cropKey,
    fromPopup,
    files,
  }) as Partial<DetailedEvent>;

export const eventToFormData = ({
  event,
  eventAccess,
}: {
  event: Partial<DetailedEvent>;
  eventAccess;
}) => {
  const prepTimeMinutes = event.preparationStartDate
    ? timeDifferenceInMinutes({
        extraTime: event?.preparationStartDate,
        date: event?.startDate,
      })
    : undefined;
  const cleanupTimeMinutes = event.cleanupEndDate
    ? timeDifferenceInMinutes({
        extraTime: event?.cleanupEndDate,
        date: event?.endDate,
      })
    : undefined;
  let startDate;
  let endDate;
  if (event?.allDay) {
    if (event?.startDate) {
      startDate = moment(event.startDate)
        .hour(moment().hour() + 1)
        .startOf('hour');
    } else {
      startDate = moment().add(1, 'hour').startOf('hour');
    }
    if (event?.endDate) {
      endDate = moment(event.endDate)
        .hour(moment().hour() + 2)
        .startOf('hour');
    } else {
      endDate = moment().add(2, 'hour').startOf('hour');
    }
  } else {
    if (event?.startDate) {
      startDate = moment(event.startDate);
    } else {
      startDate = moment().add(1, 'hour').startOf('hour');
    }
    if (event?.endDate) {
      endDate = moment(event.endDate);
    } else {
      endDate = moment().add(2, 'hour').startOf('hour');
    }
  }

  const canEditInternalNote = eventAccess?.fields.internalNote?.canEdit;
  const canEditSensitiveInfo = eventAccess?.fields.secureInformation?.canEdit;
  if (!canEditInternalNote && isEmpty(event.internalNote) && event.id) {
    // Only hide internal note for existing events.
    event.internalNote = '***';
  }
  if (!canEditSensitiveInfo && isEmpty(event.secureInformation) && event.id) {
    // Only hide sensitive information for existing events.
    const tempEvent = Object.assign({}, event);
    tempEvent.secureInformation = '***';
    event = tempEvent;
  }

  const newLocationObj = cloneDeep(event?.locationObj);
  const resourceIdFromLocationMap =
    // @ts-ignore: ignore location_map. Backend has mixed location_map and custom_data.
    newLocationObj?.location_map?.resourceSourceId;
  if (resourceIdFromLocationMap && newLocationObj) {
    newLocationObj.custom_data = {
      resourceId: resourceIdFromLocationMap,
    };
    // @ts-ignore: ignore location_map. Backend has mixed location_map and custom_data.
    newLocationObj.location_map = undefined;
  }
  const attendanceFields = event.attendance?.attendanceFields;
  const attendance = {
    ...event.attendance,
  };
  attendanceFields?.forEach((item) => {
    if (item.amount) {
      attendance[item.name] = item.amount;
    } else {
      attendance[item.name] = null;
    }
  });
  const eventToFormData = {
    ...event,
    fromPopup: undefined, // remove from form state
    date: {
      startDate,
      endDate,
      allDay: event?.allDay,
      rrule: event?.rrule,
      hideEndTime: event?.hideEndTime,
    },
    resourcesLocation: {
      resourceIds: isArray(event?.resources)
        ? event?.resources
        : compact(map(event?.resources, (_, key) => parseInt(key, 10))),
      locationName: event?.locationName,
      locationObj: newLocationObj,
      churchIds: event?.churches?.map((church) => church.id),
      prepTimeMinutes: prepTimeMinutes === 0 ? undefined : prepTimeMinutes,
      cleanupTimeMinutes:
        cleanupTimeMinutes === 0 ? undefined : cleanupTimeMinutes,
      fromPopup: event.fromPopup,
      prepAndCleanUp: prepTimeMinutes || cleanupTimeMinutes ? true : false,
    },
    taxonomies: map(event?.taxonomies, (_, key) => parseInt(key, 10)).filter(
      (taxonomyId) => taxonomyId !== event.mainCategory
    ),
    users: map(event?.users, (_, key) => parseInt(key, 10)),
    files: event?.files?.map((file) => ({
      id: file.id,
      title: file.fileName,
      downloadUrl: file.downloadUrl,
      permission: { canView: file.canDownload },
    })),
    visibilitySetting: {
      visibility:
        event.visibility === 'internal'
          ? isEmpty(event?.groups)
            ? 'internal-all'
            : 'internal-group'
          : event.visibility,
      groupIds: map(event?.groups, 'id'),
    },
    attendance,
    rotasAndIntentions: {
      rotas: event?.rotas ? event.rotas : [],
      shiftsAndIntentions: event?.shiftsAndIntentions?.map((item) => ({
        ...item,
        intentions: orderBy(
          item.intentions
            ? item.intentions?.map((intention) => ({
                ...intention,
                assignedBy: 'backend',
              }))
            : [],
          ['priority', 'reference', 'intentionText'],
          ['asc', 'asc', 'asc']
        ),
      })),
    },
  };
  return eventToFormData;
};

export const showConflicts = (conflicts, hideAllowConflicts?) => {
  const $uibModal = $injector.get('$uibModal') as UibModal;
  return new Promise((resolve: (allowDoubleBooking: boolean) => void) => {
    $uibModal.open({
      component: 'cdDoubleBookingModal',
      resolve: {
        conflicts: () => conflicts,
        hideAllowConflicts: () => hideAllowConflicts,
        allowConflicts: () => () => {
          resolve(true);
          // Backwards compatibility for the Angular component.
          return Promise.resolve(null);
        },
      },
    });
  });
};
