import { useState } from 'react';
import moment from 'moment';

import { FormBookingHours } from '../../types/availability-taxonomy.types';

import { Weekdays } from '@/react/shared/models/taxonomy';

const incrementEndTime = (time: string) => {
  const [hours, ...rest] = time.split(':');
  return [String((parseInt(hours) + 1) % 24).padStart(2, '0'), ...rest].join(
    ':'
  );
};

export const useWeeklyScheduleEvents = (
  value: FormBookingHours,
  onChange?: (value: FormBookingHours) => void
) => {
  const [expandedWeekdays, setExpandedWeekdays] = useState<string[]>(
    Object.entries(value)
      .filter(([, v]) => v.enabled)
      .map(([k]) => k)
  );

  const onWeekdayEnabledChange = (weekDay: Weekdays) => () => {
    const currentData = value[weekDay];
    onChange({
      ...value,
      [weekDay]: {
        ...value[weekDay],
        enabled: !currentData.enabled,
      },
    });
    if (currentData.enabled) {
      setExpandedWeekdays(expandedWeekdays.filter((d) => d !== weekDay));
    } else {
      setExpandedWeekdays([...expandedWeekdays, weekDay]);
    }
  };

  const onTimeChange =
    (weekDay: Weekdays, item: FormBookingHours[Weekdays]['data'][number]) =>
    (newTime: string, type: 'start' | 'end') => {
      const start = item.start;
      let end = item.end;

      if (type === 'start') {
        const diff = moment(newTime, 'HH:mm').diff(
          moment(item.start, 'HH:mm'),
          'minutes'
        );
        end = moment(end, 'HH:mm').add(diff, 'minutes').format('HH:mm');
      }

      onChange({
        ...value,
        [weekDay]: {
          ...value[weekDay],
          data: value[weekDay].data.map((d) =>
            d === item ? { start, end, [type]: newTime } : d
          ),
        },
      });
    };

  const onTimeRangeRemove =
    (weekDay: Weekdays, item: FormBookingHours[Weekdays]['data'][number]) =>
    () => {
      onChange({
        ...value,
        [weekDay]: {
          ...value[weekDay],
          data: value[weekDay].data.filter((d) => d !== item),
        },
      });
    };

  const onTimeRangeAdd = (weekDay: Weekdays) => () => {
    const previousData = value[weekDay].data[value[weekDay].data.length - 1];
    onChange({
      ...value,
      [weekDay]: {
        ...value[weekDay],
        data: [
          ...value[weekDay].data,
          {
            start: previousData.end,
            end: incrementEndTime(previousData.end),
          },
        ],
      },
    });
  };

  return {
    expandedWeekdays,
    setExpandedWeekdays,
    onWeekdayEnabledChange,
    onTimeRangeRemove,
    onTimeRangeAdd,
    onTimeChange,
  };
};
