import { PageHeader } from '@ant-design/pro-layout';
import {
  Button,
  Card,
  Checkbox,
  Col,
  Descriptions,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
} from 'antd';
/* eslint-disable react-hooks/exhaustive-deps */
import { extend, get, map, omit, pick, upperFirst } from 'lodash';
import moment from 'moment';
import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { gettextCatalog } from '../../../services/I18nService';
import StateServiceFactory, {
  RootScopeServiceFactory,
  StateParamsServiceFactory,
} from '../../../services/StateServiceFactory';
import TaxonomySelect from '../../../shared/components/TaxonomySelect';
import FeeAllocationTable from '../../components/intention-fees/FeeAllocationTable';
import { IntentionFee, IntentionFeeTypes } from '../../models/fee';
import {
  clearIntentionFee,
  createIntentionFee,
  fetchIntentionFee,
  updateIntentionFee,
} from '../../redux/intention-fees/Actions';
import { getIntentionFee } from '../../redux/intention-fees/Selectors';

import AuthorizationService from '@/react/services/AuthorizationService';
import { handleAntFormValidationErrors } from '@/react/services/ErrorHandlingService';
import { getDecimalSeparator } from '@/react/shared/utils';

// Antd
const { TextArea } = Input;
const { Option } = Select;

interface CreateIntentionFeeStateParam {
  currentState: string;
  id: string;
}

export const CreateIntentionFee: FunctionComponent = () => {
  const dispatch = useDispatch();

  const rootScope = RootScopeServiceFactory();
  const stateService = StateServiceFactory();
  const stateParams = StateParamsServiceFactory<CreateIntentionFeeStateParam>();
  const currentState = stateParams.currentState;
  const id = stateParams.id;
  const [form] = Form.useForm();

  // Define labels
  const submitButtonLabel =
    currentState === 'create'
      ? gettextCatalog.getString('Save')
      : gettextCatalog.getString('Update');

  const _intentionFee = useSelector(getIntentionFee);
  const intentionFee = _intentionFee && _intentionFee.asMutable({ deep: true });

  const feeTypes = [
    {
      id: IntentionFeeTypes.INTENTIONEN,
      label: upperFirst(IntentionFeeTypes.INTENTIONEN) as string,
    },
  ];

  if (AuthorizationService.hasPackage('intentionExtended')) {
    feeTypes.push({
      id: IntentionFeeTypes.STOLARIEN,
      label: upperFirst(IntentionFeeTypes.STOLARIEN),
    });
  }

  // Retrieve required entities
  useEffect(() => {
    // Initialize intention data in case of creation or update/view
    if (currentState === 'create') {
      dispatch(clearIntentionFee());
    } else {
      dispatch(fetchIntentionFee(id));
    }

    return () => {
      dispatch(clearIntentionFee());
    };
  }, []);

  // Update the form with the retrieved fee (update/view)
  useEffect(() => {
    if (currentState !== 'create' && intentionFee) {
      form.setFieldsValue({
        type: intentionFee.type,
        name: intentionFee.name,
        description: intentionFee.description,
        active: intentionFee.active,
        isDefault: intentionFee.isDefault,
        taxonomies: map(intentionFee.taxonomies, 'id'),
        feeAllocations: intentionFee.feeAllocations,
      });
    } else {
      form.setFieldsValue({
        type: null,
        name: null,
        description: null,
        active: true,
        isDefault: false,
        taxonomies: [],
        feeAllocations: [],
      });
    }
  }, [intentionFee ? intentionFee.id : null]);

  const save = async (formValues) => {
    const values = await form.validateFields().catch((err) => {
      handleAntFormValidationErrors(err);
    });
    if (!values) return;

    const associations = {
      taxonomies: map(formValues.taxonomies, (id) => ({ id })),
      feeAllocations: formValues.feeAllocations,
    };
    const payload: Partial<IntentionFee> = extend(
      {},
      omit(formValues, ['taxonomies']),
      associations
    ) as Partial<IntentionFee>;

    payload.feeAllocations = payload.feeAllocations.map((feeAllocation) =>
      pick(feeAllocation, ['amount', 'shouldForwardShare', 'recipientId'])
    );
    if (currentState === 'create') {
      dispatch(createIntentionFee(payload));
    } else {
      dispatch(updateIntentionFee({ id, updatePayload: payload }));
    }
  };

  const cancel = () => {
    // If previous tab has id in the url it is from an event, so go back there
    const previousStateParamsId = get(rootScope, 'previousStateParams.id');
    if (previousStateParamsId) {
      // Go back to event
      stateService.go(get(rootScope, 'previousState.name'), {
        id: previousStateParamsId,
      });
    } else {
      // If not from event, go back to the intention fees screen with intention on it
      stateService.go('app.private.settings.fees.overview', {
        tab: IntentionFeeTypes.INTENTIONEN,
      });
    }
  };

  // Get page title depending on current state
  let pageTitle;
  switch (currentState) {
    case 'edit':
      pageTitle = gettextCatalog.getString('Edit Fee');
      break;
    default:
      // Create
      pageTitle = gettextCatalog.getString('Create Fee');
  }

  // Return the create intention form
  return (
    <React.Fragment>
      <div className="app-main">
        <PageHeader
          className="site-page-header-responsive"
          style={{ padding: '0px 0px 24px 0px' }}
          title={pageTitle}
          extra={
            <Space>
              <Button
                form="createIntentionFeeForm"
                htmlType="submit"
                type="primary"
              >
                {submitButtonLabel}
              </Button>
              <Button onClick={cancel}>Cancel</Button>
            </Space>
          }
        />
        <Form
          id="createIntentionFeeForm"
          onFinish={save}
          form={form}
          size="middle"
          layout="vertical"
        >
          {/* Start of left column */}
          <Card bordered style={{ marginBottom: 16 }}>
            <Row justify="center">
              <Col flex="600px">
                <Form.Item
                  name="type"
                  label={gettextCatalog.getString('Fee Type')}
                  required={true}
                >
                  <Select
                    placeholder={gettextCatalog.getString('Type...')}
                    allowClear
                    disabled={currentState !== 'create'}
                  >
                    {feeTypes.map((fee) => (
                      <Option key={fee.id} value={fee.id}>
                        {get(fee, 'label')}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  name="name"
                  label={gettextCatalog.getString('Name')}
                  required={true}
                >
                  <Input placeholder={gettextCatalog.getString('Name...')} />
                </Form.Item>

                <Form.Item
                  name="active"
                  valuePropName="checked"
                  required={true}
                  label={gettextCatalog.getString('Active')}
                  extra={gettextCatalog.getString(
                    'Select if this fee is active and can be chosen when creating intentions.'
                  )}
                >
                  <Checkbox />
                </Form.Item>

                <Form.Item
                  name="description"
                  label={gettextCatalog.getString('Description')}
                >
                  <TextArea
                    rows={3}
                    placeholder={gettextCatalog.getString('Description...')}
                  />
                </Form.Item>

                <Form.Item
                  name="taxonomies"
                  label={gettextCatalog.getString('Event types')}
                  extra={gettextCatalog.getString(
                    'Select the events where this fee should be applied.'
                  )}
                  rules={[
                    {
                      required: true,
                      message: gettextCatalog.getString(
                        'The field is required.'
                      ),
                    },
                  ]}
                >
                  <TaxonomySelect
                    placeholder={gettextCatalog.getString(
                      'Select event types...'
                    )}
                  />
                </Form.Item>

                <Form.Item
                  name="isDefault"
                  valuePropName="checked"
                  label={gettextCatalog.getString('Default')}
                  extra={gettextCatalog.getString(
                    'Select if this fee should be the default fee.'
                  )}
                >
                  <Checkbox />
                </Form.Item>

                {AuthorizationService.hasPackage('intentionSimple') &&
                  !AuthorizationService.hasPackage('intentionExtended') && (
                    <Form.Item
                      name={['feeAllocations', 0, 'amount']}
                      label={gettextCatalog.getString('Amount')}
                      required={true}
                    >
                      <InputNumber
                        decimalSeparator={getDecimalSeparator()}
                        precision={2}
                      />
                    </Form.Item>
                  )}
              </Col>
            </Row>
          </Card>

          {AuthorizationService.hasPackage('intentionExtended') && (
            <Card bordered style={{ marginBottom: 16 }}>
              {/* Fee allocations */}
              <Row justify="center">
                <Col flex="600px">
                  <Form.Item
                    name="feeAllocations"
                    required={true}
                    label={gettextCatalog.getString('Fee Allocations')}
                  >
                    <FeeAllocationTable />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          )}
        </Form>
        {get(intentionFee, 'createdBy') ||
        get(intentionFee, 'createdAt') ||
        get(intentionFee, 'updatedBy') ||
        get(intentionFee, 'updatedAt') ? (
          <Card bordered style={{ marginBottom: 16 }}>
            <Row justify="center">
              <Col flex="600px">
                <Descriptions
                  title={gettextCatalog.getString('Activity')}
                  column={1}
                  size="small"
                >
                  {get(intentionFee, 'createdBy') ? (
                    <Descriptions.Item
                      label={gettextCatalog.getString('Created by')}
                    >
                      {get(intentionFee, 'createdBy.contact.fullName')}
                    </Descriptions.Item>
                  ) : null}
                  {get(intentionFee, 'createdAt') ? (
                    <Descriptions.Item
                      label={gettextCatalog.getString('Created on')}
                    >
                      {moment(get(intentionFee, 'createdAt')).format('lll')}
                    </Descriptions.Item>
                  ) : null}
                  {get(intentionFee, 'updatedBy') ? (
                    <Descriptions.Item
                      label={gettextCatalog.getString('Updated by')}
                    >
                      {get(intentionFee, 'updatedBy.contact.fullName')}
                    </Descriptions.Item>
                  ) : null}
                  {get(intentionFee, 'updatedAt') ? (
                    <Descriptions.Item
                      label={gettextCatalog.getString('Updated on')}
                    >
                      {moment(get(intentionFee, 'updatedAt')).format('lll')}
                    </Descriptions.Item>
                  ) : null}
                </Descriptions>
              </Col>
            </Row>
          </Card>
        ) : null}
      </div>
    </React.Fragment>
  );
};
