import { atom, selector, selectorFamily } from 'recoil';
import _ from 'lodash';

import {
  Organization,
  OrganizationPortals,
  Portal,
  PortalCategory,
} from '../types/portal.type';

import { ApiSearchResult, mainApi } from '@/react/api';
import { SelectedOrganizationId } from '@/react/organization/store/organization';
import { handleError } from '@/react/services/ErrorHandlingService';

export interface PortalOrganizationsAndCategories {
  id: string;
  name: string;
  organizations: Organization[];
  categories: {
    id: string;
    name: string;
    description?: string;
    type: PortalCategoryType;
  }[];
}
export interface OrganizationSearchParams extends Record<string, any> {
  limit: number;
  pageNumber: number;
}

export enum PortalCategoryType {
  EVENT = 'event',
  BLOG = 'blog',
}

export const GetCurrentPortal = selector<PortalOrganizationsAndCategories>({
  key: 'GetCurrentPortal',
  get: ({ get }) => {
    const organizationPortals = get(
      GetOrganizationPortals(get(SelectedOrganizationId))
    );

    const portal =
      (_.head(
        _.filter(organizationPortals.portals, (_portal) =>
          _.find(_portal.portalOrganizations, { isHost: true })
        )
      ) as Portal) || organizationPortals.portals[0];
    return get(GetPortal(portal?.id));
  },
});

export const GetCurrentFullPortal = selector<PortalOrganizationsAndCategories>({
  key: 'GetCurrentFullPortal',
  get: ({ get }) => {
    const organizationPortals = get(
      GetOrganizationPortals(get(SelectedOrganizationId))
    );

    const portal =
      (_.head(
        _.filter(organizationPortals.portals, (_portal) =>
          _.find(_portal.portalOrganizations, { isHost: true })
        )
      ) as Portal) || organizationPortals.portals[0];

    return get(GetPortal(portal.id));
  },
});
export const OrganizationSearchParamsAtom = atom({
  key: 'OrganizationSearchParamsAtom',
  default: {
    limit: 30,
    pageNumber: 1,
  },
});

export const GetPortalOrganizations = selectorFamily<
  ApiSearchResult<Organization>,
  OrganizationSearchParams
>({
  key: 'GetPortalOrganizations',
  get:
    ({ pageNumber, limit }: OrganizationSearchParams) =>
    async ({ get }) => {
      const organizationPortals = get(
        GetOrganizationPortals(get(SelectedOrganizationId))
      );

      const portal =
        (_.head(
          _.filter(organizationPortals.portals, (_portal) =>
            _.find(_portal.portalOrganizations, { isHost: true })
          )
        ) as Portal) || organizationPortals.portals[0];

      const portalId = portal.id;
      const response = await mainApi.get<any>(
        `/portal/portals/${portalId}/organizations?limit=${limit}&page=${pageNumber}`
      );
      if (!response.ok) {
        handleError(response);
        return {
          items: [],
          total: 0,
        };
      }
      return {
        items: response.data.items,
        total: response.data.total,
      };
    },
});

export const GetFullPortal = selectorFamily<
  Portal,
  { portalId: string; organizationId: number }
>({
  key: 'GetFullPortal',
  get:
    ({
      portalId,
      organizationId,
    }: {
      portalId: string;
      organizationId: number;
    }) =>
    async () => {
      const response = await mainApi.get<any>(
        `/portal/portals/${portalId}?organizationId=${organizationId}`
      );

      if (!response.ok) {
        handleError(response);
        return null;
      }
      return response.data;
    },
});

export const GetPortal = selectorFamily<
  PortalOrganizationsAndCategories,
  string
>({
  key: 'GetPortal',
  get: (portalId) => async () => {
    if (!portalId) return undefined;

    const response = await mainApi.get<any>(
      `/portal/portals/v2/${portalId}/organizations-and-categories`
    );

    if (!response.ok) {
      handleError(response);
      return null;
    }
    return response.data;
  },
});

export const GetOrganizationPortals = selectorFamily<
  OrganizationPortals,
  number
>({
  key: 'GetOrganizationPortals',
  get: (organizationId) => async () => {
    const response = await mainApi.get<OrganizationPortals>(
      `/portal/portals/organization`,
      {
        organizationId,
      }
    );

    if (!response.ok) {
      handleError(response);
      return null;
    }
    return response.data;
  },
});

export const GetPortalCategories = selectorFamily<
  { items: PortalCategory[]; total: number },
  { type: PortalCategoryType }
>({
  key: 'GetPortalCategories',
  get:
    ({ type }) =>
    ({ get }) => {
      const currentPortal = get(GetCurrentPortal);
      let items: PortalCategory[] = [];
      if (type === PortalCategoryType.EVENT) {
        items = currentPortal.categories.filter(
          (category) => category.type === type || category.type === null
        );
      } else {
        items = currentPortal.categories.filter(
          (category) => category.type === type
        );
      }

      return {
        items,
        total: items.length,
      };
    },
});
