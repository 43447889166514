import { BackendEventVisibility } from '@/react/calendar/types/event';

export enum ITaxonomyTypes {
  absence = 'absence',
  blog = 'blog',
  event = 'event',
  availability = 'availability',
}

export enum Weekdays {
  Monday = 'monday',
  Tuesday = 'tuesday',
  Wednesday = 'wednesday',
  Thursday = 'thursday',
  Friday = 'friday',
  Saturday = 'saturday',
  Sunday = 'sunday',
}

export type BookingHours = Partial<
  Record<Weekdays, { start: string; end: string }[]>
>;

export interface BookingOption {
  id?: string;
  name?: string;
  description?: string;
  mainResourceId?: number;
  additionalResourceIds?: number[];
  config?: {
    duration?: number;
    minimumNoticePeriod?: number;
    startTimeIncrement?: number;
    bufferBeforeEvent?: number;
    bufferAfterEvent?: number;
    bookingHours: BookingHours;
  };
}

export interface BookingFormData {
  id: string;
  url: string;
}

export interface Taxonomy {
  id: number;
  type: ITaxonomyTypes;
  name: string;
  color: number;
  alias?: string;
  description: string;
  organizationId: number;
  registerAttendance: boolean;
  isMaster: boolean;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  groups?: { id: number }[];
  config?: {
    type: 'general-availability' | 'specific-availability';
    isBookable?: boolean;
    publicName: string;
    event?: {
      title: string;
      churchIds: number[];
      groupIds: number[];
      categoryIds: number[];
      visibility: BackendEventVisibility;
    };
    form?: BookingFormData;
  };
  bookingOptions?: BookingOption[];
}
