'use strict';

// Redux
import { FetchUserPrivilege } from '../../../react/user/redux/actions';
import { getUserPrivilegeSelector } from '../../../react/user/redux/selectors';

import { recoilRefresh } from '@/app/cdRecoilRefresher';
import { GetAllTags } from '@/react/people/store/tagState';

class PeopleTagListState {
  constructor(
    $state,
    $ngRedux,
    $scope,
    $uibModal,
    _,
    toastr,
    gettextCatalog,
    base64,
    People,
    PeopleTags,
    PeopleSegments
  ) {
    'ngInject';

    this.$state = $state;
    this.$uibModal = $uibModal;
    this._ = _;
    this.toastr = toastr;
    this.gettextCatalog = gettextCatalog;
    this.base64 = base64;
    this.People = People;
    this.PeopleTags = PeopleTags;
    this.PeopleSegments = PeopleSegments;

    this.sorting = {
      field: 'name',
      reverse: false,
    };

    // Attach lists that relate to each tag
    this._.each(this.tags, (tag) => {
      tag.lists = this._.filter(this.lists, (list) =>
        this._.some(list.setup.filters, { property: 'tags', value: tag.id })
      );
    });

    const unsubscribe = $ngRedux.connect(
      this.mapStateToScope,
      this.mapDispatchToScope
    )(this);
    $scope.$on('$destroy', unsubscribe);
  }

  $onInit() {
    this.FetchUserPrivilege({
      permission: 'organization.people_segment.edit',
    });
    this.FetchUserPrivilege({
      permission: 'organization.actions.managePeopleTags',
    });
    this.FetchUserPrivilege({
      permission: 'organization.actions.deletePeopleTags',
    });
    this.tags = this.PeopleTags.query();
    this.lists = this.PeopleSegments.query();
  }

  // AngularJS <-> Redux mapping functions
  mapStateToScope = (state) => ({
    canCreateLists: getUserPrivilegeSelector(
      state,
      'organization.people_segment.edit'
    ),
    canCreateTags: getUserPrivilegeSelector(
      state,
      'organization.actions.managePeopleTags'
    ),
    canEditTags: getUserPrivilegeSelector(
      state,
      'organization.actions.managePeopleTags'
    ),
    canDeleteTags: getUserPrivilegeSelector(
      state,
      'organization.actions.deletePeopleTags'
    ),
  });

  mapDispatchToScope = (dispatch) => ({
    FetchUserPrivilege: (permission) =>
      dispatch(FetchUserPrivilege(permission)),
  });

  /**
   * Go to the people list and filter by tag
   *
   * @param {any} tag
   * @memberof PeopleTagListState
   */
  filterPeopleByTag(tag) {
    const filterCriteria = [
      {
        type: 'list',
        property: 'tags',
        operator: 'eq',
        value: tag.id,
      },
    ];

    this.$state.go('app.private.people.contacts.list', {
      filter: this.base64.urlencode(angular.toJson(filterCriteria)),
    });
  }

  /**
   * Create multiple tags at a time
   *
   * @memberof PeopleTagListState
   */
  createTags() {
    this.$uibModal
      .open({
        component: 'cdCreatePeopleTag',
        windowClass: 'modal-ui-select',
        resolve: {
          tagList: () => this.tags,
          multiple: () => true,
        },
      })
      .result.then((newTags) => {
        this._.each(newTags, (tag) => {
          this.tags.push(tag);
        });
        recoilRefresh(GetAllTags(''));
      });
  }

  /**
   * Edit a tag
   *
   * @param {Object} tag - The tag to be updated
   * @memberof PeopleTagListState
   */
  editTag(tag) {
    this.$uibModal
      .open({
        templateUrl: '@/app/people/tag-list/templates/edit-tag-modal.html',
        resolve: {
          tagObject: () => angular.copy(tag),
        },

        controller: [
          '$uibModalInstance',
          'tagObject',
          'toastr',
          'gettextCatalog',
          function ($uibModalInstance, tagObject, toastr, gettextCatalog) {
            'ngInject';

            this.tag = tagObject;

            this.save = () => {
              this.tag.$save(() => {
                toastr.success(
                  gettextCatalog.getString('Tag updated successfully.')
                );
                recoilRefresh(GetAllTags(''));
                $uibModalInstance.close(this.tag);
              });
            };

            this.dismiss = $uibModalInstance.dismiss;
          },
        ],
        controllerAs: '$ctrl',
      })
      .result.then((updatedTag) => {
        this._.extend(
          this._.find(this.tags, { id: updatedTag.id }),
          updatedTag
        );
      });
  }

  /**
   * Delete a tag
   *
   * @param {Object} tag - The tag to be deleted
   * @memberof PeopleTagListState
   */
  deleteTag(tag) {
    this.$uibModal
      .open({
        component: 'cdSimpleModal',
        resolve: {
          title: () => this.gettextCatalog.getString('Delete tag'),
          body: () =>
            this.gettextCatalog.getString(
              'Deleting a tag doesn’t delete the tagged contacts but you’ll no longer be able to search for all contacts with this tag.'
            ),

          options: {
            confirmButtonText: this.gettextCatalog.getString('Delete'),
            closeButtonText: this.gettextCatalog.getString('Cancel'),
            confirmButtonType: 'danger',
          },
        },
      })
      .result.then(() => {
        tag.$delete(
          () => {
            this.toastr.success(
              this.gettextCatalog.getString('Tag deleted successfully.')
            );

            this._.remove(this.tags, tag);
            recoilRefresh(GetAllTags(''));
          },
          (error) => {
            if (error.status !== 409) return;

            this.$uibModal.open({
              templateUrl:
                '@/app/people/tag-list/templates/delete-tag-conflict-modal.html',
              resolve: {
                errorData: () => angular.copy(error.data),
              },

              controller: [
                '$uibModalInstance',
                'errorData',
                function ($uibModalInstance, errorData) {
                  'ngInject';

                  this.errorData = errorData;
                  this.forms = errorData.error || errorData.errors;
                  this.dismiss = $uibModalInstance.dismiss;
                },
              ],
              controllerAs: '$ctrl',
            });
          }
        );
      });
  }

  /**
   * Update the sorting order of a column
   *
   * @param {String} field - The field being sorted
   * @memberof PeopleTagListState
   */
  setSorting(field) {
    if (this.sorting.field === field) {
      this.sorting.reverse = !this.sorting.reverse;
    } else {
      this.sorting.field = field;
      this.sorting.reverse = false;
    }
  }

  /**
   * Return the appropriate sort class for a table column
   *
   * @param {String} field - The field being sorted
   * @memberof PeopleTagListState
   */
  getSortingDirectionClass(field) {
    if (this.sorting.field === field) {
      return this.sorting.reverse
        ? 'fa fa-sort-down fa-fw'
        : 'fa fa-sort-up fa-fw';
    }
  }

  /**
   * Create a new list based on a tag
   *
   * @param {Object} tag - The tag to create the list from
   * @memberof PeopleTagListState
   */
  createAsList(tag) {
    const { People } = this;
    const allList = this._.find(this.lists, { filteringType: 'organization' });
    const filterQuery = {
      comparison: 'AND',
      churchIds: allList?.setup.churchIds,
      filters: [
        {
          type: 'list',
          property: 'tags',
          operator: 'eq',
          value: tag.id,
        },
      ],
    };

    this.$uibModal
      .open({
        component: 'cdPeopleCreateListModal',
        resolve: {
          filterQuery: () => filterQuery,
          filterGroups: () => People.getFilters().$promise,
        },
      })
      .result.then((newList) => {
        this.$state.go('app.private.people.contacts.list', {
          list: newList.id,
        });
      });
  }
}
PeopleTagListState.$inject = [
  '$state',
  '$ngRedux',
  '$scope',
  '$uibModal',
  '_',
  'toastr',
  'gettextCatalog',
  'base64',
  'People',
  'PeopleTags',
  'PeopleSegments',
];

angular.module('cdApp.people').component('cdPeopleTagListState', {
  templateUrl: '@/app/people/tag-list/tag-list.component.html',
  controller: PeopleTagListState,
});
