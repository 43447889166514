import { Col, Row } from 'antd';
import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';

import { calendarSettingsSelectors } from '../store/calendar-settings/calendarSettingsSlice';

import AbsenceFilters from './AbsenceFilters';
import CreateCalendarSubscriptionModal from './CreateCalendarSubscriptionModal';
import MyCalenders from './MyCalenders';
import { ParishCalendars } from './ParishCalendars';
import SelectDatePicker from './SelectDatePicker';
import SidebarStyles from './SideBarStyle';
import UsersAndRoomsCalendars from './UsersAndRoomsCalendars';
import { AvailabilityCalendarFilters } from './filters/AvailabilityCalendarFilters';

export const SidebarContent: FunctionComponent = () => {
  const isBookingSystemEnabled = useSelector(
    calendarSettingsSelectors.getIsBookingSystemEnabled
  );

  return (
    <SidebarStyles>
      <Col style={{ width: '100%' }}>
        <Row>
          <SelectDatePicker />
        </Row>

        <Row>
          <Col style={{ width: '100%' }}>
            <MyCalenders />
            <UsersAndRoomsCalendars />
            <AbsenceFilters />
            {isBookingSystemEnabled && <AvailabilityCalendarFilters />}
            <ParishCalendars />
          </Col>
        </Row>
      </Col>
      <CreateCalendarSubscriptionModal />
    </SidebarStyles>
  );
};
