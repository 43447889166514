import { gettextCatalog } from '../../services/I18nService';
import { EventType } from '../models/calendar';

export const translateEventType = (type: EventType) => {
  const names = {
    [EventType.Event]: gettextCatalog.getString('Event'),
    [EventType.Absence]: gettextCatalog.getString('Absence'),
    [EventType.Availability]: gettextCatalog.getString('Availability'),
    [EventType.Collection]: gettextCatalog.getString('Collection'),
    [EventType.External]: gettextCatalog.getString('Event'),
    [EventType.Feed]: gettextCatalog.getString('Feed'),
  } satisfies Record<EventType, string>;

  return names[type];
};
