import React from 'react';
import { Space } from 'antd';

import { gettextCatalog } from '../../services/I18nService';
import { useOrganizationUsers } from '../hooks/useOrganizationUsers';

import {
  CdSelect,
  CdSelectProps,
} from '@/react/shared/components/cd-select/CdSelect';
import { CdUserAvatar } from '@/react/user/components/cd-user-avatar/CdUserAvatar';

interface CdUserSelectProps
  extends Omit<CdSelectProps, 'options' | 'placeholder'> {
  omitIds?: number[];
  includeIds?: number[];
}

export const CdUserSelect = (props: CdUserSelectProps) => {
  const { users, isLoading } = useOrganizationUsers();

  return (
    <CdSelect
      placeholder={gettextCatalog.getString('Choose a user.')}
      options={users
        .filter((user) => user.status === 1)
        .filter(
          (user) =>
            !props.includeIds ||
            props.includeIds?.length === 0 ||
            props.includeIds?.includes(user.id)
        )
        .filter((user) => !props.omitIds?.includes(user.id))
        .map((user) => ({
          label: (
            <Space>
              <CdUserAvatar picture={user.picture} size={30} />
              {user.name || user.email}
            </Space>
          ),
          value: user.id,
          name: user.name,
          email: user.email,
        }))}
      filterOption={(input, option) =>
        option?.name?.toLowerCase().includes(input.toLowerCase()) ||
        option?.email?.toLowerCase().includes(input.toLowerCase())
      }
      filterSort={(a, b) =>
        (a.name || a.email).localeCompare(b.name || b.email)
      }
      disabled={isLoading}
      {...props}
    />
  );
};
