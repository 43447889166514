import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import {
  useToggleOpenedAbsencePopover,
  AbsenceSelectors,
} from '../../store/absences/absencesSlice';

export const useCalendarPopover = (popoverIndex?: string) => {
  const setIsToolTipVisible = useToggleOpenedAbsencePopover();
  const isToolTipVisible = useSelector(
    AbsenceSelectors.isGroupedAbsenceOpened(popoverIndex)
  );

  const openTooltip = useCallback(
    (e) => {
      e?.preventDefault();
      e?.stopPropagation();
      setIsToolTipVisible(popoverIndex);
    },
    [setIsToolTipVisible, popoverIndex]
  );

  const closeToolTip = useCallback(
    (e?) => {
      e?.preventDefault();
      e?.stopPropagation();
      setIsToolTipVisible(false);
    },
    [setIsToolTipVisible]
  );

  const stopPropagation = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  return {
    isToolTipVisible,
    openTooltip,
    closeToolTip,
    stopPropagation,
  };
};
