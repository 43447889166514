import { useModal } from '@ebay/nice-modal-react';
import { useCallback, useState } from 'react';

export const useCdModal = <
  T extends {
    onOk?: () => Promise<Result> | Result;
    onCancel?: () => unknown;
    handleAlternativeButtonClick?: () => unknown;
  },
  Result,
>(
  initialValues: T
) => {
  const modal = useModal();
  const [modalProps, _setModalProps] = useState<T>(initialValues);

  const setModalProps = useCallback((newValues) => {
    if (typeof newValues === 'function') {
      _setModalProps((prevValues) => ({
        ...prevValues,
        ...newValues(prevValues),
      }));
    } else {
      _setModalProps((prevValues) => ({ ...prevValues, ...newValues }));
    }
  }, []);

  const [disabled, setDisable] = useState<boolean>(false);
  const [crashed, setCrashed] = useState<boolean>(false);

  const onOk = useCallback(async () => {
    try {
      setDisable(true);
      const result: { preventClose?: boolean } = await modalProps.onOk?.();
      if (result?.preventClose) {
        setDisable(false);
      } else {
        modal.resolve({ resolved: true });
        modal.hide();
      }
    } catch (error) {
      setDisable(false);
    }
  }, [modal, modalProps]);

  const onCancel = useCallback(async () => {
    try {
      setDisable(true);
      // This takes care of nested modals, where the parent modal should not close if the child modal is not resolved
      const result: { preventClose?: boolean } = await modalProps.onCancel?.();
      if (result?.preventClose) {
        setDisable(false);
      } else {
        modal.resolve({ resolved: false });
        modal.hide();
      }
    } catch (error) {
      setDisable(false);
    }
  }, [modal, modalProps]);

  const handleExtraElementClick = useCallback(async () => {
    try {
      setDisable(true);
      const result: { preventClose?: boolean } =
        await modalProps.handleAlternativeButtonClick?.();
      if (result?.preventClose) {
        setDisable(false);
      } else {
        modal.resolve({ resolved: false });
        modal.hide();
      }
    } catch (error) {
      setDisable(false);
    }
  }, [modal, modalProps]);

  return {
    modal,
    disabled,
    crashed,
    modalProps,
    setCrashed,
    onOk,
    onCancel,
    handleExtraElementClick,
    setModalProps,
  };
};
