import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Skeleton } from 'antd';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { gettextCatalog } from '../../services/I18nService';
import {
  useGetMyAbsenceGroups,
  useGetViewAbsenceGroups,
  useIsAllGroupsSelected,
} from '../store/absences/absencesSlice';
import { selectIsUserFiltersLoaded } from '../store/filters/Selectors';
import { selectIsInResourceView } from '../store/main-view/Selectors';
import { CdCalendarFilterCollapse } from '../containers/calendar-filter/CdCalendarFilterCollapse';

import AbsenceItem from './AbsenceItem';
import FilterList from './FilterList';
import { FilterSectionHeader } from './FilterSectionHeader';

import { CdTooltip } from '@/react/shared/components/cd-tooltip/CdTooltip';

const AbsenceFilters = () => {
  const isInResourceView = useSelector(selectIsInResourceView);
  const isUserFiltersLoaded = useSelector(selectIsUserFiltersLoaded);

  const myAbsenceGroups = useGetMyAbsenceGroups();
  const viewAbsenceGroups = useGetViewAbsenceGroups();

  const otherGroups = viewAbsenceGroups?.filter(
    (group) => !myAbsenceGroups?.map((group) => group.id).includes(group.id)
  );

  const [areAllGroupsSelected, toggleAllGroups] = useIsAllGroupsSelected();
  const allAbsenceOnClick = useCallback(
    () => !isInResourceView && toggleAllGroups(undefined),
    [isInResourceView, toggleAllGroups]
  );
  return (
    <CdCalendarFilterCollapse
      $transparentBackground
      items={[
        {
          key: 'groupSelectAll',
          label: isUserFiltersLoaded ? (
            <FilterSectionHeader
              name={gettextCatalog.getString('All absences')}
              isAllSelected={areAllGroupsSelected as boolean}
              onClick={allAbsenceOnClick}
              isDisabled={isInResourceView}
              type="absence"
            />
          ) : (
            <div style={{ padding: '0 16px' }}>
              <Skeleton
                title={{ width: '80%' }}
                paragraph={false}
                loading
                active
              />
            </div>
          ),
          children: isUserFiltersLoaded ? (
            <Col>
              {myAbsenceGroups && myAbsenceGroups.length > 0 ? (
                <Col>
                  <label
                    style={{
                      padding: '12px 16px 0',
                      color: 'rgba(0, 0, 0, 0.85)',
                      fontWeight: 'bold',
                      fontSize: 12,
                    }}
                  >
                    {gettextCatalog.getString('My groups')}
                    <CdTooltip
                      title={gettextCatalog.getString(
                        'Show absence for members in this group'
                      )}
                    >
                      <FontAwesomeIcon
                        icon={faQuestionCircle}
                        className="u-ml-5"
                      />
                    </CdTooltip>
                  </label>
                  <FilterList>
                    {myAbsenceGroups.map((group) => (
                      <AbsenceItem
                        key={`group-${group.id}`}
                        groupId={group.id}
                        name={group.name}
                        isDisabled={isInResourceView}
                      />
                    ))}
                  </FilterList>
                </Col>
              ) : null}

              {otherGroups && otherGroups.length > 0 ? (
                <Col>
                  <label
                    style={{
                      padding: '12px 16px 0',
                      color: 'rgba(0, 0, 0, 0.85)',
                      fontWeight: 'bold',
                      fontSize: 12,
                    }}
                  >
                    {gettextCatalog.getString('Not a member of')}
                    <CdTooltip
                      title={gettextCatalog.getString(
                        'Show absence for members in this group'
                      )}
                    >
                      <FontAwesomeIcon
                        icon={faQuestionCircle}
                        className="u-ml-5"
                      />
                    </CdTooltip>
                  </label>
                  <FilterList>
                    {otherGroups.map((group) => (
                      <AbsenceItem
                        key={`group-${group.id}`}
                        groupId={group.id}
                        name={group.name}
                        isDisabled={isInResourceView}
                      />
                    ))}
                  </FilterList>
                </Col>
              ) : null}
            </Col>
          ) : (
            <div style={{ padding: '8px 16px' }}>
              <Skeleton
                title={false}
                paragraph={{ rows: 4, width: '100%' }}
                loading
                active
              />
            </div>
          ),
        },
      ]}
    ></CdCalendarFilterCollapse>
  );
};

export default AbsenceFilters;
