import { mainApi } from '../../../api';
import gettextCatalog from '../../../services/I18nService';

export const checkForDuplicateTaxonomyAlias = async (alias: string) => {
  const response = await mainApi.post<{ isAvailable: boolean }>(
    '/taxonomies/alias/check',
    { alias }
  );

  if (response.ok && !response.data.isAvailable) {
    throw new Error(
      gettextCatalog.getString(
        'This alias is already in use on another booking page.'
      )
    );
  }

  if (!response.ok) {
    throw new Error(
      gettextCatalog.getString('An error occurred while validating the alias.')
    );
  }
};
