(function () {
  'use strict';

  AbsenceController.$inject = [
    'moment',
    '$scope',
    '$stateParams',
    '$uibModal',
    '$http',
    '$location',
    '$state',
    'toastr',
    'gettextCatalog',
    'Revisions',
    'Users',
    'absenceData',
    'cdResourceColors',
    'cdRedactedValue',
  ];

  function AbsenceController(
    moment,
    $scope,
    $stateParams,
    $uibModal,
    $http,
    $location,
    $state,
    toastr,
    gettextCatalog,
    Revisions,
    Users,
    absenceData,
    cdResourceColors,
    cdRedactedValue
  ) {
    // =============================================================================
    // General stuff.
    // =============================================================================

    $scope.cdRedactedValue = cdRedactedValue;
    $scope.revision = !_.isUndefined($stateParams.rid) ? absenceData : null;
    $scope.absence = _.isUndefined($stateParams.rid)
      ? absenceData
      : absenceData.data;

    // Fetch revisions only if users has required roles.
    if ($scope.absence.canEdit) {
      fetchRevisions();
    }

    $scope.isTaxonomiesAvailable = (taxonomies) => _.size(taxonomies) > 0;

    // Display the date in different formats.
    $scope.displayDate = function (start, end, allDay, hideEnd) {
      // It is not all day.
      if (!allDay) {
        // End time is not hidden.
        if (!hideEnd) {
          // Difference is less than 24 hours, we only show end time.
          if (moment(end).diff(moment(start), 'hours') < 24) {
            return (
              moment(start).format('LLLL') +
              ' ' +
              gettextCatalog.getString('to', {}, 'Date interval') +
              ' ' +
              moment(end).format('LT')
            );
          } else {
            return (
              moment(start).format('LLLL') +
              ' ' +
              gettextCatalog.getString('to', {}, 'Date interval') +
              ' ' +
              moment(end).format('LLLL')
            );
          }
        } else {
          // End time is hidden.
          return moment(start).format('LLLL');
        }
      } else {
        // Difference is less than 24 hours, we show only start date.
        if (moment(end).diff(moment(start), 'hours') < 24) {
          return (
            moment(start).format('dddd, MMMM Do YYYY') +
            ' ' +
            gettextCatalog.getString('(all day)')
          );
        } else {
          return (
            moment(start).format('dddd, MMMM Do YYYY') +
            ' ' +
            gettextCatalog.getString('to', {}, 'Date interval') +
            ' ' +
            moment(end).format('dddd, MMMM Do YYYY')
          );
        }
      }
    };

    // Print calendar
    $scope.print = function () {
      window.print();
    };

    // Generate a date from timestamp.
    $scope.Date = function (number) {
      return moment(number).format('LLLL');
    };

    // Time ago.
    $scope.timeAgo = function (number) {
      return moment(number).fromNow();
    };

    // When the email/sms was sent.
    $scope.sentOn = function (date) {
      return gettextCatalog.getString('Sent on ') + moment(date).format('LLL');
    };

    // Limit notifications to only 5 items to begin with.
    $scope.limit = 5;

    // Load more notification logs per user request.
    $scope.loadMore = function () {
      $scope.limit += 5;
    };

    // Static absence type colors.
    $scope.colors = cdResourceColors;

    // =============================================================================
    // Revisions of the current absence.
    // =============================================================================

    $scope.isRevision = !_.isUndefined($stateParams.rid);

    // Fetch revisions for the current absence.
    function fetchRevisions() {
      Revisions.query(
        {
          entityType: 'absence',
          entityId: $stateParams.id,
        },

        function (response) {
          $scope.revisions = response;

          Users.query(function (users) {
            _.each($scope.revisions, function (revision, index) {
              $scope.revisions[index].revisionAuthorName = _.result(
                _.find(users, { id: revision.revisionAuthorId }),
                'name'
              );
            });
          });
        }
      );
    }

    // Revert to another version.
    $scope.revert = function (revision) {
      $uibModal
        .open({
          templateUrl: 'confirmAbsenceRevert.html',
          scope: $scope,
          controller: [
            '$scope',
            '$uibModalInstance',
            function ($scope, $uibModalInstance) {
              'ngInject';

              $scope.revision = revision;

              $scope.save = function () {
                let url =
                  cdApp.config.api.main +
                  '/revisions/absence/' +
                  $stateParams.id +
                  '/revert/' +
                  revision.id;

                $http.post(url).then(
                  function () {
                    toastr.success(
                      gettextCatalog.getString(
                        '"{{title}}" has been reverted to another version.',
                        {
                          title: $scope.absence.title,
                        }
                      )
                    );

                    $uibModalInstance.close();
                  },
                  function () {
                    toastr.error(
                      gettextCatalog.getString(
                        'Something went wrong with the revert.'
                      )
                    );
                  }
                );
              };

              $scope.cancel = function () {
                $uibModalInstance.dismiss('cancel');
              };
            },
          ],
        })
        .result.then(function () {
          $state.reload();
        });
    };

    // =============================================================================
    // Modals.
    // =============================================================================

    // Delete an absence.
    $scope.delete = function () {
      $uibModal
        .open({
          component: 'cdEventDeleteModal',
          resolve: {
            event: () => $scope.absence,
          },
        })
        .result.then(() => {
          $location.path('calendar');
        });
    };

    // Export absence.
    $scope.export = function (type, id) {
      $uibModal.open({
        templateUrl:
          '@/app/calendar/full-calendar/templates/modals/event-export.modal.html',
        resolve: {
          type: function () {
            return type;
          },
          id: function () {
            return id;
          },
          // Get the token from the server, to be able to generate the output urls for the ical.
          token: [
            '$q',
            function ($q) {
              let deferred = $q.defer();
              $http
                .get(cdApp.config.api.main + '/ical/token')
                .success(function (token) {
                  if (!token) {
                    // There is some problems in the server side.
                    toastr.error(
                      gettextCatalog.getString(
                        'Something went wrong while exporting your calendar.'
                      )
                    );

                    deferred.reject();
                  }
                  deferred.resolve(token);
                })
                .error(function (err) {
                  deferred.reject(err);
                });
              return deferred.promise;
            },
          ],
        },

        controller: [
          '$scope',
          '$uibModalInstance',
          '$window',
          'type',
          'id',
          'token',
          function ($scope, $uibModalInstance, $window, type, id, token) {
            'ngInject';

            $scope.type = type;
            $scope.id = id;

            // Function to generate the export functionality url.
            $scope.generateExportUrl = function (isPrivate, clean) {
              let parts = ['/ical'];
              // Attach the type to the url.
              if ($scope.type) {
                parts.push($scope.type);
              }
              // Attach the id of type to the url.
              if ($scope.id) {
                parts.push($scope.id);
              }
              // Generate the output url.
              let url = cdApp.config.api.main + parts.join('/');
              // Check if we are generating the url for the private or public feed.
              if (isPrivate) {
                // Attach the token to the end of url.
                url = url + '?token=' + token;
              } else {
                // Just public url.
                url =
                  url + '?organizationId=' + $window.churchdeskOrganizationId;
              }
              if (clean) {
                url = url.replace('https://', '').replace('http://', '');
              }
              return url;
            };

            $scope.close = function () {
              $uibModalInstance.dismiss('cancel');
            };
          },
        ],
      });
    };

    // Sometimes double clicking an event in the calendar triggers
    // the qtip as well, so this just makes sure the qtip is hidden
    // once the event is fully rendered.
    $(function () {
      $('.qtip').qtip('hide');
    });
  }

  angular
    .module('cdApp.calendar')
    .controller('AbsenceController', AbsenceController);
})();
