import { Form } from 'antd';

import { useResourcesQuery } from '../store/resourceHooks';
import { AvailabilityTaxonomyFormData } from '../types/availability-taxonomy.types';

import gettextCatalog from '@/react/services/I18nService';

// both array defined as functions so that `gettextCatalog` is called during render when we have translations
// and not during app load when they are not present
const START_INCREMENT_OPTIONS = () => [
  { label: gettextCatalog.getString('15 minutes'), value: 15 },
  { label: gettextCatalog.getString('30 minutes'), value: 30 },
  { label: gettextCatalog.getString('1 hour'), value: 60 },
];

export const useOptionFormData = (optionIndex: number) => {
  // eslint-disable-next-line destructuring/no-rename
  const { data: resources } = useResourcesQuery();
  const form = Form.useFormInstance<AvailabilityTaxonomyFormData>();
  const allowWeeklySchedule = Form.useWatch(
    ['bookingOptions', optionIndex, 'allowWeeklySchedule'],
    form
  );

  return {
    locations: resources ?? [],
    allowWeeklySchedule,
    startIncrementOptions: START_INCREMENT_OPTIONS(),
  };
};
