import type { SagaIterator } from '@redux-saga/types';
import { all, call, fork, put, takeLatest, throttle } from 'redux-saga/effects';

import { REFRESH_MAIN_DATA_REQUEST } from '../../config/store/Actions';
import { withLoadingAndErrors } from '../../shared/loading/saga';
import { FETCH_USERS_SUCCESS } from '../../user/redux/actions';
import absenceSaga from '../store/absences/absenceSaga';
import { calendarSettingsActions } from '../store/calendar-settings/calendarSettingsSlice';
import { fullCalendarEventsRootSaga } from '../store/events/eventsSaga';
import { fullCalendarActions } from '../store/events/fullCalendarEventsSlice';
import {
  ADD_CALENDAR,
  ADD_CATEGORY,
  FETCH_CALENDAR_FILTER_DATA,
  REMOVE_CALENDAR,
  REMOVE_CATEGORY,
  SELECT_ALL_CATEGORIES,
  TOGGLE_ABSENCE_VIEW,
  TOGGLE_ALL_MY_CALENDARS,
  TOGGLE_ALL_PARISHES,
  TOGGLE_CALENDAR,
  TOGGLE_CALENDAR_SUBSCRIPTION,
  TOGGLE_CATEGORY,
  TOGGLE_ME_FILTER,
  TOGGLE_SELECT_ALL_CATEGORIES,
  TOGGLE_USERS_AND_ROOMS_CALENDERS,
  UNSELECT_ALL_CATEGORIES,
} from '../store/filters/Actions';
import {
  ADD_EVENT,
  CALENDAR_SUBSCRIPTION_CREATE,
  CALENDAR_SUBSCRIPTION_DELETE,
  CALENDAR_SUBSCRIPTION_UPDATE,
  CALENDAR_SUBSCRIPTION_FORCE_SYNC,
  CHANGE_TIME_PERIOD,
  DELETE_EVENT,
  EDIT_EVENT,
  GO_TO_DATE,
  RESIZE_EVENT,
  SET_CALENDAR_EVENT_COLORING_CRITERIA,
  TOGGLE_SHOW_INTENTIONS,
  TOGGLE_SHOW_LITURGICAL_HOLIDAYS,
  TOGGLE_SHOW_NATIONAL_HOLIDAYS,
  TOGGLE_SHOW_PREP_TIME,
  TOGGLE_SHOW_RESOURCES,
  TOGGLE_SHOW_COLLECTIONS,
  TOGGLE_SHOW_DECLINED_EVENTS,
} from '../store/main-view/Actions';
import { CdAction } from '../../redux/utils/index';
import { TimePeriod } from '../store/main-view/index';
import CalendarViewService from '../services/CalendarViewService';
import { availabilitiesSlice } from '../store/availabilities/availabilitiesSlice';

import {
  fireLoadAction,
  reLoadAndUpdateFilterData,
  withUpdateFilterData,
} from './utils';
import {
  calculatedUnselectedCalendars,
  createCalendarSubscription,
  deleteCalendarSubscription,
  DeleteEventSaga,
  EditEventSaga,
  eventAdded,
  fetchCalendarFilterData,
  fetchCalendarFilterDataAndRefresh,
  saveCalendarData,
  toggleSelectAll,
  updateCalendarSubscription,
  forceResyncCalendarSubscription,
  updateEvent,
} from './CalendarControlSagas';

function* refreshRenderEventsSaga() {
  yield put(fullCalendarActions.refreshEventsToRender());
}

function* calculateUnselectedCalendarsAndReload() {
  yield call(calculatedUnselectedCalendars);
  yield call(fireLoadAction);
}

function* changeTimePeriod({ payload }: CdAction<TimePeriod>) {
  switch (payload) {
    case TimePeriod.Week:
      yield call(CalendarViewService.changeView, 'timeGridWeek');
      break;
    case TimePeriod.Month:
      yield call(CalendarViewService.changeView, 'dayGridMonth');
      break;
    default:
      break;
  }
  yield call(reLoadAndUpdateFilterData);
}

export default function* root(): SagaIterator {
  yield all([
    takeLatest(RESIZE_EVENT, updateEvent),
    takeLatest(TOGGLE_ME_FILTER, reLoadAndUpdateFilterData),
    takeLatest(ADD_CATEGORY, reLoadAndUpdateFilterData),
    takeLatest(REMOVE_CATEGORY, reLoadAndUpdateFilterData),
    takeLatest(SELECT_ALL_CATEGORIES, reLoadAndUpdateFilterData),
    takeLatest(UNSELECT_ALL_CATEGORIES, reLoadAndUpdateFilterData),
    takeLatest(
      ADD_CALENDAR,
      withUpdateFilterData(calculateUnselectedCalendarsAndReload)
    ),
    takeLatest(
      REMOVE_CALENDAR,
      withUpdateFilterData(calculateUnselectedCalendarsAndReload)
    ),
    takeLatest(
      TOGGLE_CALENDAR,
      withUpdateFilterData(calculateUnselectedCalendarsAndReload)
    ),
    takeLatest(TOGGLE_CATEGORY, reLoadAndUpdateFilterData),
    takeLatest(
      TOGGLE_SELECT_ALL_CATEGORIES,
      withUpdateFilterData(toggleSelectAll)
    ),
    takeLatest(
      FETCH_CALENDAR_FILTER_DATA,
      withLoadingAndErrors(
        FETCH_CALENDAR_FILTER_DATA,
        fetchCalendarFilterDataAndRefresh
      )
    ),
    takeLatest(TOGGLE_ABSENCE_VIEW, fireLoadAction),
    takeLatest(EDIT_EVENT, EditEventSaga),
    takeLatest(DELETE_EVENT, DeleteEventSaga),
    takeLatest(
      SET_CALENDAR_EVENT_COLORING_CRITERIA,
      withUpdateFilterData(refreshRenderEventsSaga)
    ),
    takeLatest(
      TOGGLE_CALENDAR_SUBSCRIPTION,
      withUpdateFilterData(fireLoadAction)
    ),
    takeLatest(
      CALENDAR_SUBSCRIPTION_DELETE,
      withUpdateFilterData(deleteCalendarSubscription)
    ),
    takeLatest(
      CALENDAR_SUBSCRIPTION_CREATE,
      withUpdateFilterData(createCalendarSubscription)
    ),
    takeLatest(CALENDAR_SUBSCRIPTION_UPDATE, updateCalendarSubscription),
    takeLatest(
      CALENDAR_SUBSCRIPTION_FORCE_SYNC,
      forceResyncCalendarSubscription
    ),
    takeLatest(
      calendarSettingsActions.toggleCalendarView.type,
      withUpdateFilterData()
    ),
    takeLatest(
      REFRESH_MAIN_DATA_REQUEST,
      withLoadingAndErrors(
        REFRESH_MAIN_DATA_REQUEST,
        fetchCalendarFilterDataAndRefresh
      )
    ),
    takeLatest(CHANGE_TIME_PERIOD, changeTimePeriod),
    takeLatest(
      TOGGLE_SHOW_RESOURCES,
      withUpdateFilterData(refreshRenderEventsSaga)
    ),
    takeLatest(
      TOGGLE_SHOW_PREP_TIME,
      withUpdateFilterData(refreshRenderEventsSaga)
    ),
    takeLatest(
      TOGGLE_SHOW_INTENTIONS,
      withUpdateFilterData(refreshRenderEventsSaga)
    ),
    takeLatest(TOGGLE_SHOW_DECLINED_EVENTS, reLoadAndUpdateFilterData),
    takeLatest(TOGGLE_SHOW_NATIONAL_HOLIDAYS, withUpdateFilterData()),
    takeLatest(TOGGLE_SHOW_LITURGICAL_HOLIDAYS, withUpdateFilterData()),
    takeLatest(TOGGLE_SHOW_COLLECTIONS, withUpdateFilterData()),
    takeLatest(ADD_EVENT, withLoadingAndErrors(ADD_EVENT, eventAdded)),
    takeLatest(GO_TO_DATE, withUpdateFilterData()),
    takeLatest(TOGGLE_ALL_MY_CALENDARS, reLoadAndUpdateFilterData),
    takeLatest(TOGGLE_USERS_AND_ROOMS_CALENDERS, reLoadAndUpdateFilterData),
    takeLatest(TOGGLE_ALL_PARISHES, reLoadAndUpdateFilterData),
    takeLatest(
      FETCH_USERS_SUCCESS,
      withUpdateFilterData(fetchCalendarFilterData)
    ),
    takeLatest(
      availabilitiesSlice.actions.setState.type,
      reLoadAndUpdateFilterData
    ),
    throttle(3000, 'onSaveCalendarData', saveCalendarData),
    fork(absenceSaga),
    fork(fullCalendarEventsRootSaga),
  ]);
}
