import React from 'react';
import { Skeleton, Col } from 'antd';
import { compact } from 'lodash';

import { CdCalendarFilterCollapse } from '../../containers/calendar-filter/CdCalendarFilterCollapse';
import { FilterSectionHeader } from '../FilterSectionHeader';
import FilterList from '../FilterList';
import gettextCatalog from '../../../services/I18nService';
import { CdTooltip } from '../../../shared/components/cd-tooltip/CdTooltip';
import { getClassName } from '../AbsenceItem';
import { FilterListItem } from '../FilterItem';

export interface FilterSectionProps<
  T extends { id: number; name: string } = any,
> {
  title: string;
  isLoading: boolean;
  isDisabled: boolean;
  options: T[];
  value?: Record<number, boolean>;
  onChange: (values: Record<number, boolean>) => void;
}

export const FilterSection = (props: FilterSectionProps) => {
  const isAllSelected =
    props.options &&
    compact(Object.values(props.value)).length === props.options?.length;
  const isSomeSelected = compact(Object.values(props.value)).length > 0;

  const toggleOne = (id: number, check: boolean) =>
    props.onChange({
      ...props.value,
      [id]: check,
    });

  const onSelectAll = () =>
    props.onChange(
      isAllSelected
        ? {}
        : props.options
            .map((option) => option.id)
            .reduce((acc, id) => ({ ...acc, [id]: true }), {})
    );

  return (
    <CdCalendarFilterCollapse
      $transparentBackground
      items={[
        {
          key: 'selectAll',
          label: !props.isLoading ? (
            <FilterSectionHeader
              name={props.title}
              isAllSelected={
                isSomeSelected && !isAllSelected ? null : isAllSelected
              }
              onClick={onSelectAll}
              isDisabled={props.isDisabled}
              type="availability"
            />
          ) : (
            <Skeleton
              title={false}
              paragraph={{ rows: 1, width: '100%' }}
              loading
              active
              style={{ padding: '0 16px' }}
            />
          ),
          children: !props.isLoading ? (
            <Col>
              {props.options?.length > 0 && (
                <Col>
                  <FilterList>
                    {props.options.map((option) => (
                      <CdTooltip
                        key={option.id}
                        title={
                          props.isDisabled &&
                          gettextCatalog.getString(
                            'This calendar cannot be enabled for this view'
                          )
                        }
                      >
                        <FilterListItem
                          onClick={(event) => {
                            event.stopPropagation();
                            event.preventDefault();
                          }}
                          isDisabled={props.isDisabled}
                        >
                          <div className="bullet-wrapper">
                            <div
                              className="bullet-label"
                              onClick={(event) => {
                                event.stopPropagation();
                                event.preventDefault();
                                toggleOne(option.id, !props.value[option.id]);
                              }}
                            >
                              <span
                                className={getClassName(
                                  props.value[option.id],
                                  999
                                )}
                              />
                              <div className="label">
                                <span className="name">{option.name}</span>
                              </div>
                            </div>
                          </div>
                        </FilterListItem>
                      </CdTooltip>
                    ))}
                  </FilterList>
                </Col>
              )}
            </Col>
          ) : (
            <Skeleton
              title={false}
              paragraph={{ rows: 4, width: '100%' }}
              loading
              active
              style={{ padding: '8px 16px' }}
            />
          ),
        },
      ]}
    />
  );
};
