import { useMemo } from 'react';

import { useGroups } from '@/react/organization/store/organization';

export const useAvailaiblityFormDetails = () => {
  const { groups } = useGroups();

  const groupOptions = useMemo(
    () =>
      (groups ?? []).map((group) => ({
        label: group.name,
        value: group.id,
      })),
    [groups]
  );

  return {
    groupOptions,
  };
};
