import { useQuery, useQueryClient } from '@tanstack/react-query';

import { mainApi } from '../../api';
import { CategoryType } from '../../shared/models/category';
import { Taxonomy } from '../../taxonomies/services/Taxonomies';
import NotificationService from '../../services/NotificationService';
import gettextCatalog from '../../services/I18nService';

export const useTaxonomies = <T extends Taxonomy>(type: CategoryType) => {
  const queryClient = useQueryClient();
  const query = useQuery({
    queryKey: ['useTaxonomies', type],
    queryFn: async () => {
      const response = await mainApi.get<T[]>(`taxonomies?type=${type}`);
      if (response.ok) {
        return response.data || [];
      }
      throw response;
    },
  });

  const refreshTaxonomies = () => {
    queryClient.invalidateQueries({
      queryKey: ['useTaxonomies'],
    });
  };

  return {
    taxonomies: query.data,
    refreshTaxonomies,
    isLoading: query.isLoading,
  };
};

export const useDeleteTaxonomy = () => {
  const queryClient = useQueryClient();
  const deleteTaxonomy = async (id: number) => {
    await mainApi.delete(`taxonomies/${id}`);
    await queryClient.invalidateQueries({
      queryKey: ['useTaxonomies'],
    });
    NotificationService.notifySuccess(
      gettextCatalog.getString('Booking page deleted')
    );
  };

  return { deleteTaxonomy };
};
