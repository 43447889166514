import moment from 'moment';
import { DateSelectArg } from '@fullcalendar/core';
import { get } from 'lodash';

import { FilterType } from '../store/filters';
import { CreateEventData } from '../services/EventPopupService';

export const dateSelectArgToEventData = (args: DateSelectArg) => {
  if (!args) return null;
  const { start, end, allDay } = args;

  const data: CreateEventData = {
    type: 'event',
    showAbsence: true,
    startDate: allDay
      ? moment(start).startOf('day').toISOString()
      : moment(start).toISOString(),
    endDate: allDay
      ? moment(end).subtract(1, 'days').endOf('day').toISOString()
      : moment(end).toISOString(),
    allDay,
  };

  if (args.resource) {
    const entityType: FilterType = get(
      args.resource,
      '_resource.extendedProps.type'
    );
    const entityId: string = get(
      args.resource,
      '_resource.extendedProps.originalId'
    );
    switch (entityType) {
      case FilterType.resources:
        data.resources = [parseInt(entityId)];
        break;
      case FilterType.users:
        data.users = [parseInt(entityId)];
        break;
    }
  }

  return data;
};
