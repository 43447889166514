import { react2angular } from 'react18-react2angular';

import sharedContext from './shared-context-root/shared-context-root.component';

import { CdMasqueradeTag } from '@/react/shared/components/cd-buttons/CdMasqueradeTag';

angular
  .module('cdApp.shared')
  .component(
    'cdrMasqueradeTag',
    react2angular(sharedContext.use(CdMasqueradeTag), [], [])
  );
