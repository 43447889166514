import { ApiResponse } from 'apisauce';

import { mainApi } from '../../api';

export interface Group {
  id: number;
  name: string;
  description: null | string;
  type: null;
  absenceDisabled: boolean | null;
  picture: string;
  members: number[];
  tasks: Task[];
  leaders: number[];
}

export interface Task {
  id: number;
  title: string;
  groupId: number;
  updatedAt: string;
}

class GroupService {
  public getAllGroups = async () => {
    const response: ApiResponse<Group[]> = await mainApi.get('/groups');
    if (response.ok) return response.data;
    throw response.data;
  };
}

export default new GroupService();
