import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { ApiSearchParams, mainApi } from '@/react/api';
import { handleError } from '@/react/services/ErrorHandlingService';
import {
  DuplicateContact,
  DuplicateContactResponse,
} from '@/react/people/duplicate/types/Types';
import { handleSuccessMessage } from '@/react/shared/utils';
import gettextCatalog from '@/react/services/I18nService';

export const getDuplicateContacts = () => (params: ApiSearchParams) =>
  useQuery({
    queryKey: ['getDuplicateContacts', params],
    queryFn: async () => {
      const { extraData, limit, pageNumber } = params;
      const search = extraData?.search;
      const effectivePageNumber = search ? 1 : pageNumber;
      const response = await mainApi.get<DuplicateContactResponse>(
        `people/duplicates`,
        { limit, pageNumber: effectivePageNumber, search }
      );
      if (!response.ok) {
        handleError(response);
        return {
          items: [],
          total: 0,
        };
      }
      return {
        items: response.data.duplicates,
        total: response.data.total,
      };
    },
  });

export const useDuplicateContactRecord = (duplicateId: number) => {
  const { data, isLoading } = useQuery({
    queryKey: ['useDuplicateContactRecord', duplicateId],
    queryFn: async () => {
      const response = await mainApi.get<DuplicateContact>(
        `people/duplicates/${duplicateId}`
      );
      if (!response.ok) {
        handleError(response);
        return undefined;
      }
      return response.data;
    },
  });
  return { duplicateRecordData: data, isLoading };
};

export const useMergeDuplicateContact = () => {
  const queryClient = useQueryClient();
  const { mutate } = useMutation({
    mutationFn: async ({
      duplicateId,
      baseContactId,
      selectedEmail,
      selectedPhoneNumber,
    }: {
      duplicateId: number;
      baseContactId: number;
      selectedEmail: string;
      selectedPhoneNumber: string;
    }) => {
      const response = await mainApi.post(`/people/duplicates/merge`, {
        duplicateId,
        baseContactId,
        selectedEmail,
        selectedPhoneNumber,
      });
      if (!response.ok) {
        handleError(response);
        return undefined;
      }
      return response;
    },
    onSuccess: (res) => {
      res.ok &&
        handleSuccessMessage(
          gettextCatalog.getString('Contacts merged successfully')
        );
      queryClient.invalidateQueries({
        queryKey: ['getDuplicateContacts'],
      });
    },
  });
  return { mergeDuplicateContact: mutate };
};

export const useRejectDuplicateContactMerge = () => {
  const queryClient = useQueryClient();

  const { mutate } = useMutation({
    mutationFn: async (duplicateId: number) => {
      const response = await mainApi.post(`/people/duplicates/reject`, {
        duplicateId: duplicateId,
      });
      if (!response.ok) {
        handleError(response);
        return undefined;
      }
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['getDuplicateContacts'],
      });
    },
  });
  return { rejectDuplicateContactMerge: mutate };
};
