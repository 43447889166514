import { Collapse, CollapseProps } from 'antd';
import React from 'react';
import styled from 'styled-components';

interface StyledCollapseProps extends CollapseProps {
  $transparentBackground?: boolean;
}

const CdCalendarFilterCollapseStyled = styled(Collapse)<StyledCollapseProps>`
&&&& {
    background: #fff;
    border-radius: 0px;
    border: 0px;
    border-top: 1px solid #ddd;

    .ant-collapse-item {
        border-bottom: 0px;
    }

    .ant-collapse-header {
        align-items: center;
        padding: 0px;
        background: ${({ $transparentBackground }) =>
          $transparentBackground ? 'none' : 'rgb(238, 238, 238)'};
    }

    .ant-collapse-expand-icon {
        padding-right: 16px;
    }
    .ant-collapse-content-box {
        padding: 0px;
    }
    
    .ant-collapse-content {
        border-top: 0px;
        padding-bottom: 16px;
    }
}
`;

export const CdCalendarFilterCollapse = (
  props: CollapseProps & { $transparentBackground?: boolean }
) => <CdCalendarFilterCollapseStyled {...props} expandIconPosition="end" />;
