import { Card } from 'antd';
import styled from 'styled-components';
import { CardProps } from 'antd/lib/card';

interface PopoverContentCardProps extends CardProps {
  $isPublic: boolean;
}

export const PopoverContentCard: React.FunctionComponent<PopoverContentCardProps> = styled(
  Card
)`
  &&&& {
    margin: 24px 0;
    max-width: 450px;

    .ant-card-head-title {
      padding: 0;
    }
    .ant-card-head-wrapper {
      flex: 1;
    }
    .ant-card-head {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-height: 80px;
      max-height: 450px;
      width: 450px;
      padding: 8px 16px 16px;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      color: rgb(255, 255, 255);
    }
    .ant-card-body {
      max-height: calc(100vh - 129px - 48px - 48px);
      overflow-y: scroll;
      padding: ${(p: PopoverContentCardProps) =>
        p.$isPublic ? '0 0 8px' : '8px 0'};

      .event-data__section {
        padding: 8px 16px;

        .event-data__label {
          font-weight: 700;
        }

        .ant-typography {
          margin-bottom: 0;
        }

        .absence-user-link:not(:hover) {
          color: rgba(0, 0, 0, 0.85);
          transition: all 0.3s;
        }
      }
      .cd-collapse-custom-collapse {
        width: 100%;

        .ant-collapse-header {
          padding: 8px 16px;

          &:hover,
          &:active {
            background: rgb(238, 238, 238);
            border-radius: 0;
          }
        }
        .ant-collapse-content-box {
          padding: 0;
        }
      }
      .ant-list {
        .ant-list-header {
          padding: 0;
          border: 0;
        }

        .ant-list-items {
          padding: 4px 0;

          .ant-list-item {
            padding: 0;
            border: 0;

            .ant-list-item-meta {
              align-items: center;
              padding: 4px 0;

              .ant-list-item-meta-avatar,
              .ant-list-item-meta-title {
                padding: 0;
                margin: 0;
              }

              .ant-list-item-meta-title {
                display: flex;
                align-items: center;
                line-height: 1em;

                a.event-data__user-link {
                  display: -webkit-box;
                  -webkit-line-clamp: 1;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                }
              }
            }
          }
        }
      }
    }
    .event-popup__footer {
      display: flex;
      justify-content: space-between;
      padding: 0 16px;
    }
  }
`;
