const settingsModule = angular.module('cdApp.settings', []);

require('./settings.component');
require('./account-settings/account-settings.component');
require('./legal-settings/legal-settings.component');
require('./people-settings/people-settings.component');
require('./people-settings/cdr-people-settings');
require('./users-settings');
require('./widgets-settings/widgets-settings.component');
require('./taxonomy-detail/taxonomy-detail.component');
require('./taxonomy-list/taxonomy-list.component');
require('./taxonomy-delete-modal/taxonomy-delete-modal.component');
require('./resource-list/resource-list.component');
require('./mvr-form/mvr-form.component');
require('./privacy-policy/cdr-privacy-policy');
require('./manage-roles/index');
require('./fees/cdr-create-fee');
require('./fees/cdr-intention-fees');
require('./report-templates/report-templates.component');
require('./report-templates/cdr-template-placeholder-definitions.component');
require('./report-templates/create-report-templates/create-report-templates.component');
require('./templates');
require('./imprint/cdr-imprint');
require('./booking-pages/booking-pages.component');

module.exports = settingsModule.name;
