import { EventApi } from '@fullcalendar/react';
import { PayloadAction } from '@reduxjs/toolkit';
import { ApiResponse } from 'apisauce';
import _ from 'lodash';
import type { SagaIterator } from 'redux-saga';
import {
  all,
  call,
  fork,
  put,
  select,
  takeLatest,
  throttle,
} from 'redux-saga/effects';

import { getCurrentUser } from '../../../config/store/Selector';
import { AppState } from '../../../redux';
import { withLoadingAndErrors } from '../../../shared/loading/saga';
import { Me } from '../../../shared/models/authentication';
import { Resource } from '../../../shared/models/resource';
import { FBaseEvent } from '../../models/calendar';
import CalendarService from '../../services/CalendarService';
import CalendarViewService from '../../services/CalendarViewService';
import EventPopupService from '../../services/EventPopupService';
import { AbsenceSelectors } from '../absences/absencesSlice';
import { FETCH_CALENDAR_FILTER_DATA } from '../filters/Actions';
import {
  getSelectedChurch,
  getSelectedChurchCategories,
  selectIsUserFiltersLoaded,
} from '../filters/Selectors';
import { SET_CALENDAR_EVENT_COLORING_CRITERIA } from '../main-view/Actions';
import { selectIsInResourceView } from '../main-view/Selectors';
import { availabilityIdsSelector } from '../availabilities/availabilitiesSlice';

import { eventsDetailsActions } from './eventsDetailsSlice';
import { eventsActions, eventsSelectors } from './eventsSlice';
import {
  calendarModuleName,
  fullCalendarActions,
} from './fullCalendarEventsSlice';

const getLoggedInUserId = (state: AppState) => state.config.me.id;
const getSelectedResources = (state: AppState) =>
  state.calendar.filters.selectedResources;
const getSelectedUser = (state: AppState) =>
  state.calendar.filters.selectedUsers;

function* openDeleteModalSaga({ payload }: PayloadAction<number>) {
  const event = yield call(CalendarService.UN_SAFE_getEventNgResource, payload);
  yield call(EventPopupService.deleteEvent, event);
}

function* openEditModalSaga({
  payload,
}: PayloadAction<{ eventId: number; type: string }>) {
  const event = yield call(
    CalendarService.UN_SAFE_getEventNgResource,
    payload.eventId
  );
  yield call(EventPopupService.editEventDetails, event.type, event);
}

function* openCopyModalSaga({ payload }: PayloadAction<number>) {
  const event = yield call(CalendarService.UN_SAFE_getEventNgResource, payload);
  yield call(EventPopupService.copyEvent, event.type, event);
}

function* refreshRenderEventsSaga(): Generator {
  const state = yield select((state: AppState) => state);
  const loggedInUserId = getLoggedInUserId(state as AppState);
  const showPrepTime = (state as AppState).calendar.view.showPrepTime;
  const eventColoringCriteria = (state as AppState).calendar.view
    .eventColoringCriteria;
  const events = eventsSelectors.getOriginalEvents(state as AppState);
  const eventsToRender = yield call(
    CalendarService.getFullCalendarEventEvents,
    events,
    loggedInUserId,
    showPrepTime,
    eventColoringCriteria
  );
  yield put(
    fullCalendarActions.updateEventToRender(eventsToRender as EventApi[])
  );
}

function* reloadCurrentViewSaga(): SagaIterator {
  const initialStateLoaded = selectIsUserFiltersLoaded(yield select());
  if (!initialStateLoaded) {
    yield put({ type: FETCH_CALENDAR_FILTER_DATA });
    return;
  }
  yield fork(
    withLoadingAndErrors(calendarModuleName, fetchEventsByOrganizationAndChurch)
  );
}

function* fetchEventsByOrganizationAndChurch(): SagaIterator {
  const startDate: Date = yield call(CalendarViewService.getStartDate);
  const endDate: Date = yield call(CalendarViewService.getEndDate);
  const isInResourceView = selectIsInResourceView(yield select());
  const organizationId: number = yield select((state: AppState) =>
    _.get(
      state,
      'config.organization.id',
      _.get(window, 'churchdeskOrganizationId')
    )
  );
  const churches: Resource[] = yield select(getSelectedChurch);
  let selectedOrganizationCategories: number[] = [];
  // Calendar subscriptions
  const calendarSubscriptions: any = yield select(
    (state: AppState) => state.calendar.view.calendarSubscriptions
  );
  // Calendar subscriptions filters
  const calendarSubscriptionFilters: any = yield select(
    (state: AppState) => state.calendar.filters.calendarSubscriptions
  );
  const showDeclinedEventsFilters: any = yield select(
    (state: AppState) => state.calendar.view?.showDeclinedEvents
  );

  const feedIds: string[] = _.reduce(
    calendarSubscriptions,
    (feeds, calendarSubscription) => {
      if (calendarSubscriptionFilters[calendarSubscription.id] === false) {
        return feeds;
      }
      feeds.push(calendarSubscription.id);
      return feeds;
    },
    []
  );
  const selectedCategory = getSelectedChurchCategories(yield select());
  if (!churches || churches.length === 0) {
    selectedOrganizationCategories = _.map(
      _.pickBy(
        selectedCategory[organizationId],
        (value, key) => value && key !== 'isAllSelected'
      ),
      (value, key) => parseInt(key)
    );
  }

  const selectedUsers = getSelectedUser(yield select());
  const selectedUserIds = _.map(
    _.pickBy(selectedUsers, (value) => value),
    (value, key) => parseInt(key)
  );
  const loggedInUser: Me = yield select(getCurrentUser);
  const showMyCalendar: boolean = yield select(
    (state: AppState) => state.calendar.filters.showMyCalendar
  );
  if (showMyCalendar) {
    selectedUserIds.push(loggedInUser.id);
  }

  const selectedResources = getSelectedResources(yield select());
  const selectedResourceIds = _.map(
    _.pickBy(selectedResources, (value) => value),
    (value, key) => parseInt(key)
  );
  const absenceFromGroupIds = AbsenceSelectors.selectedGroupIds(yield select());
  const availabilityTaxonomyIds = availabilityIdsSelector(yield select());
  const isMinimizedAbsenceView = AbsenceSelectors.isMinimizedView(
    yield select()
  );
  const absenceViewMode: 'minimized' | 'all' =
    isMinimizedAbsenceView && !isInResourceView ? 'minimized' : 'all';

  const loadDataResponse: ApiResponse<FBaseEvent[]> = yield call(
    CalendarService.loadData,
    startDate,
    endDate,
    organizationId,
    selectedUserIds,
    selectedResourceIds,
    selectedCategory,
    selectedOrganizationCategories || ([] as number[]),
    feedIds,
    absenceFromGroupIds,
    absenceViewMode,
    showDeclinedEventsFilters,
    availabilityTaxonomyIds
  );
  if (loadDataResponse.ok) {
    const state: AppState = yield select();
    const showPrepTime = state.calendar.view.showPrepTime;
    const eventColoringCriteria = state.calendar.view.eventColoringCriteria;
    const eventsToRender: EventApi[] = yield call(
      CalendarService.getFullCalendarEventEvents,
      loadDataResponse.data as FBaseEvent[],
      loggedInUser.id,
      showPrepTime,
      eventColoringCriteria
    );
    yield put(eventsActions.eventsReceived(loadDataResponse.data));
    yield put(
      fullCalendarActions.updateEventToRender(eventsToRender as EventApi[])
    );
  }
}

//  changeAttendingStatus just clear the calendar and it is not updating the event and have no communication to the backend
function* changeAttendingStatus({
  payload,
}: PayloadAction<{ eventId: number; eventType: string; status: string }>) {
  const { eventId, status } = payload;

  const originalEventSelector = eventsSelectors.getOriginalEvent(eventId);
  let event = originalEventSelector(yield select());
  event = { ...event };
  const loggedInUser: Me = yield select(getCurrentUser);
  event.users = { ...event.users, [loggedInUser.id]: status };
  yield put(eventsActions.updateOne({ id: eventId, changes: { ...event } }));
  yield put(fullCalendarActions.refreshEventsToRender());
}

export function* fullCalendarEventsRootSaga(): SagaIterator {
  yield all([
    throttle(
      1000,
      fullCalendarActions.reloadCurrentView.type,
      reloadCurrentViewSaga
    ),
    takeLatest(
      fullCalendarActions.refreshEventsToRender.type,
      refreshRenderEventsSaga
    ),
    takeLatest(SET_CALENDAR_EVENT_COLORING_CRITERIA, refreshRenderEventsSaga),
    takeLatest(fullCalendarActions.editEvent.type, openEditModalSaga),
    takeLatest(fullCalendarActions.deleteEvent.type, openDeleteModalSaga),
    takeLatest(
      eventsDetailsActions.changeAttendingStatus.type,
      changeAttendingStatus
    ),
    takeLatest(fullCalendarActions.copyEvent.type, openCopyModalSaga),
  ]);
}
