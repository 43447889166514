import React, { Suspense } from 'react';
import { Button, Space, Typography } from 'antd';
import NiceModal from '@ebay/nice-modal-react';
import { useRecoilValue } from 'recoil';

import { FieldSection } from '../../components/FieldSection';
import { MINIMUM_COLUMN_WIDTH } from '../../constants';

import { EventInvitationList } from './EventInvitationList';

import { UserHasPrivilege } from '@/react/user/store/permissions';
import { MessageType } from '@/react/people/types/message';
import { navigate } from '@/react/services/StateServiceFactory';
import { gettextCatalog } from '@/react/services/I18nService';

interface InviteTabProps {
  eventId: number;
  saveAndClose: any;
}

export const InviteTab = (props: InviteTabProps) => (
  <Suspense>
    <InviteTabInner {...props} />
  </Suspense>
);

const InviteTabInner = (props: InviteTabProps) => {
  const canCommunicate = useRecoilValue(
    UserHasPrivilege('people_message.actions.send')
  );
  const handleCreateInvitation = () => {
    props
      .saveAndClose({
        title: gettextCatalog.getString('Save before creating an invite'),
        message: gettextCatalog.getString(
          'To create an invite you will first have to save the event.'
        ),
        okText: gettextCatalog.getString('Save & continue'),
        cancelText: gettextCatalog.getString('Cancel'),
        skipRedirecting: false,
      })
      .then(() => {
        NiceModal.show('CalendarEmailPosterTemplates', {
          calendarId: props.eventId,
        });
      });
  };
  const handleEditInvitation = (invitationId) => {
    props
      .saveAndClose({
        title: gettextCatalog.getString('Save before editing an invite'),
        message: gettextCatalog.getString(
          'To edit an invite you will first have to save the event.'
        ),
        okText: gettextCatalog.getString('Save & continue'),
        cancelText: gettextCatalog.getString('Cancel'),
        skipRedirecting: true,
      })
      .then(() => {
        navigate(
          'app.private.people.messages.editorV2.view',
          { messageId: invitationId, type: MessageType.EVENT_INVITATION },
          { reload: false }
        );
      });
  };

  return (
    <FieldSection>
      <Space direction="vertical">
        <Typography.Text strong>
          {gettextCatalog.getString('Invites')}
        </Typography.Text>
        <Button
          type="primary"
          onClick={handleCreateInvitation}
          disabled={!canCommunicate}
        >
          {gettextCatalog.getString('Create invite')}
        </Button>
      </Space>
      <div
        style={{
          border: '1px solid 	#E5E4E2',
          borderRadius: '4px',
          marginTop: '16px',
          width: `${MINIMUM_COLUMN_WIDTH + 24}px`,
        }}
      >
        <EventInvitationList
          eventId={props.eventId}
          handleCreateInvitation={handleCreateInvitation}
          handleEditInvitation={handleEditInvitation}
        />
      </div>
    </FieldSection>
  );
};
