import { mainApi } from '@/react/api';
import { Resource } from '@/react/shared/models/resource';

export const getResources = async () => {
  const response = await mainApi.get('/resources');
  if (response.ok) {
    return response.data as Resource[];
  }
  throw response.data;
};
