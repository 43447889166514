import React, { PropsWithChildren, useEffect } from 'react';
import { Space, Spin, Typography } from 'antd';
import { isNull } from 'lodash';

import { createCdDrawer } from '../../../shared/components/cd-drawer/CdDrawer';
import { ModalType } from '../../../shared/components/cd-modal-new/CdModal2';
import gettextCatalog from '../../../services/I18nService';
import { useSingleEnquiry } from '../hooks/use-enquiries';
import { useEnquiriesPage } from '../hooks/use-enquiries-page';
import { COLOR_INDEX } from '../../../../app/ResourceColors';
import { CdCircleFilled } from '../../../shared/components/Icons';
import { CdVerticalSpace } from '../../../shared/components/cd-vertical-space/CdVerticalSpace';

import { FormSubmissionViewer } from './FormSubmissionViewer';
import { DateIntervalRender } from './DateIntervalRender';
import { EnquiryStatusIcon } from './EnquiryStatus';

export const showEnquiryDrawer = createCdDrawer<{ id: string }>(
  ({ id, setDrawerProps }) => {
    const { approveEnquiry, declineEnquiry } = useEnquiriesPage();
    const query = useSingleEnquiry(id);
    const enquiry = query.data;

    useEffect(
      () =>
        setDrawerProps({
          modalType: ModalType.POPUP,
          okText: gettextCatalog.getString('Approve', null, 'Enquiry'),
          cancelText: gettextCatalog.getString('Decline', null, 'Enquiry'),
          hideOk: enquiry?.status !== 'requested',
          hideCancel: enquiry?.status !== 'requested',
          onOk: () => approveEnquiry(id),
          onCancel: () => declineEnquiry(id),
        }),
      [approveEnquiry, declineEnquiry, enquiry?.status, id, setDrawerProps]
    );

    return (
      <Spin spinning={query.isLoading}>
        {enquiry && (
          <>
            <Typography.Title style={{ marginTop: 0 }}>
              {gettextCatalog.getString('Enquiry for {{type}}', {
                type: enquiry.taxonomy.name,
              })}
            </Typography.Title>
            <DateIntervalRender
              startDate={enquiry.startDate}
              endDate={enquiry.endDate}
            />
            <Section title={gettextCatalog.getString('Status')}>
              <EnquiryStatusIcon status={enquiry?.status} />
            </Section>
            <Section title={gettextCatalog.getString('Booking option')}>
              <CdVerticalSpace size={0}>
                <Typography.Text>{enquiry.bookingOption.name}</Typography.Text>
                {enquiry.resources?.map((resource) => (
                  <Space>
                    {!isNull(resource.color) && (
                      <CdCircleFilled
                        style={{
                          color: COLOR_INDEX[resource.color],
                        }}
                      />
                    )}
                    {resource.name}
                  </Space>
                ))}
              </CdVerticalSpace>
            </Section>
            <Section title={gettextCatalog.getString('Users')}>
              {enquiry.users
                ?.map((user) => user.contact?.fullName || user.email)
                .join(', ')}
            </Section>
            <FormSubmissionViewer submissionData={enquiry?.submissionData} />
          </>
        )}
      </Spin>
    );
  }
);

const Section = (props: PropsWithChildren & { title?: string }) => (
  <>
    <Typography.Title level={3}>{props.title}</Typography.Title>
    {props.children}
  </>
);
