import { flatten } from 'lodash';

import { AvailabilityTaxonomy } from '../../../taxonomies/services/Taxonomies';
import { InterfaceGroups } from '../../../organization/services/Groups.service';

export const getUsersInAvailabilityGroup = (
  taxonomy: AvailabilityTaxonomy,
  groups: InterfaceGroups[]
) => {
  const groupsInTaxonomy =
    groups?.filter((group) =>
      taxonomy?.groups
        ?.map((groupInTaxonomy) => groupInTaxonomy.id)
        .includes(group.id)
    ) || [];
  const userIdsInGroups = flatten(
    groupsInTaxonomy.map((group) => group.members)
  );

  return userIdsInGroups;
};
