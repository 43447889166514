import NiceModal from '@ebay/nice-modal-react';
import React from 'react';
import { useCallback, useEffect } from 'react';

import { showCreateUserModal } from '../user/components/CreateUserModal';
import { showAddMemberModal } from '../groups/components/AddMemberModal';
import { showPostGroupMessageModal } from '../groups/components/PostGroupMessageModal';

/**
 * Bridge component to communciate to Angular from React
 */
export const ReactModalBridge = () => {
  const handleModal = useCallback((event) => {
    const source = event.data?.data?.source;
    const messageType = event.data?.message;
    const data = event.data?.data;
    if (source === 'cdReact') {
      switch (messageType) {
        case 'FilePickerComponentModal':
          NiceModal.show('FilePickerComponentModal', data.modalProps).then(
            ({ file, imageFormat, isUsedFromEditor }) => {
              window.postMessage(
                {
                  message: 'FilePickerComponentModalCallback',
                  data: { file, imageFormat, isUsedFromEditor },
                },
                window.location.origin
              );
            }
          );
          break;
        case 'showModal':
          if (data.modalName === 'createUserModal') {
            showCreateUserModal({ initialValues: data.modalProps });
          } else if (data.modalName === 'addMemberModal') {
            showAddMemberModal(data.modalProps);
          } else if (data.modalName === 'postGroupMessageModal') {
            showPostGroupMessageModal(data.modalProps);
          } else NiceModal.show(data.modalName, data.modalProps);
          break;
      }
    }
  }, []);

  useEffect(() => {
    window.addEventListener('message', handleModal);
    return () => {
      window.removeEventListener('message', handleModal);
    };
  }, [handleModal]);
  return <></>;
};

export const launchFilePicker = (isUsedFromEditor: boolean) => {
  window.postMessage(
    {
      message: 'FilePickerComponentModal',
      data: { source: 'cdReact', modalProps: { isUsedFromEditor } },
    },
    window.location.origin
  );
};

/**
 * Function to show a modal from the React app
 * @param modalName The name of the modal to show
 * @param modalProps The props to pass to the modal
 */
export const showModal = (modalName: string, modalProps: any) => {
  window.postMessage(
    {
      message: 'showModal',
      data: { source: 'cdReact', modalName, modalProps },
    },
    window.location.origin
  );
};

/**
 * Hides all Antd modals and drawers by sending a message to the window object.
 */
export const hideAllModals = () => {
  window.postMessage(
    {
      message: 'hideAllModal',
      data: { source: 'cdReact' },
    },
    window.location.origin
  );
};
