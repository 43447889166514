import { Skeleton } from 'antd';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { gettextCatalog } from '../../services/I18nService';
import { toggleUsersAndRoomsCalenders } from '../store/filters/Actions';
import {
  selectIsAllRoomsAndUsersSelected,
  selectIsUserFiltersLoaded,
} from '../store/filters/Selectors';
import { CdCalendarFilterCollapse } from '../containers/calendar-filter/CdCalendarFilterCollapse';

import CalendarsSelect from './CalendarsSelect';
import { FilterSectionHeader } from './FilterSectionHeader';

const UsersAndRoomsCalendars = () => {
  const dispatch = useDispatch();
  const isUserFiltersLoaded = useSelector(selectIsUserFiltersLoaded);
  const isAllUsersAndRoomsSelected = useSelector(
    selectIsAllRoomsAndUsersSelected
  );
  const toggleAllUsersAndRooms = useCallback(() => {
    dispatch(toggleUsersAndRoomsCalenders(isAllUsersAndRoomsSelected));
  }, [isAllUsersAndRoomsSelected, dispatch]);
  return (
    <CdCalendarFilterCollapse
      defaultActiveKey={['USER_ROOM_CALENDARS']}
      $transparentBackground
      items={[
        {
          key: 'USER_ROOM_CALENDARS',
          label: isUserFiltersLoaded ? (
            <FilterSectionHeader
              name={gettextCatalog.getString('User & room calendars')}
              isAllSelected={isAllUsersAndRoomsSelected}
              onClick={toggleAllUsersAndRooms}
              isDisabled={false}
            />
          ) : (
            <div style={{ padding: '0 16px' }}>
              <Skeleton
                title={false}
                paragraph={{ rows: 4, width: '100%' }}
                loading
                active
              />
            </div>
          ),
          children: isUserFiltersLoaded ? (
            <div>
              <CalendarsSelect />
            </div>
          ) : (
            <div style={{ padding: '0 16px' }}>
              <Skeleton
                title={false}
                paragraph={{ rows: 4, width: '100%' }}
                loading
                active
              />
            </div>
          ),
        },
      ]}
    ></CdCalendarFilterCollapse>
  );
};

export default UsersAndRoomsCalendars;
