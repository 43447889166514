import { ApiResponse } from 'apisauce';
import _ from 'lodash';
import moment from 'moment';
import type { SagaIterator } from 'redux-saga';
import { createModal, destroyModal } from 'redux-saga-modal';
import { all, call, fork, put, race, select, take } from 'redux-saga/effects';

import {
  getCurrentCountryCode,
  getCurrentOrganization,
  getCurrentUser,
} from '../../config/store/Selector';
import { AppState } from '../../redux';
import ErrorHandlingService from '../../services/ErrorHandlingService';
import { gettextCatalog } from '../../services/I18nService';
import { Me } from '../../shared/models/authentication';
import { Category } from '../../shared/models/category';
import { Resource, ResourceTypes } from '../../shared/models/resource';
import {
  fetchCategoriesSaga,
  fetchResourcesSaga,
} from '../../shared/sagas/SharedSagas';
import CalendarSubscriptionService, {
  CalendarSubscriptionPayload,
} from '../../shared/services/CalendarSubscriptionService';
import DataService from '../../shared/services/DataService';
import {
  groupsLoaded,
  loadGroups,
  selectGroupIds,
} from '../../shared/store/groups';
import { getResources } from '../../shared/store/resources';
import { getEventCategories } from '../../shared/store/Selectors';
import { FETCH_USERS_SUCCESS, FetchUsers } from '../../user/redux/actions';
import { getUsers } from '../../user/redux/selectors';
import { User } from '../../user/types/User.types';
import { DetailedEvent, EventConflict, FEvent } from '../models/calendar';
import { Filters, ParentResources } from '../models/calendarData';
import CalendarService from '../services/CalendarService';
import EventPopupService from '../services/EventPopupService';
import HolidayService, { HolyDayTypes } from '../services/HolidayService';
import {
  AbsencesActions,
  AbsenceSelectors,
} from '../store/absences/absencesSlice';
import { calendarSettingsActions } from '../store/calendar-settings/calendarSettingsSlice';
import { fullCalendarActions } from '../store/events/fullCalendarEventsSlice';
import { FilterType, SelectedCalendar } from '../store/filters';
import {
  AddCalendar,
  FetchCalendarFilterDataAction,
  InitializeFiltersState,
  SelectAllCategories,
  toggleAllParishes,
  ToggleCalendarSubscriptionAction,
  ToggleMeFilter,
  ToggleSelectAllCategories,
  UnselectAllCategories,
  UpdateCategoryCalendarFiltersRequest,
  userFiltersLoaded,
} from '../store/filters/Actions';
import {
  getCategories,
  getSelectedChurch,
  isAllSelected,
  isCalendarSelected,
  isCategorySelected,
  selectIsUserFiltersLoaded,
} from '../store/filters/Selectors';
import { CalendarEventColoringCriteria } from '../store/main-view';
import {
  AddEventAction,
  EditEvent,
  FetchCalendarSubscriptionSuccessAction,
  GoToDateAction,
  InitializeViewState,
  LoadLiturgicalHolidaysSuccess,
  LoadNationalHolidaysSuccess,
  SetCalendarEventColoringCriteria,
  setUnselectedCalendars,
  UpdateEventAction,
} from '../store/main-view/Actions';
import {
  availabilitiesRootSelector,
  availabilitiesSlice,
} from '../store/availabilities/availabilitiesSlice';

import ModalTypes from './ModalTypes';

export function* toDay(): SagaIterator {
  yield put(GoToDateAction(moment().toDate()));
}

export interface Holiday {
  date?: Date;
  start: Date;
  end: Date;
  title: string;
  allDay: boolean;
  eventStartEditable: boolean;
  editable: boolean;
  eventDurationEditable: boolean;
  backgroundColor: string;
  holidayType: HolyDayTypes;
  textColor: string;
  isPast: boolean;
}

const getChurchName = (churches: Resource[], id: number | null) => {
  if (id) {
    const church = churches.find((item) => item.id === id);
    return church ? church.name : gettextCatalog.getString('No Church');
  }
  return gettextCatalog.getString('No Church');
};

const getUnselectedCalendars = (state: AppState): SelectedCalendar[] => {
  const resources = state.calendar.filters.filterData.resources;
  const users = state.calendar.filters.filterData.users;
  const me: Me = getCurrentUser(state);
  const { selectedResources, selectedUsers } = state.calendar.filters;
  const resourcesCalendars = resources
    .filter((resource) => selectedResources[resource.id] === undefined)
    .map((resource) => ({ ...resource, filterType: FilterType.resources }));
  const usersCalendars = users
    .filter((user) => selectedUsers[user.id] === undefined && user.id !== me.id)
    .map((user) => ({ ...user, filterType: FilterType.users }));
  return [...resourcesCalendars, ...usersCalendars] as SelectedCalendar[];
};

function* loadUsersIfNotExist() {
  let users = getUsers(yield select());
  if (users.length === 0) {
    yield put(FetchUsers());
    yield take(FETCH_USERS_SUCCESS);
  }
  users = getUsers(yield select());
  return users;
}

export function* fetchCalendarFilterData(): SagaIterator {
  // Retrieve the filter data from the backend
  yield put(loadGroups());

  // Fetch other required data
  const allResponses = yield all({
    categoriesResponse: call(fetchCategoriesSaga),
    resourcesResponse: call(fetchResourcesSaga),
    retrievedUsers: call(loadUsersIfNotExist),
    calendarData: call(CalendarService.getCalendarData),
  });

  const { retrievedUsers, calendarData } = allResponses as {
    retrievedUsers: User[];
    calendarData: Filters;
  };

  // Filter data:
  // - Keep all the event categories ONLY
  const categories: Category[] = yield select(getEventCategories);
  // - Keep all the resources except for the `church` resources
  const parentResources: ParentResources = {};
  const resources: Resource[] = [];
  const allResources: Resource[] = yield select(getResources);
  _.each(allResources, (resource) => {
    if (resource.type === ResourceTypes.CHURCH) {
      /**
       * If the resource is a church and has any resources, add them to the parent resources list
       * and its children to the resources list
       */
      parentResources[resource.id] = resource;
      if (!_.isEmpty(resource.resources)) resources.push(...resource.resources);
    } else {
      // If the resource is NOT a church, add it to the filtered list
      resources.push(resource);
    }
  });
  // - Keep all the users except for the logged-in user
  const loggedInUser: Me = yield select(getCurrentUser);
  const users: User[] = _.map(
    _.filter(
      retrievedUsers,
      (user: User) => user.status !== 0 && user.id !== loggedInUser.id
    ),
    (value, key): User => {
      const processedUser = _.extend({}, value) as unknown as User;
      if (_.isEmpty(processedUser.name)) {
        processedUser.name = processedUser.email;
      }
      return {
        id: key,
        ...processedUser,
      };
    }
  );
  const sortedCategories = _.map(
    _.sortBy(categories, [(item: Category) => item.name.toLowerCase()]),
    (value, key): Category => ({
      id: key,
      ...value,
    })
  );

  const churches = yield select((state: AppState) =>
    _.get(state, 'config.organization.churches', [])
  );

  const orderedResources = _.orderBy(
    _.map(
      resources,
      (value, key): Resource => ({
        id: key,
        churchName: getChurchName(
          churches as Resource[],
          value && value.parentResourceId
        ),
        ...value,
      })
    ),
    ['churchName', 'parentResourceId']
  );

  // Return filtered data
  yield put(
    FetchCalendarFilterDataAction({
      categories: sortedCategories,
      parentResources,
      resources: orderedResources,
      users,
      calendarData,
    })
  );

  yield fork(fetchCalendarSubscriptions);

  const unselectedResources = yield select(getUnselectedCalendars);
  yield put(setUnselectedCalendars(unselectedResources as SelectedCalendar[]));
}

export function* fetchCalendarFilterDataAndRefresh(): SagaIterator {
  yield all([
    yield call(fetchCalendarFilterData),
    yield call(InitializeCalendar),
  ]);
  yield call(calculatedUnselectedCalendars);
}

const isPast = (
  start: Date | string | null,
  end: Date | string | null
): boolean => {
  const date = end ? end : start;
  return moment(date).isBefore(Date.now());
};

export function* getHolidays(): SagaIterator {
  try {
    const countryCode = yield select(getCurrentCountryCode);

    if (!countryCode) return;

    if (countryCode === 'de') {
      const allResponses = yield all({
        nationalFilteredHolidays: call(
          HolidayService.loadData,
          countryCode,
          HolyDayTypes.NATIONAL
        ),
        liturgicalFilteredHolidays: call(
          HolidayService.loadData,
          countryCode,
          HolyDayTypes.LITURGICAL
        ),
      });
      const { nationalFilteredHolidays, liturgicalFilteredHolidays } =
        allResponses as {
          nationalFilteredHolidays: Holiday[];
          liturgicalFilteredHolidays: Holiday[];
        };
      const nationalHolidays: Holiday[] = _.map(
        nationalFilteredHolidays,
        (holiday): Holiday => ({
          title: holiday.title,
          start: moment(holiday.date).toDate(),
          end: moment(holiday.date).add(1, 'days').toDate(),
          allDay: true,
          backgroundColor: 'inherit',
          holidayType: HolyDayTypes.NATIONAL,
          textColor: 'inherit',
          eventStartEditable: false,
          editable: false,
          eventDurationEditable: false,
          isPast: isPast(
            moment(holiday.date).toDate(),
            moment(holiday.date).add(1, 'days').toDate()
          ),
        })
      );
      yield put(LoadNationalHolidaysSuccess(nationalHolidays));
      const liturgicalHolidays: Holiday[] = _.map(
        liturgicalFilteredHolidays,
        (holiday): Holiday => ({
          title: holiday.title,
          start: moment(holiday.date).toDate(),
          end: moment(holiday.date).add(1, 'days').toDate(),
          allDay: true,
          backgroundColor: 'inherit',
          holidayType: HolyDayTypes.LITURGICAL,
          textColor: 'inherit',
          eventStartEditable: false,
          editable: false,
          eventDurationEditable: false,
          isPast: isPast(
            moment(holiday.date).toDate(),
            moment(holiday.date).add(1, 'days').toDate()
          ),
        })
      );
      yield put(LoadLiturgicalHolidaysSuccess(liturgicalHolidays));
    } else {
      // Load all holidays and classify them all under the "national"
      const allFilteredHolidays: Holiday[] = yield call(
        HolidayService.loadData,
        countryCode
      );
      const allHolidays: Holiday[] = _.map(
        allFilteredHolidays,
        (holiday): Holiday => ({
          title: holiday.title,
          start: moment(holiday.date).toDate(),
          end: moment(holiday.date).add(1, 'days').toDate(),
          allDay: true,
          backgroundColor: 'inherit',
          holidayType: HolyDayTypes.NATIONAL,
          textColor: 'inherit',
          eventStartEditable: false,
          editable: false,
          eventDurationEditable: false,
          isPast: isPast(
            moment(holiday.date).toDate(),
            moment(holiday.date).add(1, 'days').toDate()
          ),
        })
      );
      yield put(LoadNationalHolidaysSuccess(allHolidays));
    }
  } catch {}
}

const getSelectedResources = (state: AppState) =>
  state.calendar.filters.selectedResources;

export function* openModal(
  modalType: string,
  props: { [key: string]: any }
): SagaIterator {
  const modal = createModal(modalType);

  yield modal.show(props);

  const winner: any = yield race({
    submit: modal.takeSubmit(),
    hide: modal.takeHide(),
  });

  if (winner.submit) {
    yield modal.hide(props);
    return true;
  } else {
    yield modal.destroy(props);
    return false;
  }
}

export function* updateEvent(action: UpdateEventAction): SagaIterator {
  try {
    const { oldEvent, newEvent, revert } = action.payload;
    const showPrepTime = yield select(
      (state: AppState): boolean => state.calendar.view.showPrepTime
    );
    const eventFromBackend: DetailedEvent = yield call(
      CalendarService.getEvent,
      parseInt(oldEvent.id, 10)
    );
    const isAllDayUpdated = eventFromBackend.allDay !== newEvent.allDay;

    // Calculate new prep time and cleanup time based on old event
    const preparationStartDate = _.get(
      oldEvent,
      'extendedProps.preparationStartDate'
    );
    const cleanupEndDate = _.get(oldEvent, 'extendedProps.cleanupEndDate');
    const originalStartDate = _.get(
      oldEvent,
      'extendedProps.originalStartDate'
    );
    const originalEndDate = _.get(oldEvent, 'extendedProps.originalEndDate');
    const prepTimeMinutes = moment(originalStartDate).diff(
      preparationStartDate,
      'minutes'
    );
    const cleanupTimeMinutes = moment(cleanupEndDate).diff(
      originalEndDate,
      'minutes'
    );
    const hasPrepTime = prepTimeMinutes > 0;
    const hasCleanupTime = cleanupTimeMinutes > 0;

    let end = newEvent.end;
    let start = newEvent.start;

    // Recalculate start and end if being dragged with prep/cleanup time
    if (hasPrepTime && showPrepTime) {
      start = moment(newEvent.start).add(prepTimeMinutes, 'minutes').toDate();
    }

    if (hasCleanupTime && showPrepTime) {
      end = moment(newEvent.end)
        .subtract(cleanupTimeMinutes, 'minutes')
        .toDate();
    }

    if (newEvent.allDay && isAllDayUpdated) {
      start = moment(start).startOf('day').toDate();
      end = moment(start).endOf('day').toDate();
    } else if (!newEvent.allDay && isAllDayUpdated) {
      end = moment(start).add('hour', 1).toDate();
    }

    const isConfirmed = yield call(openModal, ModalTypes.EVENT_UPDATE_CONFIRM, {
      oldEvent: eventFromBackend,
      newEvent: { ...eventFromBackend, start, end },
    });

    if (!isConfirmed) {
      yield call(revert);
      return;
    }

    const eventToUpdate: Partial<FEvent> = {
      ...eventFromBackend,
      startDate: start,
      endDate: end,
      preparationStartDate: hasPrepTime
        ? moment(start).subtract(prepTimeMinutes, 'minutes').toDate()
        : start,
      cleanupEndDate: hasCleanupTime
        ? moment(end).add(cleanupTimeMinutes, 'minutes').toDate()
        : end,
      allDay: newEvent.allDay,
      id: parseInt(newEvent.id),
    };
    if (newEvent.extendedProps.visibility === 'private') {
      eventToUpdate.churches = undefined;
      eventToUpdate['churchIds'] = undefined;
    }

    const result: EventConflict = yield call(
      CalendarService.updateEvent,
      eventToUpdate,
      false
    );
    if (_.isNil(result)) {
      yield put(fullCalendarActions.reloadCurrentView());
      return;
    }

    if (result instanceof Object) {
      const allowOverbooking = yield call(
        openModal,
        ModalTypes.ALLOW_OVERBOOKING,
        {
          conflicts: result,
        }
      );
      if (allowOverbooking) {
        const result: EventConflict = yield call(
          CalendarService.updateEvent,
          eventToUpdate,
          true
        );
        if (_.isNil(result)) {
          yield put(fullCalendarActions.reloadCurrentView());
        } else {
          yield call(revert);
        }
      } else {
        yield call(revert);
      }
    } else {
      yield call(revert);
    }
  } catch (error) {
    yield call(action.payload.revert);
    yield call(ErrorHandlingService.handleError, error);
  }
}

export function* toggleSelectAll(
  action: ToggleSelectAllCategories
): SagaIterator {
  const { churchId } = action.payload;
  const allSelected = yield select(isAllSelected, churchId);
  if (allSelected) {
    yield put(UnselectAllCategories({ churchId }));
  } else {
    yield put(SelectAllCategories({ churchId }));
  }
}

function* InitializeCalendar(): SagaIterator {
  try {
    const calendarDataResponse: ApiResponse<any, any> = yield call(
      DataService.getData
    );
    if (
      calendarDataResponse.ok &&
      calendarDataResponse.data &&
      calendarDataResponse.data.view
    ) {
      yield put(InitializeViewState(calendarDataResponse.data.view));
      if (
        _.get(
          calendarDataResponse,
          'data.filters.selectedCalendars.length',
          0
        ) === 0
      ) {
        calendarDataResponse.data.filters.selectedResources = {};
        calendarDataResponse.data.filters.selectedUsers = {};
      }
      yield put(InitializeFiltersState(calendarDataResponse.data.filters));

      if (calendarDataResponse.data.absences) {
        yield put(
          AbsencesActions.initializeGroupAbsence(
            calendarDataResponse.data.absences
          )
        );
      }

      if (calendarDataResponse.data.availabilities) {
        yield put(
          availabilitiesSlice.actions.initialize(
            calendarDataResponse.data.availabilities
          )
        );
      }
    } else {
      yield call(initializeCalendarFirstTime);
    }
    if (
      !_.get(calendarDataResponse, 'data.filters.isInitializeFirstTime', false)
    ) {
      yield call(selectAllCategories);
    }
    yield put(userFiltersLoaded());
    yield put(fullCalendarActions.reloadCurrentView());
  } catch (error) {
    yield call(ErrorHandlingService.handleError, error);
  }
}

const getFilters = (state: AppState) => state.calendar.filters;
const getSelectedView = (state: AppState) => state.calendar.view;
export function* saveCalendarData(): SagaIterator {
  const initialStateLoaded = selectIsUserFiltersLoaded(yield select());
  if (!initialStateLoaded) return;
  const {
    selectedCalendars,
    selectedResources,
    selectedUsers,
    showMyCalendar,
    calendarSubscriptions,
    selectedChurchCategories,
    isInitializeFirstTime,
  } = getFilters(yield select());
  const {
    timePeriod,
    resourceType,
    showNationalHolidays,
    showLiturgicalHolidays,
    eventColoringCriteria,
    selectedDate,
    showResources,
    showPrepTime,
    showIntentions,
    showDeclinedEvents,
    showResourceView,
    showCollections,
  } = getSelectedView(yield select());
  const groupsAbsences = AbsenceSelectors.absencesRootSelector(yield select());
  const availabilities = availabilitiesRootSelector(yield select());
  DataService.setData({
    filters: {
      selectedCalendars,
      selectedResources,
      selectedUsers,
      showMyCalendar,
      calendarSubscriptions,
      selectedChurchCategories,
      isInitializeFirstTime,
    },
    view: {
      timePeriod,
      resourceType,
      showNationalHolidays,
      showLiturgicalHolidays,
      eventColoringCriteria,
      selectedDate,
      showResources,
      showPrepTime,
      showIntentions,
      showDeclinedEvents,
      showResourceView,
      showCollections,
    },
    absences: groupsAbsences,
    availabilities,
  });
}

export function* goToWeek(): SagaIterator {}

export function* determineCalendarEventColoringCriteria(): SagaIterator {
  const hasGroupedResourcesPackage: boolean = _.get(
    window,
    'cdApp.showChurchSelector'
  );
  const criteria: CalendarEventColoringCriteria = hasGroupedResourcesPackage
    ? CalendarEventColoringCriteria.Resource
    : CalendarEventColoringCriteria.Category;
  yield put(SetCalendarEventColoringCriteria(criteria));
}

export function* eventAdded(action: AddEventAction): SagaIterator {
  try {
    const event: DetailedEvent = yield call(
      CalendarService.getEvent,
      action.payload.id
    );
    if (event.type === 'event') {
      yield call(eventAddedHandler, event);
    } else if (event.type === 'absence') {
      yield call(absenceAddedHandler, event);
    } else {
      yield put(GoToDateAction(moment(event.startDate).toDate()));
    }
  } catch (error) {
    yield call(ErrorHandlingService.handleError, error);
  }
}

function* isEventVisibleDueToUserFiltersGenerator(event: DetailedEvent) {
  const currentUserId = yield select<(AppState) => number>(
    (state: AppState) => state.config.me.id
  );
  const showMyCalendar = yield select<(AppState) => boolean>(
    (state: AppState) => state.calendar.filters.showMyCalendar
  );
  const usersIds = _.keys(event.users).map((i) => parseInt(i));
  if (usersIds.length === 0) return false;
  // If current user exists in the list of users and his calendar is active
  // return so that we do not add the current user to the other calendars.
  if (usersIds.includes(currentUserId) && showMyCalendar) {
    return true;
  }
  const state: AppState = yield select();
  return usersIds.some((id) => {
    const selector = isCalendarSelected(id, FilterType.users);
    return selector(state);
  });
}

function* isEventVisibleDueToResourceFiltersGenerator(event: DetailedEvent) {
  const resourcesIds = _.keys(event.resources).map((i) => parseInt(i));
  if (resourcesIds.length === 0) return false;
  const state: AppState = yield select();
  return resourcesIds.some((id) => {
    const selector = isCalendarSelected(id, FilterType.resources);
    return selector(state);
  });
}

function* isEventVisibleDueToCategoryFiltersGenerator(event: DetailedEvent) {
  const churchesIds = event.churches
    ? event.churches.map((item) => item.id)
    : [];
  const organization = getCurrentOrganization(yield select());
  const state: AppState = yield select();
  if (churchesIds.length > 0) {
    return churchesIds.some((id: number) =>
      isCategorySelected(event.mainCategory, id)(state)
    );
  } else {
    return isCategorySelected(event.mainCategory, organization.id)(state);
  }
}

function* isEventVisibleDueToCurrentFiltersGenerator(event: DetailedEvent) {
  const isEventVisibleDueToCategoryFilters: boolean = yield call(
    isEventVisibleDueToCategoryFiltersGenerator,
    event
  );

  if (isEventVisibleDueToCategoryFilters) return true;
  const isEventVisibleDueToUserFilters: boolean = yield call(
    isEventVisibleDueToUserFiltersGenerator,
    event
  );
  // have to check the length of the users to prevent returning false witch will conclude that no need to update filters at all,
  // and by this function will continue to check resources

  if (isEventVisibleDueToUserFilters) return true;
  const isEventVisibleDueToResourceFilters: boolean = yield call(
    isEventVisibleDueToResourceFiltersGenerator,
    event
  );

  if (isEventVisibleDueToResourceFilters) return true;
  return false;
}

function* eventAddedHandler(event: DetailedEvent): SagaIterator {
  const isEventVisibleDueToCurrentFilters: boolean = yield call(
    isEventVisibleDueToCurrentFiltersGenerator,
    event
  );

  if (isEventVisibleDueToCurrentFilters) {
    yield put(GoToDateAction(moment(event.startDate).toDate()));
    return;
  }
  const usersIds = _.keys(event.users).map((i) => parseInt(i));
  if (usersIds.length > 0) {
    const usersCheckResult = yield call(handleUsersFilters, usersIds);
    if (!usersCheckResult) {
      yield put(GoToDateAction(moment(event.startDate).toDate()));
      return;
    }
  }
  const resourcesIds = _.keys(event.resources).map((i) => parseInt(i));
  if (resourcesIds.length > 0) {
    const resourceCheckResult = yield call(handleResourceFilters, resourcesIds);
    if (!resourceCheckResult) {
      yield put(GoToDateAction(moment(event.startDate).toDate()));
      return;
    }
  }
  yield call(handleCategories, event);
  yield put(GoToDateAction(moment(event.startDate).toDate()));
}

function* absenceAddedHandler(event: DetailedEvent): SagaIterator {
  const isAbsenceGrouped = AbsenceSelectors.isMinimizedView(yield select());
  if (isAbsenceGrouped) {
    const isGroupAbsenceActiveSelector = AbsenceSelectors.isGroupAbsenceActive(
      event.group.id
    );
    const isGroupSelected = isGroupAbsenceActiveSelector(yield select());
    if (!isGroupSelected) {
      yield put(AbsencesActions.toggleGroupAbsence(event.group.id));
      return;
    }
    yield put(fullCalendarActions.reloadCurrentView());
    return;
  }
  const usersIds = _.keys(event.users).map((i) => parseInt(i));
  yield call(handleUsersFilters, usersIds);
  yield put(GoToDateAction(moment(event.startDate).toDate()));
}

function* handleUsersFilters(usersIds: number[]): SagaIterator {
  const currentUserId: number = yield select<(AppState) => number>(
    (state: AppState) => state.config.me.id
  );
  const showMyCalendar: boolean = yield select<(AppState) => boolean>(
    (state: AppState) => state.calendar.filters.showMyCalendar
  );
  if (usersIds.includes(currentUserId) && !showMyCalendar) {
    yield put(ToggleMeFilter());
    return false;
  }
  // add the first Filter
  yield put(AddCalendar({ id: usersIds[0], filterType: FilterType.users }));
  return false;
}

function* handleResourceFilters(resourcesIds: number[]): SagaIterator {
  // add the first Filter
  yield put(
    AddCalendar({ id: resourcesIds[0], filterType: FilterType.resources })
  );
  return false;
}

function* handleCategories(event: DetailedEvent): SagaIterator {
  const churchesIds = event.churches
    ? event.churches.map((item) => item.id)
    : [];
  const categories = getCategories(yield select());
  const organization = getCurrentOrganization(yield select());
  const category = categories.find((item) => item.id === event.mainCategory);
  yield put(
    UpdateCategoryCalendarFiltersRequest({
      churchIds: churchesIds.length > 0 ? churchesIds : [organization.id],
      category: category,
    })
  );
  return false;
}

export function* EditEventSaga(action: EditEvent): SagaIterator {
  try {
    const event: DetailedEvent = yield call(
      CalendarService.getEvent,
      action.payload.id
    );
    event.groupId = event.group && event.group.id;
    yield call(EventPopupService.editEventDetails, event.type, event);
  } catch (error) {
    yield call(ErrorHandlingService.handleError, error);
  }
}

const getSelectedUsers = (state: AppState) =>
  state.calendar.filters.selectedUsers;

export function* calculatedUnselectedCalendars(): SagaIterator {
  const state: AppState = yield select();
  const resources = state.calendar.filters.filterData.resources;
  const users = state.calendar.filters.filterData.users;
  const me: Me = yield select(getCurrentUser);
  const selectedUsersMap = getSelectedUsers(yield select());
  const selectedResourcesMap = getSelectedResources(yield select());
  const resourcesCalendars = _.chain(resources)
    .filter(
      (resource) =>
        _.get(selectedResourcesMap, `[${resource.id}]`) === undefined
    )
    .map(
      (resource): SelectedCalendar => ({
        id: resource.id,
        filterType: FilterType.resources,
        ...resource,
        color: _.isNil(resource.color) ? '' : resource.color.toString(),
      })
    )
    .value();
  const usersCalendars = _.chain(users)
    .filter(
      (user) =>
        _.get(selectedUsersMap, `[${user.id}]`) === undefined &&
        user.id !== me.id
    )
    .map(
      (user): SelectedCalendar => ({
        id: user.id,
        filterType: FilterType.users,
        image: user.picture,
        ...user,
      })
    )
    .value();
  const unselectedCalendars = [...resourcesCalendars, ...usersCalendars];
  yield put(setUnselectedCalendars(unselectedCalendars));
}

export function* DeleteEventSaga(action: EditEvent): SagaIterator {
  try {
    const event = yield call(
      CalendarService.UN_SAFE_getEventNgResource,
      action.payload.id
    );
    yield call(EventPopupService.deleteEvent, event);
  } catch (error) {
    yield call(ErrorHandlingService.handleError, error);
  }
}

export function* createCalendarSubscription(action): SagaIterator {
  try {
    const subscription: { id: string } = yield call(
      CalendarSubscriptionService.createCalendarSubscription,
      action.payload
    );
    // Refetch
    yield call(fetchCalendarSubscriptions);
    yield put(
      ToggleCalendarSubscriptionAction({
        id: subscription.id,
        isChecked: false,
      })
    );
  } catch (error) {
    yield call(ErrorHandlingService.handleError, error);
  } finally {
    yield put(destroyModal(ModalTypes.CALENDAR_SUBSCRIPTION_MODAL));
  }
}

export function* fetchCalendarSubscriptions(): SagaIterator {
  try {
    const calendarSubscriptions: CalendarSubscriptionPayload[] = yield call(
      CalendarSubscriptionService.fetchCalendarSubscriptions
    );
    yield put(FetchCalendarSubscriptionSuccessAction(calendarSubscriptions));
  } catch (error) {
    yield call(ErrorHandlingService.handleError, error);
  }
}

export function* deleteCalendarSubscription(action): SagaIterator {
  try {
    yield call(
      CalendarSubscriptionService.deleteCalendarSubscription,
      action.payload
    );
    // Refetch
    yield fork(fetchCalendarSubscriptions);

    // Fetch events to reflect the update
    yield put(fullCalendarActions.reloadCurrentView());
  } catch (error) {
    yield call(ErrorHandlingService.handleError, error);
  }
}

export function* updateCalendarSubscription(action): SagaIterator {
  try {
    yield call(
      CalendarSubscriptionService.updateCalendarSubscription,
      action.payload.id,
      action.payload
    );
    // Refetch
    yield fork(fetchCalendarSubscriptions);

    // Fetch events to reflect the update
    yield put(fullCalendarActions.reloadCurrentView());
  } catch (error) {
    yield call(ErrorHandlingService.handleError, error);
  } finally {
    yield put(destroyModal(ModalTypes.CALENDAR_SUBSCRIPTION_MODAL));
  }
}

export function* forceResyncCalendarSubscription(action): SagaIterator {
  try {
    yield call(
      CalendarSubscriptionService.forceResyncCalendarSubscription,
      action.payload
    );
    // Refetch
    yield fork(fetchCalendarSubscriptions);
  } catch (error) {
    yield call(ErrorHandlingService.handleError, error);
  }
}

export function* initializeCalendarFirstTime(): SagaIterator {
  yield put(calendarSettingsActions.setCalendarChanged());
  yield all([call(selectAllCategories), call(initializeAbsenceFilters)]);
}

// select all church calendars
function* selectAllCategories() {
  const categories = getCategories(yield select());
  const churches = getSelectedChurch(yield select());
  yield put(
    toggleAllParishes({
      isActive: false,
      categories,
      churches,
    })
  );
}

function* initializeAbsenceFilters() {
  let groupIds = selectGroupIds(yield select());
  if (groupIds.length === 0) {
    yield take(groupsLoaded.type);
  }
  groupIds = selectGroupIds(yield select());
  yield put(
    AbsencesActions.toggleAllGroupAbsenceSuccess({
      groupIds: groupIds as number[],
      checked: true,
    })
  );
}
