import type { SagaIterator } from 'redux-saga';
import { put, call, select } from 'redux-saga/effects';

import UserService from '../services/user.service';
import { FetchUsersSuccess, FetchUserPrivilegeSuccess } from '../redux/actions';
import { User } from '../types/User.types';
import { CdAction } from '../../redux/utils';
import AuthorizationService from '../../services/AuthorizationService';
import { getUserPrivilegeSelector } from '../redux/selectors';

export function* getUsers(): SagaIterator {
  const payload: User[] = yield call(UserService.getUsers);
  yield put(FetchUsersSuccess(payload));
}

export function* getUserPrivilege(
  action: CdAction<{ permission: string }>
): SagaIterator {
  const permission = action.payload.permission;
  const existingAccess = yield select(getUserPrivilegeSelector, permission);
  // Do not request the backend if you already have a result for the privilege
  if (existingAccess !== null) return;
  const access = yield call(AuthorizationService.hasPrivilege, permission);
  yield put(FetchUserPrivilegeSuccess({ permission, access }));
}
