import React from 'react';
import { UseQueryResult } from '@tanstack/react-query';

import { CdTableData, CdTableProps } from '../cd-table';
import { UnPagedTablePagination } from '../cd-unpaged-table/UnPagedTablePagination';
import { DEFAULT_PAGE_SIZE } from '../cd-unpaged-table/CdUnPagedTable';

import { useCdServerSideTable } from './useCdServerSideTable';

import CdTable from '@/react/shared/components/cd-table/CdTable';
import {
  ApiCursorSearchParams,
  ApiCursorSearchResult,
  ApiSearchParams,
  ApiSearchResult,
} from '@/react/api';

export type ServerSidePaginationType = 'cursor' | 'page';

export type CdServerSideTableProps<T> = Omit<
  CdTableProps<T>,
  'isLoading' | 'data' | 'onTableChange' | 'headingText'
> & {
  headingText?: (data: CdTableData<T>) => string;
  searchParams?: Record<string, any>;
} & (
    | {
        pagedQuery: (
          params: ApiSearchParams
        ) => UseQueryResult<ApiSearchResult<T>>;
      }
    | {
        cursorQuery: (
          params: ApiCursorSearchParams
        ) => UseQueryResult<ApiCursorSearchResult<T>>;
      }
  );

export function CdServerSideTable<T extends Record<string, any> = any>({
  headingText,
  searchParams,
  ...props
}: CdServerSideTableProps<T>) {
  const { page, data, isLoading, handleTableChange } = useCdServerSideTable({
    extraData: searchParams,
    pageSize: props.pageSize || DEFAULT_PAGE_SIZE,
    pagedQuery: 'pagedQuery' in props && props.pagedQuery,
    cursorQuery: 'cursorQuery' in props && props.cursorQuery,
  });

  if ('cursorQuery' in props && props.cursorQuery) {
    props.OverridePagination = UnPagedTablePagination;
  }

  return (
    <CdTable<T>
      {...props}
      isLoading={isLoading}
      data={{
        items: data?.items || [],
        total:
          (data && 'total' in data && data?.total) || data?.items?.length || 0,
      }}
      page={page}
      onTableChange={handleTableChange}
      headingText={
        headingText &&
        headingText({
          items: data?.items || [],
          total:
            (data && 'total' in data && data?.total) ||
            data?.items?.length ||
            0,
        })
      }
      pageSize={props.pageSize || DEFAULT_PAGE_SIZE}
    />
  );
}
