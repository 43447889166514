import styled from 'styled-components';

export const PopoverActionButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  .event-popup__action-button,
  .event-popup__button-expand {
    color: #fff;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.2s ease-in-out;
    border-radius: 50%;

    &:hover {
      background-color: rgba(255, 255, 255, 0.2);
    }
  }
  .event-popup__button-expand {
    width: auto;
    padding: 0 6px;
    border-radius: 4px;
  }
`;
