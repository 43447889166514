import { Col, Divider, Menu, MenuProps, Row } from 'antd';
import React from 'react';
import styled from 'styled-components';

export type MenuItem = Required<MenuProps>['items'][number];

const TopDivider = styled(Divider)`
    margin-bottom: 0;
    margin-top: 16px;
`;

const BottomDivider = styled(Divider)`
    margin-bottom: 16px;
    margin-top: 0;
`;

const PaddedMenu = styled(Menu)`
  padding: 16px 16px 16px 0;
    height: 100%;
`;

const Content = styled.div`
    padding: 16px;
    max-height: 75vh;
    overflow-y: auto;
`;

type VerticalTabMenuProps = {
  menuItems: MenuItem[];
  children: React.ReactNode;
  activeItem?: string;
  setActiveItem?: (key: string) => void;
};

export const VerticalTabMenu = ({
  menuItems,
  activeItem,
  setActiveItem,
  children,
}: VerticalTabMenuProps) => (
  <>
    <TopDivider />
    <Row>
      <Col span={8}>
        <PaddedMenu
          selectedKeys={activeItem ? [activeItem] : []}
          onClick={(e) => setActiveItem(e.key)}
          mode="inline"
          items={menuItems}
        />
      </Col>
      <Col span={16}>
        <Content>{children}</Content>
      </Col>
    </Row>
    <BottomDivider />
  </>
);
