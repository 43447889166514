import { useCallback } from 'react';
import React from 'react';
import { Space } from 'antd';

import { showBookingPageSettingsModal } from '../BookingPageSettingsModal';
import { CdVerticalSpace } from '../../../shared/components/cd-vertical-space/CdVerticalSpace';

import {
  useDeleteTaxonomy,
  useTaxonomies,
} from '@/react/calendar/hooks/use-taxonomies';
import gettextCatalog from '@/react/services/I18nService';
import { showConfirmModal } from '@/react/shared/components/cd-confirm-modal/CdConfirmModal';
import { CategoryType } from '@/react/shared/models/category';
import { AvailabilityTaxonomy } from '@/react/taxonomies/services/Taxonomies';

export const useBookingPagesActions = () => {
  const { taxonomies, isLoading } = useTaxonomies(CategoryType.availability);
  const { deleteTaxonomy } = useDeleteTaxonomy();
  const deleteBookingPage = useCallback(
    async (taxonomy: AvailabilityTaxonomy) => {
      await showConfirmModal({
        title: gettextCatalog.getString('Delete booking page'),
        message: (
          <CdVerticalSpace>
            {gettextCatalog.getString(
              'Are you sure you want to delete this booking page?'
            )}
            <CdVerticalSpace size={0}>
              <Space>
                {gettextCatalog.getString('Type') + ':'}
                {taxonomy.name}
              </Space>
              <Space>
                {gettextCatalog.getString('Public name') + ':'}
                {taxonomy.config?.publicName}
              </Space>
            </CdVerticalSpace>
          </CdVerticalSpace>
        ),
        okText: gettextCatalog.getString('Delete'),
        showIcon: false,
        okButtonProps: {
          danger: true,
        },
        onOk: async () => deleteTaxonomy(taxonomy.id),
      });
    },
    [deleteTaxonomy]
  );

  const editBookingPage = useCallback(
    async (taxonomy: AvailabilityTaxonomy) => {
      await showBookingPageSettingsModal({ taxonomyId: taxonomy.id });
    },
    []
  );

  return {
    taxonomies,
    deleteBookingPage,
    editBookingPage,
    isLoading,
  };
};
